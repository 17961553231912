import apiClient from "./api";

async function createOutlet(budgetId, data) {
  try {
    const response = await apiClient.post(
      `/insert-outlet/${budgetId}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function updateOutlet(id, data) {
    try {
      const response = await apiClient.post(
        `/update-outlet/${id}`,
        data
      );
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

async function deleteOutlet(id) {
    try {
      const response = await apiClient.post(
        `/delete-outlet/${id}`);
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

async function getOutlets(budgetId) {
    try {
      const response = await apiClient.get(
        `/get-outlets/${budgetId}`);
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

export { createOutlet, updateOutlet, deleteOutlet, getOutlets };
