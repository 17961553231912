import apiClient from "./api";

async function getOperationalDepartments(budgetId) {
  try {
    const response = await apiClient.get(
      `/get-operational-departments/${budgetId}`
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function createOperationalDepartment(budgetId, data) {
  try {
    const response = await apiClient.post(
      `/insert-operational-department/${budgetId}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function updateOperationalDepartment(id, data) {
  try {
    const response = await apiClient.post(
      `/update-operational-department/${id}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function deleteOperationalDepartment(id) {
  try {
    const response = await apiClient.post(
      `/delete-operational-department/${id}`
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function getOperationalSpecialties(budgetId) {
  try {
    const response = await apiClient.get(
      `/get-operational-specialties/${budgetId}`
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function createOperationalSpecialty(budgetId, data) {
  try {
    const response = await apiClient.post(
      `/insert-operational-specialty/${budgetId}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function updateOperationalSpecialty(id, data) {
  try {
    const response = await apiClient.post(
      `/update-operational-specialty/${id}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function deleteOperationalSpecialty(id) {
  try {
    const response = await apiClient.post(
      `/delete-operational-specialty/${id}`
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function getOperationalCategories(budgetId) {
  try {
    const response = await apiClient.get(
      `/get-operational-categories/${budgetId}`
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function createOperationalCategory(budgetId, data) {
  try {
    const response = await apiClient.post(
      `/insert-operational-category/${budgetId}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function updateOperationalCategory(id, data) {
  try {
    const response = await apiClient.post(
      `/update-operational-category/${id}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function deleteOperationalCategory(id) {
  try {
    const response = await apiClient.post(`/delete-operational-category/${id}`);
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

export {
  createOperationalDepartment,
  updateOperationalDepartment,
  getOperationalDepartments,
  deleteOperationalDepartment,
  getOperationalCategories,
  createOperationalCategory,
  updateOperationalCategory,
  deleteOperationalCategory,
  getOperationalSpecialties,
  createOperationalSpecialty,
  updateOperationalSpecialty,
  deleteOperationalSpecialty,
};
