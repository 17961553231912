<template>
  <div class="form-section mt-4 ms-4">
    <!-- Container for title and button -->
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h5 style="font-weight: bolder; color: #555555">ΛΟΙΠΑ ΕΞΟΔΑ</h5>
      </div>
      <div class="d-flex align-items-center">
        <h5 style="font-weight: bolder; color: #555555; margin-right: 10px;">Επιλέξτε Κατηγορία</h5>
        <div class="dropdown" style="min-width:200px">
          <select v-model="selectedCategory" @change="onCategoryChange" class="form-select">
            <!-- Default "Όλες" option -->
            <option value="0">Όλες</option>
            <!-- Dynamically generated category options -->
            <option v-for="(title, id) in categoryTitles" :key="id" :value="id">
              {{ title }}
            </option>
          </select>
        </div>
      </div>
      <div>
        <!-- Display the total sum of all total_expense, with € symbol only if total is greater than 0 -->
        <h5 style="font-weight: bolder; color: #555555">
          ΣΥΝΟΛΟ: {{ formattedBudgetTotal }}
          <span v-if="totalExpenses"> €</span>
        </h5>
      </div>
    </div>

    <div class="mt-4 row">
      <div
        v-for="(expense, index) in items"
        :key="expense.id"
        class="col-md-2 card shadow-custom p-3 mb-3 bg-white rounded position-relative"
      >
        <!-- Non-Editable View -->
        <div v-if="!expense.isEditing">
          <div class="row" style="color:#A6A6A6; font-weight:bolder">
            <div class="col-md-8">
              <p class="label-with-circle">
                <strong>{{ expense.title }}</strong>
                <hr />
              </p>
              <p v-if="expense.type_of_calculation_id !== 2">
                <strong>KPIs:</strong> {{ expense.KPIs }}
              </p>
              <p>
                <strong>Σύνολο Εξόδου:</strong> {{ formatNumber(expense.total_expense) }}
                <span v-if="formatNumber(expense.total_expense)">€</span>
              </p>
            </div>
          </div>

          <!-- Edit Button -->
          <div class="action-buttons">
            <button class="btn border-0 p-1 edit" @click="editItem(expense)">
              <img src="../assets/images/edit-budget.png" alt="Edit" width="30" />
            </button>
          </div>
        </div>

        <!-- Editable View -->
        <div v-else>
          <div class="row" style="color:#4A4A4A; font-weight:bolder">
            <div class="col-md-12">
              <p class="label-with-circle2">
                <strong>{{ expense.title }}</strong>
                <hr />
              </p>
            </div>
            <div class="col-md-8" v-if="expense.type_of_calculation_id !== 2">
              <p><strong>KPIs</strong><hr /></p>
              <input
                type="number"
                v-model="expense.KPIs"
                class="form-control"
                maxlength="255"
                required
              />
            </div>
            <div class="col-md-8" v-if="expense.type_of_calculation_id !== 1">
              <p><strong>Σύνολο Εξόδου</strong><hr /></p>
              <input
                type="text"
                v-model="expense.total_expense"
                class="form-control"
                maxlength="255"
                required
              />
            </div>
          </div>
          <div class="row form-actions mt-4">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-secondary justify-content-end align-items-end"
                @click="cancelEdit(expense)"
              >
                ΑΚΥΡΩΣΗ
              </button>
            </div>
          </div>

          <!-- Form Actions -->
          <div class="action-buttons">
            <button class="btn border-0 p-1 save" @click="confirmSaveItem(index)">
              <img src="../assets/images/save.png" alt="Save" width="30" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import { getOtherExpensesPerCategory, updateOtherExpenses } from '@/services/other-expenses';
import { useToast } from 'vue-toastification';
import { getExpenseCategories } from '@/services/expense-categories';
import { fetchBudgetInfo, fetchUsers, changeUser, fetchUserInfo } from '../services/user';

export default {
  name: 'ItemComponent',
  data() {
    return {
      showForm: false,
      showForm2: false,
      form: {
        title: '',
        description: '',
      },
      form2: {
        title: '',
        expense_id: null,
        type_of_calculation_id: null,
      },
      items: [], // List for Categories
      items2: [], // List for Revenues
      currentEditRevenue: null, // Track current editing expense object
      currentEditCategory: null, // Track current editing expense object
      currentDeleteId: null, // For deleting categories
      currentDeleteId2: null, // For deleting expenses
      budgetTotal: 0, // Add budgetTotal here
      selectedCategory: '0', // Default to "Όλες"
      expenseCategories: [],
      categoryTitles: {}, // Object to map category IDs to Titles
      totalExpenses: 0, // New property to store the total expenses
    };
  },

  computed: {
    // Computed property to format budgetTotal with European number format
    formattedBudgetTotal() {
      // Updated to format totalExpenses instead of budgetTotal
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(this.totalExpenses);
    },
  },

  methods: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },

    // Fetch Expenses (Other Expenses) with optional category filter
    async fetchItems(categoryId = 0) {
      try {
        const response = await getOtherExpensesPerCategory(this.$route.params.budget_id, categoryId); // Pass category ID if available
        this.items = response.data.expenses.map((item) => ({ ...item, isEditing: false }));
        this.totalExpenses = response.data.total_expenses; // Save total_expenses from the response
      } catch (error) {
        console.error('Error fetching other expenses:', error);
      }
    },

    // Fetch budget info (to display the total)
    async fetchBudgetInfoData() {
      try {
        const response = await fetchBudgetInfo(this.$route.params.budget_id);
        if (response) {
          this.budgetTotal = response.data.data.total_other_cost || 0;  // Correctly set the value of budgetTotal
        }
      } catch (error) {
        console.error('Failed to fetch budget info:', error);
      }
    },

    // Fetch Expense Categories
    async fetchExpenseCategories() {
      try {
        const response = await getExpenseCategories(this.$route.params.budget_id);
        this.expenseCategories = response.data;
        this.categoryTitles = this.expenseCategories.reduce((acc, category) => {
          acc[category.id] = category.title;
          return acc;
        }, {});
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },

    // Method to call when the category is changed
    async onCategoryChange() {
      await this.fetchItems(this.selectedCategory); // Fetch items based on the selected category
    },

    // Confirm save for expense
    async confirmSaveItem(index) {
      try {
        const expense = this.items[index]; // Access the expense being edited

        if (this.currentEditCategory) {
          // Update existing expense with KPIs and total_expense
          const response = await updateOtherExpenses(
            this.$route.params.budget_id,
            this.currentEditCategory.id,
            {
              KPIs: expense.KPIs, // Send updated KPIs
              total_expense: expense.total_expense, // Send updated total_expense
            }
          );

          this.currentEditCategory.isEditing = false; // End editing mode
          this.currentEditCategory = null; // Clear current edit category

          if (response.success) {
            useToast().success(response.message); // Show success message
          } else {
            useToast().error(response.message); // Show error message
          }
        }

        this.resetForm(); // Reset form after save
        await this.fetchItems(this.selectedCategory); // Fetch the latest items
        await this.fetchBudgetInfoData(); // Fetch latest budget info (including totals)
      } catch (error) {
        console.error('Error saving expense:', error); // Log any errors
      }
    },

    // Reset the form
    resetForm() {
      this.form = {
        title: '',
        description: '',
      };
      this.currentEditCategory = null;
      this.showForm = false;
    },

    // Edit expense
    editItem(expense) {
      this.resetForm(); // Reset form to avoid conflicts
      this.currentEditCategory = expense;
      this.form = {
        title: expense.title,
        description: expense.description,
      };
      expense.isEditing = true;
    },

    // Cancel editing expense
    cancelEdit(expense) {
      expense.isEditing = false;
      this.currentEditCategory = null;
      this.resetForm();
    },
  },

  mounted() {
    // Set the page title
    useHead({
      title: 'Λοιπά Έξοδα',
    });

    // Fetch expense categories on mount
    this.fetchExpenseCategories();

    // Fetch all items (other expenses) and budget info
    this.fetchItems().then(() => {
      this.fetchBudgetInfoData();
    });
  },
};
</script>

    
    
    
    <style scoped>
     .form-container {
      background: #f8f9fa;
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #ddd;
    }
    
    .card {
      padding: 15px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #ddd;
      position: relative;
      margin-left:35px;
    }
    
    .action-buttons {
      position: absolute;
      top: 100px;
      right: 10px;
    }
    
    
    
    hr {
      margin: 10px 0;
    }
    
    .modal-backdrop {
      opacity: 0.5 !important;
    }
    /* Adding a circle bullet point next to each label */
    .label-with-circle::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #A6A6A6;
      border-radius: 50%;
      margin-right: 10px;
      vertical-align: middle;
    }
    
    .label-with-circle2::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: var(--primary-color);
      border-radius: 50%;
      margin-right: 10px;
      vertical-align: middle;
    }
    /* Aligning middle elements like Basic Board and Supplement */
    .align-middle {
      margin-top: 30px; /* Adjust this value based on the spacing you want */
    }
    
    /* Custom shadow for outlet-card */
    .shadow-custom {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease-in-out;
    }
    
    .shadow-custom:hover {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
    }
    
    
    
    .edit{
      position: absolute;
    
      top: 25%; /* Adjust the vertical position to be slightly above middle */
      right: -20px; /* Half the button outside the outlet-card */
      transform: translateY(-200%); /* Center vertically relative to its position */
    
    }
    
    .save {
      position: absolute;
      top: 30%; /* Adjust as needed to lower the button */
      right: -18px; /* Adjust to move outside the card */
      transform: translateY(-95%); /* Further adjust for desired position */
    }
    
    .delete {
      position: absolute;
      top: 50%; /* Adjust to position higher or lower */
      right: -18px; /* Same as above */
      transform: translateY(30%); /* Further adjust for desired position */
    
    }
    </style>
    