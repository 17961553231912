<template>
  <div class="form-section mt-4 ms-4">
     <!-- Container for title and button -->
     <div class="d-flex align-items-center justify-content-start ">
      <!-- Title -->
      <h5 style="font-weight: bolder; color: #555555">BOARD TYPES</h5>
    </div>

    <!-- Board Type and Supplement Section -->
    <div class="mt-2 row">
      <div class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative">
        <!-- Non-Editable View for Board Types -->
        <div v-if="!isEditingBoardTypes">
          <div class="row" style="color:#A6A6A6; font-weight:bolder">
            <div class="col-md-8">
              <p class="label-with-circle"><strong>Basic Board</strong><hr/></p>
              <p>{{ boardType }}</p>
            </div>
          </div>
          <!-- Edit Button for Board Types -->
          <div class="action-buttons">
            <button class="btn border-0 p-1 custom-action-buttons" @click="editBoardTypes">
              <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
            </button>
          </div>
        </div>

        <!-- Editable View for Board Types -->
        <div v-else>
          <div class="row" style="color:#4A4A4A; font-weight:bolder">
            <div class="col-md-8">
              <p class="label-with-circle2"><strong>Basic Board</strong><hr/></p>
              <select v-model="editableBoardType" class="form-control">
                <option v-for="option in boardTypesOptions" :key="option.id" :value="option.id">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
               
    <div class="row form-actions mt-4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditBoardTypes">
        ΑΚΥΡΩΣΗ
      </button>
          </div>
        </div>
   
    <!-- Form Actions -->
    <div class="action-buttons">
      <button class="btn border-0 p-1 save " @click="saveBoardTypes">
        <img src="../assets/images/save.png" alt="Save" width="30">
      </button>
    </div>
        </div>
      </div>

      <div class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative" style="margin-left: 30px;">
        <!-- Non-Editable View for Supplements -->
        <div v-if="!isEditingSupplements">
          <div class="row" style="color:#A6A6A6; font-weight:bolder">
            <div class="col-md-8">
              <p class="label-with-circle"><strong>Supplement</strong><hr/></p>
              <p>{{ supplement }}</p>
            </div>
          </div>
          <!-- Edit Button for Supplements -->
          <div class="action-buttons">
            <button class="btn border-0 p-1 custom-action-buttons" @click="editSupplements">
              <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
            </button>
          </div>
        </div>

        <!-- Editable View for Supplements -->
        <div v-else>
          <div class="row" style="color:#4A4A4A; font-weight:bolder">
            <div class="col-md-8">
              <p class="label-with-circle2"><strong>Supplement</strong><hr/></p>
              <select v-model="editableSupplement" class="form-control">
                <option v-for="option in supplementsOptions" :key="option.id" :value="option.id">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>

          
    <div class="row form-actions mt-4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditSupplements">
        ΑΚΥΡΩΣΗ
      </button>
          </div>
        </div>
   
    <!-- Form Actions -->
    <div class="action-buttons">
      <button class="btn border-0 p-1 save " @click="saveSupplements">
        <img src="../assets/images/save.png" alt="Save" width="30">
      </button>
    </div>
        </div>
      </div>
    </div>

    <!-- Container for title and button -->
    <div class="mt-2 d-flex align-items-center justify-content-start">
      <!-- Title -->
      <h5 style="font-weight: bolder; color: #555555">NEW ROOM TYPE</h5>

      <!-- Add Button -->
      <button v-if="!showForm" class="btn btn-primary fw-bold" style="margin-left:10px;" @click="showAddForm">ΠΡΟΣΘΗΚΗ +</button>
    </div>

    <!-- Form Container -->
    <div v-if="showForm" class="form-container">
      <!-- Form Fields -->
      <div class="row" style="color:#4A4A4A; font-weight:bolder">
        <!-- Left Column -->
        <div class="col-md-4">
          <p class="label-with-circle2"><strong>ROOM TYPE</strong><hr/></p>
          <input
            type="text"
            v-model="form.title"
            class="form-control"
            maxlength="255"
            required
          />
        </div>

        <!-- Middle Column -->
        <div class="col-md-4 form-group">
          <p class="label-with-circle2"><strong>ΜΕΓΕΘΟΣ ΔΩΜΑΤΙΟΥ</strong><hr/></p>
          <input
            type="number"
            v-model.number="form.size"
            class="form-control"
            placeholder="π.χ. 25"
          /><br/>

          <p class="label-with-circle2"><strong>ALLOTMENT</strong><hr/></p>
          <input
            type="number"
            v-model.number="form.allotment"
            class="form-control"
            placeholder="π.χ. 3"
          />
        </div>

        <!-- Right Column -->
        <div class="col-md-4 form-group">
          <p class="label-with-circle2"><strong>CAPACITY</strong><hr/></p>
          <div>
            <label><strong>Ελάχιστη:</strong></label>
            <input
              type="number"
              v-model.number="form.min_capacity"
              class="form-control me-2"
              placeholder="Ελάχιστη"
            />
            <label><strong>Μέγιστη:</strong></label>
            <input
              type="number"
              v-model.number="form.max_capacity"
              class="form-control"
              placeholder="Μέγιστη"
            />
          </div>
        </div>
      </div>

      <!-- Form Actions -->
      <div class="row form-actions mt-4">
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary" @click="resetForm">ΑΚΥΡΩΣΗ</button>
          <button class="btn btn-primary ms-2 save-button" @click="openSaveModal">ΑΠΟΘΗΚΕΥΣΗ</button>
        </div>
      </div>
    </div>

    <!-- Display list of rooms -->
    <div class="room-list mt-4">
      <div v-for="(room, index) in roomTypes" :key="room.id" class="room-item">
        <div class="card shadow-custom p-3 mb-3 bg-white rounded position-relative">
          <!-- Non-Editable View -->
          <div v-if="!room.isEditing">
            <div class="row" style="color:#A6A6A6; font-weight:bolder">
              <!-- Left Column -->
              <div class="col-md-4">
                <p class="label-with-circle"><strong>ROOM TYPE</strong><hr/></p>
                <p>{{ room.title }}</p>
              </div>
              <!-- Middle Column -->
              <div class="col-md-4">
                <p class="label-with-circle"><strong>ΜΕΓΕΘΟΣ ΔΩΜΑΤΙΟΥ</strong><hr/></p>
                <p>{{ room.size }} τ.μ.</p>
                <p class="label-with-circle"><strong>ALLOTMENT</strong><hr/></p>
                <p>{{ room.allotment }}</p>
              </div>
              <!-- Right Column -->
              <div class="col-md-4">
                <p class="label-with-circle"><strong>CAPACITY</strong><hr/></p>
                <div>
                  <p><strong>Ελάχιστη:</strong> <span>{{ room.min_capacity }} {{ room.min_capacity === 1 ? 'άτομο' : 'άτομα' }}</span></p>
                  <p><strong>Μέγιστη:</strong> <span>{{ room.max_capacity }} {{ room.max_capacity === 1 ? 'άτομο' : 'άτομα' }}</span></p>
                </div>
              </div>
            </div>

            <!-- Edit Button -->
            <div class="action-buttons">
              <button class="btn border-0 p-1 edit" @click="editRoom(index)">
                <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
              </button>
            </div>
          </div>

          <!-- Editable View -->
          <div v-else>
            <div class="row" style="color:#4A4A4A; font-weight:bolder">
              <!-- Left Column -->
              <div class="col-md-4">
                <p class="label-with-circle2"><strong>ROOM TYPE</strong><hr/></p>
                <input
                  type="text"
                  v-model="room.title"
                  class="form-control"
                  maxlength="255"
                  required
                />
              </div>
              <!-- Middle Column -->
              <div class="col-md-4 form-group">
                <p class="label-with-circle2"><strong>ΜΕΓΕΘΟΣ ΔΩΜΑΤΙΟΥ</strong><hr/></p>
                <input
                  type="number"
                  v-model.number="room.size"
                  class="form-control"
                /><br/>
                <p class="label-with-circle2"><strong>ALLOTMENT</strong><hr/></p>
                <input
                  type="number"
                  v-model.number="room.allotment"
                  class="form-control"
                />
              </div>
              <!-- Right Column -->
              <div class="col-md-4 form-group">
                <p class="label-with-circle2"><strong>CAPACITY</strong><hr/></p>
                <div>
                  <label><strong>Ελάχιστη:</strong></label>
                  <input
                    type="number"
                    v-model.number="room.min_capacity"
                    class="form-control"
                    placeholder="Ελάχιστη"
                  />
                  <label><strong>Μέγιστη:</strong></label>
                  <input
                    type="number"
                    v-model.number="room.max_capacity"
                    class="form-control"
                    placeholder="Μέγιστη"
                  /><br><br><br><br><br><br>
                </div>
              </div>
            </div>

            <div class="row form-actions mt-4">
              <div class="d-flex justify-content-end">
                <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEdit(index)">ΑΚΥΡΩΣΗ</button>
              </div>
            </div>

            <!-- Form Actions -->
            <div class="action-buttons mt-5">
              <button class="btn border-0 p-1 mb-2 save mt-3" @click="openEditModal(index)">
                <img src="../assets/images/save.png" alt="Save" width="30">
              </button>
              <button class="mt-2 btn border-0 p-1 delete" @click="openDeleteModal(index)">
                <img src="../assets/images/bin.png" alt="Delete" width="30">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Save Confirmation Modal -->
    <div class="modal fade" id="saveModal" tabindex="-1" aria-labelledby="saveModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="saveModalLabel">Επιβεβαίωση Αποθήκευσης</h5>
            <button type="button" class="close" @click="closeSaveModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Είστε σίγουροι ότι θέλετε να αποθηκεύσετε το δωμάτιο;
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeSaveModal">Ακύρωση</button>
            <button type="button" class="btn btn-primary" @click="confirmSaveRoom">Αποθήκευση</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Confirmation Modal -->
    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Επιβεβαίωση Επεξεργασίας</h5>
            <button type="button" class="close" @click="closeEditModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Είστε σίγουροι ότι θέλετε να αποθηκεύσετε τις αλλαγές;
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeEditModal">Ακύρωση</button>
            <button type="button" class="btn btn-primary" @click="confirmSaveRoomEdit">Αποθήκευση</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Επιβεβαίωση Διαγραφής</h5>
            <button type="button" class="close" @click="closeDeleteModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Είστε σίγουροι ότι θέλετε να διαγράψετε αυτόν τον τύπο δωματίου;
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeDeleteModal">Ακύρωση</button>
            <button type="button" class="btn btn-danger" @click="confirmDeleteRoom">Διαγραφή</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useHead } from '@vueuse/head';
import { useToast } from 'vue-toastification';
import { createRoomType, getRoomTypes, updateRoomType, deleteRoomType } from '@/services/room-types';
import { getBudget, updateBudget } from "@/services/budget";
export default {
  data() {
    return {
      showForm: false,
      form: {
        title: '',
        size: null,
        min_capacity: null,
        max_capacity: null,
        allotment: null,
      },
      roomTypes: [],
      boardType: '', // Single value for board type
      supplement: '', // Single value for supplement
      boardTypesOptions: [
        { id: 'BB', text: 'BB' },
        { id: 'HB', text: 'HB' },
        { id: 'FB', text: 'FB' },
        { id: 'AI', text: 'AI' },
      ],
      supplementsOptions: [
        { id: 'HB', text: 'HB' },
        { id: 'FB', text: 'FB' },
        { id: 'AI', text: 'AI' },
      ],
      editableBoardType: '',
      editableSupplement: '',
      isEditingBoardTypes: false,
      isEditingSupplements: false,
    };
  },
  methods: {
    // Fetch room types
    async fetchRoomTypes() {
      try {
        const response = await getRoomTypes(this.$route.params.budget_id);
        this.roomTypes = response.data.map((room) => ({ ...room, isEditing: false }));
      } catch (error) {
        console.error('Error fetching room types:', error);
      }
    },

    showAddForm() {
      this.resetForm();
      this.showForm = true;
    },

    resetForm() {
      this.form = {
        title: '',
        size: null,
        min_capacity: null,
        max_capacity: null,
        allotment: null,
      };
      this.boardType = '';
      this.supplement = '';
      this.showForm = false;
    },

    openSaveModal() {
      $('#saveModal').modal('show');
    },
    closeSaveModal() {
      $('#saveModal').modal('hide');
    },

    async confirmSaveRoom() {
      try {
        const response = await createRoomType(this.$route.params.budget_id, { ...this.form, boardType: this.boardType, supplement: this.supplement });
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        this.roomTypes.push({ ...response.data, isEditing: false });
        this.resetForm();
        this.closeSaveModal();
        this.fetchRoomTypes();
      } catch (error) {
        console.error('Error saving room:', error);
      }
    },

    editRoom(index) {
      this.roomTypes[index].isEditing = true;
    },

    openEditModal(index) {
      $('#editModal').modal('show');
    },
    closeEditModal() {
      $('#editModal').modal('hide');
    },

    async confirmSaveRoomEdit() {
      try {
        const room = this.roomTypes.find((room) => room.isEditing);
        const response = await updateRoomType(room.id, { ...room, boardType: this.boardType, supplement: this.supplement });
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        room.isEditing = false;
        this.closeEditModal();
      } catch (error) {
        console.error('Error updating room:', error);
      }
    },

    cancelEdit(index) {
      this.roomTypes[index].isEditing = false;
    },

    openDeleteModal(index) {
      this.currentDeleteIndex = index;
      $('#deleteModal').modal('show');
    },
    closeDeleteModal() {
      $('#deleteModal').modal('hide');
    },

    async confirmDeleteRoom() {
      try {
        const room = this.roomTypes[this.currentDeleteIndex];
        const response = await deleteRoomType(room.id);
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        this.roomTypes.splice(this.currentDeleteIndex, 1);
        this.closeDeleteModal();
      } catch (error) {
        console.error('Error deleting room:', error);
      }
    },

    // Edit and save methods for board types
    editBoardTypes() {
      this.editableBoardType = this.boardType; // Set to current value
      this.isEditingBoardTypes = true;
    },
    saveBoardTypes() {
      this.boardType = this.editableBoardType; // Store as a single value
      updateBudget(this.$route.params.budget_id, {supplement: this.supplement, basic_board: this.editableBoardType});
      this.isEditingBoardTypes = false;
      useToast().success('Board type saved successfully!');
    },
    cancelEditBoardTypes() {
      this.isEditingBoardTypes = false;
    },

    // Edit and save methods for supplements
    editSupplements() {
      this.editableSupplement = this.supplement; // Set to current value
      this.isEditingSupplements = true;
    },
    saveSupplements() {
      this.supplement = this.editableSupplement; // Store as a single value
      updateBudget(this.$route.params.budget_id, {supplement: this.supplement, basic_board: this.boardType});
      this.isEditingSupplements = false;
      useToast().success('Supplement saved successfully!');
    },
    cancelEditSupplements() {
      this.isEditingSupplements = false;
    },

    // Function to populate both basic board and supplement
    async populateBoardAndSupplement() {
      const r = await getBudget(this.$route.params.budget_id);
      if (r.data.data.basic_board) {
        this.boardType = r.data.data.basic_board;
      }
      if (r.data.data.supplement) {
        this.supplement = r.data.data.supplement;
      }
    }
  },
  mounted() {
    useHead({
        title: 'Τύποι Δωματίων',
      });
    this.fetchRoomTypes();
    this.populateBoardAndSupplement();
  },
};
</script>

<style scoped>
.form-container {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.card {
  padding: 15px;
  background: #fff;
  border-radius: 30px;
  border: 1px solid #ddd;
  position: relative;
}

.action-buttons {
  position: absolute;
  top: 100px;
  right: 10px;
}

hr {
  margin: 10px 0;
}

.modal-backdrop {
  opacity: 0.5 !important;
}


/* Aligning middle elements like Basic Board and Supplement */
.align-middle {
  margin-top: 30px; /* Adjust this value based on the spacing you want */
}

/* Custom shadow for room-card */
.shadow-custom {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.shadow-custom:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
}

.edit{
    position: absolute;
  
    top: 25%; /* Adjust the vertical position to be slightly above middle */
    right: -20px; /* Half the button outside the outlet-card */
    transform: translateY(-200%); /* Center vertically relative to its position */
  
  }
  
  .save {
    position: absolute;
    top: 30%; /* Adjust as needed to lower the button */
    right: -18px; /* Adjust to move outside the card */
    transform: translateY(-95%); /* Further adjust for desired position */
  }
  
  .delete {
    position: absolute;
    top: 50%; /* Adjust to position higher or lower */
    right: -18px; /* Same as above */
    transform: translateY(30%); /* Further adjust for desired position */
  
  }

.custom-action-buttons {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-172%);
  background: #67B662;
  width: 40px;
  height: 40px;
}

.custom-action-buttons:hover {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-172%);
  background: #67B662;
  width: 40px;
  height: 40px;
}
</style>
