import apiClient from './api';


export async function fetchBudgetInfo(budget_id) {
  try {
    const response = await apiClient.get(`/get-current-budget/${budget_id}`);
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

export async function fetchUserInfo() {
  try {
    const response = await apiClient.get('/user'); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

export async function fetchUsers() {
  try {
    const response = await apiClient.get('/fetch-users'); // Replace with your endpoint
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      return { success: false, message: "No response received", details: error.request };
    } else {
      // Something happened in setting up the request that triggered an Error
      return { success: false, message: "Request setup error", details: error.message };
    }
  }};

  export async function getAllUnreadNotifications() {
    try {
      const response = await apiClient.post('/get-unread-notifications'); // Replace with your endpoint
      return response;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error.response;
      } else if (error.request) {
        // The request was made but no response was received
        return { success: false, message: "No response received", details: error.request };
      } else {
        // Something happened in setting up the request that triggered an Error
        return { success: false, message: "Request setup error", details: error.message };
      }
    }};

    export async function changePassword(payload) {
      try {
        const response = await apiClient.post('/change-password', payload);
        return response.data; // Assuming Laravel returns a JSON response
      } catch (error) {
        if (error.response) {
          return { success: false, message: error.response.data.message || 'Request failed' };
        } else if (error.request) {
          return { success: false, message: 'No response from server' };
        } else {
          return { success: false, message: error.message };
        }
      }
    }
    