<template>
    <div class="form-section mt-4 ms-4">
       <!-- Container for title and button -->
       <div class="d-flex align-items-center justify-content-start ">
        <!-- Title -->
        <h5 style="font-weight: bolder; color: #555555">NEΟ OUTLET</h5>
  
        <!-- Add Button -->
        <button v-if="!showForm" class="btn btn-primary fw-bold" style="margin-left:10px;" @click="showAddForm">ΠΡΟΣΘΗΚΗ +</button>
      </div>
  
      <!-- Form Container -->
      <div v-if="showForm" class="form-container">
        <!-- Form Fields -->
        <div class="row" style="color:#4A4A4A; font-weight:bolder">
    <!-- Left Column -->
    <div class="col-md-4">
      <p class="label-with-circle2"><strong>ΕΙΔΟΣ ΕΣΤΙΑΤΟΡΙΟΥ</strong><hr/></p>
      <input
        type="text"
        v-model="form.restaurant_kind"
        class="form-control"
        maxlength="255"
        required
      />
      
    </div>
    
    <!-- Middle Column -->
    <div class="col-md-4 form-group">
      <p class="label-with-circle2"><strong>ΚΟΥΒΕΡ ΚΛΕΙΣΤΟΥ ΧΩΡΟΥ</strong><hr/></p>
      <input
        type="number"
        v-model.number="form.number_of_people_close"
        class="form-control"
        placeholder="π.χ. 25"
      /><br/>
      
      <p class="label-with-circle2"><strong>ΚΟΥΒΕΡ ANOIXTOY ΧΩΡΟΥ</strong><hr/></p>
      <input
        type="number"
        v-model.number="form.number_of_people_open"
        class="form-control"
        placeholder="π.χ. 3"
      />
      
    </div>
    
    <!-- Right Column -->
    <div class="col-md-4 form-group">
      <p class="label-with-circle2"><strong>ΚΟΥΖΙΝΑ</strong><hr/></p>
      <select v-model="form.hasCuisine" class="form-control">
        <option value="1">ΝΑΙ</option>
        <option value="0">OXI</option>
      </select><br>
      <p class="label-with-circle2"><strong>ΠΑΣΟ</strong><hr/></p>
      <input
        type="number"
        v-model.number="form.number_of_paso"
        class="form-control"
        placeholder="π.χ. 25"
      /><br/>
      <br><br><br>
      
     
    </div>
  </div>
  
  
        <!-- Form Actions -->
        <div class="row form-actions mt-4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary" @click="resetForm">
              ΑΚΥΡΩΣΗ
            </button>
            <button class="btn btn-primary ms-2 save-button" @click="openSaveModal">
              ΑΠΟΘΗΚΕΥΣΗ
            </button>
          </div>
        </div>
      </div>
  
      <!-- Display list of outlets -->
      <div class="outlet-list mt-4">
        <div
          v-for="(outlet, index) in outlets"
          :key="outlet.id"
          class="outlet-item"
        >
        <div class="card shadow-custom p-3 mb-3 bg-white rounded position-relative">
          <!-- Non-Editable View -->
            <div v-if="!outlet.isEditing">
              <div class="row" style="color:#A6A6A6; font-weight:bolder">
      <!-- Left Column -->
      <div class="col-md-4">
        <p class="label-with-circle"><strong>ΕΙΔΟΣ ΕΣΤΙΑΤΟΡΙΟΥ</strong><hr/></p>
        <p>{{ outlet.restaurant_kind }}</p>
        
      </div>
      <!-- Middle Column -->
      <div class="col-md-4">
        <p class="label-with-circle"><strong>ΚΟΥΒΕΡ ΚΛΕΙΣΤΟΥ ΧΩΡΟΥ</strong><hr/></p>
        <p>{{ outlet.number_of_people_close }}</p>
        <p class="label-with-circle"><strong>ΚΟΥΒΕΡ ΑΝΟΙΧΤΟΥ ΧΩΡΟΥ</strong><hr/></p>
        <p>{{ outlet.number_of_people_open }}</p>
        
      </div>
      <!-- Right Column -->
      <div class="col-md-4">
        <p class="label-with-circle"><strong>ΚΟΥΖΙΝΑ</strong><hr/></p>
          <p><span> {{ outlet.hasCuisine === 1 ? 'NAI' : 'OXI' }}</span></p>
          <p class="label-with-circle"><strong>ΠΑΣΟ</strong><hr/></p>
        <p>{{ outlet.number_of_paso }}</p>
      </div>
    </div>
  
    <!-- Edit Button -->
    <div class="action-buttons">
      <button class="btn border-0 p-1 edit" @click="editoutlet(index)">
        <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
      </button>
    </div>
  </div>
  
            <!-- Editable View -->
            <div v-else>
    <div class="row" style="color:#4A4A4A; font-weight:bolder">
      <!-- Left Column -->
      <div class="col-md-4">
        <p class="label-with-circle2"><strong>ΕΙΔΟΣ ΕΣΤΙΑΤΟΡΙΟΥ</strong><hr/></p>
        <input
          type="text"
          v-model="outlet.restaurant_kind"
          class="form-control"
          maxlength="255"
          required
        />
    
      </div>
      <!-- Middle Column -->
      <div class="col-md-4 form-group">
        <p class="label-with-circle2"><strong>ΚΟΥΒΕΡ ΚΛΕΙΣΤΟΥ ΧΩΡΟΥ</strong><hr/></p>
        <input
          type="number"
          v-model.number="outlet.number_of_people_close"
          class="form-control"
        /><br/>
        <p class="label-with-circle2"><strong>ΚΟΥΒΕΡ ANOIXTOY ΧΩΡΟΥ</strong><hr/></p>
        <input
          type="number"
          v-model.number="outlet.number_of_people_open"
          class="form-control"
        />
      </div>
      <!-- Right Column -->
      <div class="col-md-4 form-group">
        <p class="label-with-circle2"><strong>ΚΟΥΖΙΝΑ</strong><hr/></p>
        <select v-model="outlet.hasCuisine" class="form-control">
         <option value="1" :selected="outlet.hasCuisine === 1">ΝΑΙ</option>
         <option value="0" :selected="outlet.hasCuisine !== 1">ΟΧΙ</option>
      </select><br/>
        <p class="label-with-circle2"><strong>ΠΑΣΟ</strong><hr/></p>
        <input
          type="number"
          v-model.number="outlet.number_of_paso"
          class="form-control"
        />
      </div>
    </div>
  
    <div class="row form-actions mt-4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEdit(index)">
        ΑΚΥΡΩΣΗ
      </button>
          </div>
        </div>
   
    <!-- Form Actions -->
    <div class="action-buttons">
      <button class="btn border-0 p-1 save " @click="openEditModal(index)">
        <img src="../assets/images/save.png" alt="Save" width="30">
      </button>
      <button class="mt-2 btn border-0 p-1 delete" @click="openDeleteModal(index)">
        <img src="../assets/images/bin.png" alt="Delete" width="30">
      </button>
      
    </div>
  </div>
  
  
          </div>
        </div>
      </div>
  
      <!-- Save Confirmation Modal -->
      <div
        class="modal fade"
        id="saveModal"
        tabindex="-1"
        aria-labelledby="saveModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="saveModalLabel">Επιβεβαίωση Αποθήκευσης</h5>
              <button type="button" class="close" @click="closeSaveModal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Είστε σίγουροι ότι θέλετε να αποθηκεύσετε το δωμάτιο;
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeSaveModal">
                Ακύρωση
              </button>
              
              <button type="button" class="btn btn-primary" @click="confirmSaveoutlet">
                Αποθήκευση
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Edit Confirmation Modal -->
      <div
        class="modal fade"
        id="editModal"
        tabindex="-1"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editModalLabel">Επιβεβαίωση Επεξεργασίας</h5>
              <button type="button" class="close" @click="closeEditModal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Είστε σίγουροι ότι θέλετε να αποθηκεύσετε τις αλλαγές;
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeEditModal">
                Ακύρωση
              </button>
              <button type="button" class="btn btn-primary" @click="confirmSaveoutletEdit">
                Αποθήκευση
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Delete Confirmation Modal -->
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">Επιβεβαίωση Διαγραφής</h5>
              <button type="button" class="close" @click="closeDeleteModal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το Outlet;
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeDeleteModal">
                Ακύρωση
              </button>
              <button type="button" class="btn btn-danger" @click="confirmDeleteoutlet">
                Διαγραφή
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useHead } from '@vueuse/head';
  import { useRoute } from 'vue-router';
  import { createOutlet, getOutlets, updateOutlet, deleteOutlet } from '@/services/outlets';
  import Select2 from 'vue3-select2-component';
import { useToast } from 'vue-toastification';
  
  export default {
    name: 'outlet',
    components: {
      Select2,
    },
    data() {
      return {
        showForm: false,
        form: {
          restaurant_kind: '',
          number_of_people_close: null,
          number_of_people_open: null,
          hasCuisine: null,
          number_of_paso: null
        },
        outlets: [],
        editIndex: null,
        currentEditIndex: null,
        currentDeleteIndex: null,
        basicBoardsOptions: [
          { id: 'BB', text: 'BB' },
          { id: 'HB', text: 'HB' },
          { id: 'FB', text: 'FB' },
          { id: 'AI', text: 'AI' },
        ],
        supplementsOptions: [
          { id: 'HB', text: 'HB' },
          { id: 'FB', text: 'FB' },
          { id: 'AI', text: 'AI' },
        ],
      };
    },
    methods: {
      async fetchoutlets() {
        try {
          const response = await getOutlets(this.$route.params.budget_id);
          this.outlets = response.data.map((outlet) => ({ ...outlet, isEditing: false }));
        } catch (error) {
          console.error('Error fetching outlets:', error);
        }
      },

      showAddForm() {
        this.resetForm();
        this.showForm = true;
      },
      resetForm() {
        this.form = {
          restaurant_kind: '',
          number_of_people_close: null,
          number_of_people_open: null,
          hasCuisine: null,
          number_of_paso: null
        };
        this.editIndex = null;
        this.showForm = false;
      },
      openSaveModal() {
 
        $('#saveModal').modal('show');
      },
      closeSaveModal() {
        $('#saveModal').modal('hide');
      },
      async confirmSaveoutlet() {
        try {
          const response = await createOutlet(this.$route.params.budget_id, this.form);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          const savedoutlet = response.data;
  
          if (this.editIndex !== null) {
            this.outlets.splice(this.editIndex, 1, { ...savedoutlet, isEditing: false });
            this.editIndex = null;
          } else {
            this.outlets.push({ ...savedoutlet, isEditing: false });
          }
          this.resetForm();
          this.closeSaveModal();
          this.fetchoutlets();
        } catch (error) {
          console.error('Error saving outlet:', error);
          this.closeSaveModal();
        }
      },
      editoutlet(index) {
        this.outlets[index].originalData = { ...this.outlets[index] };
        this.outlets[index].isEditing = true;
      },
      openEditModal(index) {
        this.currentEditIndex = index;
        $('#editModal').modal('show');
      },
      closeEditModal() {
        $('#editModal').modal('hide');
      },
      async confirmSaveoutletEdit() {
        try {
          const outlet = this.outlets[this.currentEditIndex];
  
          const response = await updateOutlet(outlet.id, outlet);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          delete outlet.originalData;
          outlet.isEditing = false;
          this.closeEditModal();
        } catch (error) {
          console.error('Error updating outlet:', error);
          this.closeEditModal();
        }
      },
      cancelEdit(index) {
        this.outlets[index] = { ...this.outlets[index].originalData };
        delete this.outlets[index].originalData;
        this.outlets[index].isEditing = false;
      },
      openDeleteModal(index) {
        this.currentDeleteIndex = index;
        $('#deleteModal').modal('show');
      },
      closeDeleteModal() {
        $('#deleteModal').modal('hide');
      },
      async confirmDeleteoutlet() {
        try {
          const outlet = this.outlets[this.currentDeleteIndex];
          const response = await deleteOutlet(outlet.id);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          this.outlets.splice(this.currentDeleteIndex, 1);
          this.closeDeleteModal();
        } catch (error) {
          console.error('Error deleting outlet:', error);
          this.closeDeleteModal();
        }
      },
    },
    mounted() {
      useHead({
        title: 'Outlets',
      });
      this.fetchoutlets();
    },
  };
  </script>
  
  <style scoped>
  .form-container {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .card {
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
    position: relative;
  }
  
  .action-buttons {
    position: absolute;
    top: 100px;
    right: 10px;
  }
  
  
  
  hr {
    margin: 10px 0;
  }
  
  .modal-backdrop {
    opacity: 0.5 !important;
  }
  /* Adding a circle bullet point next to each label */
  .label-with-circle::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #A6A6A6;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }
  
  .label-with-circle2::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }
  /* Aligning middle elements like Basic Board and Supplement */
  .align-middle {
    margin-top: 30px; /* Adjust this value based on the spacing you want */
  }
  
  /* Custom shadow for outlet-card */
  .shadow-custom {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .shadow-custom:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
  }
  
  
  
  .edit{
    position: absolute;
  
    top: 55%; /* Adjust the vertical position to be slightly above middle */
    right: -20px; /* Half the button outside the outlet-card */
    transform: translateY(-50%); /* Center vertically relative to its position */
  
  }
  
  .save {
    position: absolute;
    top: 30%; /* Adjust as needed to lower the button */
    right: -18px; /* Adjust to move outside the card */
    transform: translateY(-60%); /* Further adjust for desired position */
  }
  
  .delete {
    position: absolute;
    top: 50%; /* Adjust to position higher or lower */
    right: -18px; /* Same as above */
    transform: translateY(30%); /* Further adjust for desired position */
  
  }
  </style>
  
