import apiClient from "./api";

async function updateSpecialty(id, data) {
    try {
      const response = await apiClient.post(
        `/insert-specialty-cost/${id}`,
        data
      );
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

async function getSpecialties(budgetId) {
    try {
      const response = await apiClient.get(
        `/get-employee-specialties/${budgetId}`);
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

async function getCategories(budgetId) {
  try {
    const response = await apiClient.get(
      `/get-employee-categories/${budgetId}`);
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

export { updateSpecialty, getSpecialties, getCategories };
