import apiClient from "./api";



async function updateOtherExpenses(budget_id, id, data) {
    try {
      const response = await apiClient.post(
        `calculate-other-expense/${budget_id}/${id}`,
        data
      );
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}



async function getOtherExpensesPerCategory(budgetId, categoryId) {
    try {
      const response = await apiClient.get(
        `/get-expenses-per-category/${budgetId}/${categoryId}`);
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

export { updateOtherExpenses, getOtherExpensesPerCategory };
