import apiClient from "./api";

async function createSpecialty(budgetId, data) {
  try {
    const response = await apiClient.post(
      `/insert-employee-specialty/${budgetId}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function createCategory(budgetId, data) {
  try {
    const response = await apiClient.post(
      `/insert-employee-category/${budgetId}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function updateCategory(id, data) {
  try {
    const response = await apiClient.post(
      `/update-employee-category/${id}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function updateSpecialty(id, data) {
    try {
      const response = await apiClient.post(
        `/update-employee-specialty/${id}`,
        data
      );
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

async function deleteSpecialty(id) {
    try {
      const response = await apiClient.post(
        `/delete-employee-specialty/${id}`);
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

async function deleteCategory(id) {
  try {
    const response = await apiClient.post(
      `/delete-employee-category/${id}`);
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function getSpecialties(budgetId) {
    try {
      const response = await apiClient.get(
        `/get-employee-specialties/${budgetId}`);
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

async function getCategories(budgetId) {
  try {
    const response = await apiClient.get(
      `/get-employee-categories/${budgetId}`);
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

export { createSpecialty, updateSpecialty, deleteSpecialty, getSpecialties, getCategories, deleteCategory, createCategory, updateCategory };
