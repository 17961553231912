<template>
    <div class="form-section mt-4 ms-4">
      <!-- Container for title and button -->
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h5 style="font-weight: bolder; color: #555555">ΛΟΙΠΑ ΕΣΟΔΑ</h5>
        </div>
        <div>
          <!-- Display the total sum of all total_revenue, with € symbol only if total is greater than 0 -->
          <h5 style="font-weight: bolder; color: #555555">
            ΣΥΝΟΛΟ: {{ formattedBudgetTotal }}
            <span v-if="budgetTotal"> €</span>
          </h5>
        </div>
      </div>
  
      <!-- Display list of revenue categories -->
      <div class="mt-4 row">
        <div
          v-for="(revenue, index) in items"
          :key="revenue.id"
          class="col-md-2 card shadow-custom p-3 mb-3 bg-white rounded position-relative"
        >
          <!-- Non-Editable View -->
          <div v-if="!revenue.isEditing">
            <div class="row" style="color:#A6A6A6; font-weight:bolder">
              <div class="col-md-8">
                <p class="label-with-circle">
                  <strong>{{ revenue.title }}</strong>
                  <hr />
                </p>
                <p v-if="revenue.type_of_calculation_id !== 2">
                    <strong>KPIs:</strong> {{ revenue.KPIs }}
                </p>
                <p>
                    <strong>Σύνολο Εσόδου:</strong> {{ formatNumber(revenue.total_revenue) }}
                    <span v-if="formatNumber(revenue.total_revenue)">€</span>
                </p>
              </div>
            </div>
  
            <!-- Edit Button -->
            <div class="action-buttons">
              <button class="btn border-0 p-1 edit" @click="editItem(revenue)">
                <img src="../assets/images/edit-budget.png" alt="Edit" width="30" />
              </button>
            </div>
          </div>
  
          <!-- Editable View -->
          <div v-else>
            <div class="row" style="color:#4A4A4A; font-weight:bolder">
              <div class="col-md-12">
                <p class="label-with-circle2">
                  <strong>{{ revenue.title }}</strong>
                  <hr />
                </p>
              </div>
              <div class="col-md-8" v-if="revenue.type_of_calculation_id !== 2">
                <p><strong>KPIs</strong><hr /></p>
                <input
                  type="number"
                  v-model="revenue.KPIs"
                  class="form-control"
                  maxlength="255"
                  required
                />
              </div>
              <div class="col-md-8" v-if="revenue.type_of_calculation_id !== 1">
                <p><strong>Σύνολο Εσόδου</strong><hr /></p>
                <input
                  type="text"
                  v-model="revenue.total_revenue"
                  class="form-control"
                  maxlength="255"
                  required
                />
              </div>
            </div>
            <div class="row form-actions mt-4">
              <div class="d-flex justify-content-end">
                <button
                  class="btn btn-secondary justify-content-end align-items-end"
                  @click="cancelEdit(revenue)"
                >
                  ΑΚΥΡΩΣΗ
                </button>
              </div>
            </div>
  
            <!-- Form Actions -->
            <div class="action-buttons">
              <button class="btn border-0 p-1 save" @click="confirmSaveItem(index)">
                <img src="../assets/images/save.png" alt="Save" width="30" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useHead } from '@vueuse/head';
  import { getOtherRevenues, updateOtherRevenues } from '@/services/other-revenues';
  import { useToast } from 'vue-toastification';
  import {
    fetchBudgetInfo,
    fetchUsers,
    changeUser,
    fetchUserInfo,
  } from '../services/user';
  
  export default {
    name: 'ItemComponent',
    data() {
      return {
        showForm: false,
        showForm2: false,
        form: {
          title: '',
          description: '',
        },
        form2: {
          title: '',
          revenue_revenue_id: null,
          type_of_calculation_id: null,
        },
        items: [], // List for Categories
        items2: [], // List for Revenues
        currentEditRevenue: null, // Track current editing revenue object
        currentEditCategory: null, // Track current editing revenue object
        currentDeleteId: null, // For deleting categories
        currentDeleteId2: null, // For deleting revenues
        budgetTotal: 0, // Add budgetTotal here

      };
    },

    computed: {
    // Computed property to format budgetTotal with European number format
    formattedBudgetTotal() {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(this.budgetTotal);
    },
  },

    
      methods: {
        formatNumber(value) {
          return new Intl.NumberFormat('de-DE', {
           style: 'decimal',
           minimumFractionDigits: 2,
           maximumFractionDigits: 2
           }).format(value);
        },
        // Fetch Revenue Categories
        async fetchItems() {
          try {
            const response = await getOtherRevenues(this.$route.params.budget_id);
            this.items = response.data.map((item) => ({ ...item, isEditing: false }));
          } catch (error) {
            console.error('Error fetching other revenues:', error);
          }
        },

        async fetchBudgetInfoData() {
  try {
    const response = await fetchBudgetInfo(this.$route.params.budget_id);
    if (response) {
      this.budgetTotal = response.data.data.total_other_revenues || 0;  // Correctly set the value of budgetTotal
    }
  } catch (error) {
    console.error('Failed to fetch budget info:', error);
  }
},
    
    
        // Show form for adding new categories
        showAddForm() {
          this.resetForm();
          this.showForm = true;
        },
    
        // Show form for adding new revenues
        showAddForm2() {
          this.resetForm2();
          this.showForm2 = true;
        },
    
        // Reset revenue form
        resetForm() {
          this.form = {
            title: '',
            description: '',
          };
          this.currentEditCategory = null;
          this.showForm = false;
        },
    
        // Reset revenue form
        resetForm2() {
          this.form2 = {
            title: '',
            revenue_revenue_id: null,
            type_of_calculation_id: null,
          };
          this.currentEditRevenue = null;
          this.showForm2 = false;
        },
    
        // Confirm save for revenue
        async confirmSaveItem(index) {
    try {
      const revenue = this.items[index]; // Access the revenue being edited
      
      if (this.currentEditCategory) {
        // Update existing revenue with KPIs and total_revenue
        const response = await updateOtherRevenues(this.currentEditCategory.id, {
          KPIs: revenue.KPIs, // Send updated KPIs
          total_revenue: revenue.total_revenue, // Send updated total_revenue
        });
        
        this.currentEditCategory.isEditing = false; // End editing mode
        this.currentEditCategory = null; // Clear current edit category
        
        if (response.success) {
          useToast().success(response.message); // Show success message
        } else {
          useToast().error(response.message); // Show error message
        }
      }
      
      this.resetForm(); // Reset form after save
      await this.fetchItems(); // Fetch the latest items
      await this.fetchBudgetInfoData();

    } catch (error) {
      console.error('Error saving revenue:', error); // Log any errors
    }
  },

      
    
      
        // Edit revenue
        editItem(revenue) {
          this.resetForm(); // Reset form to avoid conflicts
          this.currentEditCategory = revenue;
          this.form = {
            title: revenue.title,
            description: revenue.description,
          };
          revenue.isEditing = true;
        },
    
       
    
        // Cancel editing revenue
        cancelEdit(revenue) {
          revenue.isEditing = false;
          this.currentEditCategory = null;
          this.resetForm();
        },

    
      },
      mounted() {
        useHead({
          title: 'Λοιπά Έσοδα',
        });
        this.fetchItems().then(() => {
            this.fetchBudgetInfoData();

        });
      },
    };
    </script>
    
    
    
    
    <style scoped>
     .form-container {
      background: #f8f9fa;
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #ddd;
    }
    
    .card {
      padding: 15px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #ddd;
      position: relative;
      margin-left:35px;
    }
    
    .action-buttons {
      position: absolute;
      top: 100px;
      right: 10px;
    }
    
    
    
    hr {
      margin: 10px 0;
    }
    
    .modal-backdrop {
      opacity: 0.5 !important;
    }
    /* Adding a circle bullet point next to each label */
    .label-with-circle::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #A6A6A6;
      border-radius: 50%;
      margin-right: 10px;
      vertical-align: middle;
    }
    
    .label-with-circle2::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: var(--primary-color);
      border-radius: 50%;
      margin-right: 10px;
      vertical-align: middle;
    }
    /* Aligning middle elements like Basic Board and Supplement */
    .align-middle {
      margin-top: 30px; /* Adjust this value based on the spacing you want */
    }
    
    /* Custom shadow for outlet-card */
    .shadow-custom {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease-in-out;
    }
    
    .shadow-custom:hover {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
    }
    
    
    
    .edit{
      position: absolute;
    
      top: 25%; /* Adjust the vertical position to be slightly above middle */
      right: -20px; /* Half the button outside the outlet-card */
      transform: translateY(-200%); /* Center vertically relative to its position */
    
    }
    
    .save {
      position: absolute;
      top: 30%; /* Adjust as needed to lower the button */
      right: -18px; /* Adjust to move outside the card */
      transform: translateY(-95%); /* Further adjust for desired position */
    }
    
    .delete {
      position: absolute;
      top: 50%; /* Adjust to position higher or lower */
      right: -18px; /* Same as above */
      transform: translateY(30%); /* Further adjust for desired position */
    
    }
    </style>
    