<template>
  <div id="mytask-layout" class="theme-indigo">

    <div class="main" style="display: flex;align-items: center;">
        
        <!-- Body: Body -->

              <div class="col-lg-12 d-flex justify-content-center align-items-center border-0 rounded-lg">
                <div style="max-width: 25rem;">
                  
               
            </div>
            <slot />
          </div> <!-- End Row -->
      </div>
    </div>
</template>

<script>
export default {
  name: 'LoginLayout'
};
</script>

<style></style>
