<template>
  <div class="row clearfix g-3">
  </div>
</template>

<script>
import router from '@/router';
import ApexCharts from 'apexcharts';
import { useToast } from 'vue-toastification';

export default {
  name: 'Dashboard',
  data() {},
  computed: {},
  methods: {},
  mounted() {
  }
};
</script>

<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
