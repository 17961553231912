<template>
  <div class="form-section mt-4 ms-4">
    <!-- Container for title and button -->
    <div class="d-flex align-items-center justify-content-start ">
      <h5 style="font-weight: bolder; color: #555555">NEA ΚΑΤΗΓΟΡΙΑ ΕΙΔΙΚΟΤΗΤΑΣ</h5>
      <button v-if="!showCategoryForm" class="btn btn-primary fw-bold" style="margin-left:10px;" @click="showAddCategoryForm">
        ΠΡΟΣΘΗΚΗ +
      </button>
    </div>

    <!-- Category Form for Adding New Category -->
    <div v-if="showCategoryForm" class="col-md-4 form-container">
      <div class="row">
        <div class="col-md-8">
          <p class="label-with-circle2">
            <strong>Τίτλος Κατηγορίας</strong>
            <hr />
          </p>
          <input type="text" v-model="categoryForm.title" class="form-control" maxlength="255" required /><br />
        </div>
      </div>
      <div class="row form-actions mt-4">
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary" @click="resetCategoryForm(); showCategoryForm = false;">
            Ακύρωση
          </button>
          <button class="btn btn-primary ms-2 save-button" @click="openCategorySaveModal">
            Αποθήκευση
          </button>
        </div>
      </div>
    </div>

    <!-- Category List with Inline Editing -->
    <div class="mt-4 row">
      <div v-for="(category, index) in categories" :key="category.id" class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative">
        <!-- Non-Editable View -->
        <div v-if="!editStates[category.id]">
          <div class="row" style="color:#A6A6A6; font-weight:bolder">
            <div class="col-md-8">
              <p class="label-with-circle">
                <strong>{{ category.title }}</strong>
                <hr />
              </p>
            </div>
          </div>

          <div class="action-buttons">
            <button class="btn border-0 p-1 edit" @click="editCategory(category)">
              <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
            </button>
          </div>
        </div>

        <!-- Editable View (Inline) -->
        <div v-else>
          <div class="row" style="color:#4A4A4A; font-weight:bolder">
            <div class="col-md-8">
              <p class="label-with-circle2">
                <strong>Τίτλος Κατηγορίας</strong>
                <hr />
              </p>
              <input type="text" v-model="categoryForm.title" class="form-control" maxlength="255" required />
            </div>
          </div>
          <div class="row form-actions mt-4">
            <div class="d-flex justify-content-middle">
              <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditCategory(category)">
                ΑΚΥΡΩΣΗ
              </button>
            </div>
          </div>

          <!-- Form Actions -->
          <div class="action-buttons">
            <button class="btn border-0 p-1 save" @click="openCategorySaveModal">
              <img src="../assets/images/save.png" alt="Save" width="30">
            </button>
            <button class=" btn border-0 p-1 delete" @click="openDeleteCategoryModal(category)">
              <img src="../assets/images/bin.png" alt="Delete" width="30">
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Save Confirmation Modal for Categories -->
    <div class="modal fade" id="categorySaveModal" tabindex="-1" aria-labelledby="categorySaveModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="categorySaveModalLabel">Επιβεβαίωση Αποθήκευσης</h5>
            <button type="button" class="close" @click="closeCategorySaveModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Είστε σίγουροι ότι θέλετε να αποθηκεύσετε αυτήν την κατηγορία;
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeCategorySaveModal">Ακύρωση</button>
            <button type="button" class="btn btn-primary" @click="confirmSaveCategory">Αποθήκευση</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal for Categories -->
    <div class="modal fade" id="deleteCategoryModal" tabindex="-1" aria-labelledby="deleteCategoryModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteCategoryModalLabel">Επιβεβαίωση Διαγραφής</h5>
            <button type="button" class="close" @click="closeDeleteCategoryModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Είστε σίγουροι ότι θέλετε να διαγράψετε αυτήν την κατηγορία;
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeDeleteCategoryModal">Ακύρωση</button>
            <button type="button" class="btn btn-danger" @click="confirmDeleteCategory">Διαγραφή</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Specialties CRUD Section -->
    <div class="form-section mt-4">
      <!-- Container for title and button -->
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <h5 style="font-weight: bolder; color: #555555">NEA ΕΙΔΙΚΟΤΗΤΑ</h5>
          <button v-if="!showForm" class="btn btn-primary fw-bold" style="margin-left:10px;" @click="showAddSpecialtyForm">
            ΠΡΟΣΘΗΚΗ +
          </button>
        </div>

        <!-- Right-aligned category selection -->
        <div class="d-flex align-items-center">
          <h5 style="font-weight: bolder; color: #555555; margin-right: 10px;">Επιλέξτε Κατηγορία</h5>
          <div class="dropdown">
            <select v-model="selectedCategory" class="form-select">
              <option value="">Όλες</option> <!-- Option for all categories -->
              <option v-for="(title, id) in categoryTitles" :key="id" :value="id">
                {{ title }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- Specialty Form -->
      <div v-if="showForm" class="form-container mt-4">
        <div class="row" style="color:#4A4A4A; font-weight:bolder">
          <div class="col-md-4">
            <p class="label-with-circle2">
              <strong>Τίτλος Ειδικότητας</strong>
              <hr />
            </p>
            <input type="text" v-model="form.title" class="form-control" maxlength="255" required /><br />
          </div>
          <div class="col-md-4">
            <p class="label-with-circle2">
              <strong>Κατηγορία Ειδικότητας</strong>
              <hr />
            </p>
            <select v-model="form.employee_category_id" class="form-control" required>
              <option v-for="(title, id) in categoryTitles" :key="id" :value="id">
                {{ title }}
              </option>
            </select><br />
          </div>
        </div>
        <div class="row form-actions mt-4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary" @click="resetForm(); showForm = false;">
              Ακύρωση
            </button>
            <button class="btn btn-primary ms-2 save-button" @click="openSpecialtySaveModal($event, null)">
              Αποθήκευση
            </button>
          </div>
        </div>
      </div>

      <!-- Specialties List (Grouped by Category) -->
      <div v-if="filteredSpecialties.length > 0" class=" mt-4">
        <div v-for="(specialtiesByCategory, categoryId) in groupedSpecialties" :key="categoryId">
          <h5 style="font-weight: bold; color: #333;">
            {{ categoryTitles[categoryId] }}
          </h5>
          <div class="row">
            <div v-for="(specialty, index) in specialtiesByCategory" :key="specialty.id" class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative">
              <!-- Non-Editable View -->
              <div v-if="!editStates[specialty.id]">
                <div class="row" style="color:#A6A6A6; font-weight:bolder">
                  <div class="col-md-8">
                    <p class="label-with-circle">
                      <strong>{{ specialty.title }}</strong>
                      <hr />
                    </p>
                  </div>

                  <div class="action-buttons">
                    <button class="btn border-0 p-1 edit" @click="editSpecialty(specialty)">
                      <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
                    </button>
                  </div>
                </div>
              </div>

              <!-- Editable View (Inline) -->
              <div v-else>
                <div class="row">
                  <div class="col-md-8 form-group">
                    <p class="label-with-circle2">
                      <strong>Τίτλος Ειδικότητας</strong>
                      <hr />
                    </p>
                    <input type="text" v-model="specialty.title" class="form-control" maxlength="255" required />
                  </div><br>

                  <!-- Editable Category -->
                  <div class="col-md-8 mt-2 form-group">
                    <p class="label-with-circle2">
                      <strong>Κατηγορία Ειδικότητας</strong>
                      <hr />
                    </p>
                    <select v-model="specialty.employee_category_id" class="form-control" required>
                      <option v-for="(title, id) in categoryTitles" :key="id" :value="id">
                        {{ title }}
                      </option>
                    </select><br />
                  </div>
                </div>

                <div class="row form-actions mt-4">
                  <div class="d-flex justify-content-end">
                    <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditSpecialty(specialty)">
                      ΑΚΥΡΩΣΗ
                    </button>
                  </div>
                </div>

                <!-- Form Actions -->
                <div class="action-buttons">
                  <button class="btn border-0 p-1 save" @click="openSpecialtySaveModal($event, specialty)">
                    <img src="../assets/images/save.png" alt="Save" width="30">
                  </button>
                  <button class=" btn border-0 p-1 delete" @click="openDeleteSpecialtyModal(specialty)">
                    <img src="../assets/images/bin.png" alt="Delete" width="30">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Save Confirmation Modal for Specialties -->
    <div class="modal fade" id="specialtySaveModal" tabindex="-1" aria-labelledby="specialtySaveModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="specialtySaveModalLabel">Επιβεβαίωση Αποθήκευσης</h5>
            <button type="button" class="close" @click="closeSpecialtySaveModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">Είστε σίγουροι ότι θέλετε να αποθηκεύσετε αυτήν την ειδικότητα;</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeSpecialtySaveModal">Ακύρωση</button>
            <button type="button" class="btn btn-primary" @click="confirmSaveSpecialty">Αποθήκευση</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal for Specialties -->
    <div class="modal fade" id="deleteSpecialtyModal" tabindex="-1" aria-labelledby="deleteSpecialtyModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteSpecialtyModalLabel">Επιβεβαίωση Διαγραφής</h5>
            <button type="button" class="close" @click="closeDeleteSpecialtyModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">Είστε σίγουροι ότι θέλετε να διαγράψετε αυτήν την ειδικότητα;</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeDeleteSpecialtyModal">Ακύρωση</button>
            <button type="button" class="btn btn-danger" @click="confirmDeleteSpecialty">Διαγραφή</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import { useToast } from 'vue-toastification';
import {
  createCategory,
  getCategories,
  updateCategory,
  deleteCategory,
  createSpecialty,
  getSpecialties,
  updateSpecialty,
  deleteSpecialty,
} from '@/services/employee-specialties';

export default {
  name: 'EmployeeSpecialties',
  data() {
    return {
      showCategoryForm: false,
      showForm: false,
      isEditingSpecialty: false,
      showSpecialtySaveModal: false, // Modal for saving specialties
      showDeleteSpecialtyModal: false, // Modal for deleting specialties
      showCategorySaveModal: false, // Modal for saving categories
      showDeleteCategoryModal: false, // Modal for deleting categories
      categoryForm: {
        title: '',
      },
      form: {
        title: '',
        employee_category_id: null,
      },
      categories: [],
      specialties: [],
      selectedCategory: '', // Initialize as an empty string
      categoryTitles: {},
      editStates: {}, // Store the editing state separately
      currentEditSpecialty: null,
      currentDeleteSpecialty: null,
      currentEditCategory: null,
      currentDeleteCategory: null,
    };
  },
  computed: {
    // Filter specialties based on selected category
    filteredSpecialties() {
      if (!this.selectedCategory) return this.specialties;
      return this.specialties.filter(specialty => specialty.employee_category_id == this.selectedCategory);
    },

    // Grouped specialties based on category
    groupedSpecialties() {
      return this.filteredSpecialties.reduce((acc, specialty) => {
        const categoryId = specialty.employee_category_id;
        if (!acc[categoryId]) {
          acc[categoryId] = [];
        }
        acc[categoryId].push(specialty);
        return acc;
      }, {});
    }
  },
  methods: {
    // Category methods
    async fetchCategories() {
      try {
        const response = await getCategories(this.$route.params.budget_id);
        this.categories = response.data;
        this.categoryTitles = this.categories.reduce((acc, category) => {
          acc[category.id] = category.title;
          return acc;
        }, {});
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
    showAddCategoryForm() {
      this.resetCategoryForm();
      this.showCategoryForm = true;
      this.isEditingCategory = false;
    },
    resetCategoryForm() {
      this.categoryForm = { title: '' };
      this.currentEditCategory = null;
    },
    openCategorySaveModal() {
      $('#categorySaveModal').modal('show');
    },
    closeCategorySaveModal() {
      $('#categorySaveModal').modal('hide');
    },
    openDeleteCategoryModal(category) {
      this.currentDeleteCategory = category;
      $('#deleteCategoryModal').modal('show');
    },
    closeDeleteCategoryModal() {
      $('#deleteCategoryModal').modal('hide');
    },
    async confirmSaveCategory() {
      try {
        const form = { title: this.categoryForm.title };
        if (this.isEditingCategory && this.currentEditCategory) {
          const response = await updateCategory(this.currentEditCategory.id, form);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          this.currentEditCategory.title = form.title;
          this.editStates[this.currentEditCategory.id] = false;
        } else {
          const response = await createCategory(this.$route.params.budget_id, form);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          this.categories.push(response.data);
        }
        this.fetchCategories();
        this.resetCategoryForm();
        this.closeCategorySaveModal();
        this.showCategoryForm = false;
      } catch (error) {
        console.error('Error saving category:', error);
        this.closeCategorySaveModal();
      }
    },
    editCategory(category) {
      this.currentEditCategory = category;
      this.categoryForm.title = category.title;
      this.editStates = { ...this.editStates, [category.id]: true };
      this.isEditingCategory = true;
    },
    cancelEditCategory(category) {
      this.editStates[category.id] = false;
      this.resetCategoryForm();
    },
    async confirmDeleteCategory() {
      try {
        const category = this.currentDeleteCategory;
        const response = await deleteCategory(category.id);
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        const index = this.categories.findIndex((c) => c.id === category.id);
        if (index !== -1) {
          this.categories.splice(index, 1);
        }
        this.fetchCategories();
        this.closeDeleteCategoryModal();
      } catch (error) {
        console.error('Error deleting category:', error);
        this.closeDeleteCategoryModal();
      }
    },

    // Specialty methods
    async fetchSpecialties() {
      try {
        const response = await getSpecialties(this.$route.params.budget_id);
        this.specialties = response.data;
      } catch (error) {
        console.error('Error fetching specialties:', error);
      }
    },
    showAddSpecialtyForm() {
      this.resetForm();
      this.showForm = true;
      this.currentEditSpecialty = null; // Reset any previous edits
    },
    resetForm() {
      this.form = {
        title: '',
        employee_category_id: null,
      };
      this.currentEditSpecialty = null; // Reset the edit state
    },
    openSpecialtySaveModal(ev, specialty = null) {
      if (specialty) {
        this.currentEditSpecialty = specialty;
      } else {
        this.currentEditSpecialty = null; // Ensure this is null when creating a new specialty
      }
      $('#specialtySaveModal').modal('show');
    },
    closeSpecialtySaveModal() {
      $('#specialtySaveModal').modal('hide');
    },
    openDeleteSpecialtyModal(specialty) {
      this.currentDeleteSpecialty = specialty;
      $('#deleteSpecialtyModal').modal('show');
    },
    closeDeleteSpecialtyModal() {
      $('#deleteSpecialtyModal').modal('hide');
    },
    async confirmSaveSpecialty() {
      try {
        if (!this.currentEditSpecialty) {
          // Create a new specialty
          const response = await createSpecialty(this.$route.params.budget_id, this.form);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          this.specialties.push(response.data); // Add the new specialty to the list
        } else {
          // Update existing specialty
          const response = await updateSpecialty(this.currentEditSpecialty.id, this.currentEditSpecialty);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          this.editStates[this.currentEditSpecialty.id] = false; // Close the edit mode after saving
        }
        this.fetchSpecialties();
        this.closeSpecialtySaveModal();
        this.resetForm();
        this.showForm = false; // Close the form after saving
      } catch (error) {
        console.error('Error saving specialty:', error);
        this.closeSpecialtySaveModal();
      }
    },
    editSpecialty(specialty) {
      this.editStates = { ...this.editStates, [specialty.id]: true };
      this.currentEditSpecialty = specialty;
    },
    cancelEditSpecialty(specialty) {
      this.editStates[specialty.id] = false;
      this.currentEditSpecialty = null; // Reset the edit state
    },
    async confirmDeleteSpecialty() {
      try {
        const specialty = this.currentDeleteSpecialty;
        const response = await deleteSpecialty(specialty.id);
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        const index = this.specialties.findIndex((s) => s.id === specialty.id);
        if (index !== -1) {
          this.specialties.splice(index, 1);
        }
        this.closeDeleteSpecialtyModal();
      } catch (error) {
        console.error('Error deleting specialty:', error);
        this.closeDeleteSpecialtyModal();
      }
    },
  },
  mounted() {
    useHead({
      title: 'Ειδικότητες Εργαζομένων',
    });
    this.fetchCategories();
    this.fetchSpecialties();
  },
};
</script>

<style scoped>
  .form-container {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }

  .card {
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
    position: relative;
    margin-left: 35px;
  }

  .action-buttons {
    position: absolute;
    top: 100px;
    right: 10px;
  }

  .label-with-circle::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #a6a6a6;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }

  .label-with-circle2::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }

  .align-middle {
    margin-top: 30px;
  }

  .shadow-custom {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }

  .shadow-custom:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
  }

  .edit {
    position: absolute;
    top: 25%; /* Adjust the vertical position to be slightly above middle */
    right: -20px; /* Half the button outside the outlet-card */
    transform: translateY(-200%); /* Center vertically relative to its position */
  }

  .save {
    position: absolute;
    top: 30%; /* Adjust as needed to lower the button */
    right: -18px; /* Adjust to move outside the card */
    transform: translateY(-95%); /* Further adjust for desired position */
  }

  .delete {
    position: absolute;
    top: 50%; /* Adjust to position higher or lower */
    right: -18px; /* Same as above */
    transform: translateY(30%); /* Further adjust for desired position */
  }
</style>
