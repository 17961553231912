<template>
  <div class="form-section mt-4 ms-4">
     <!-- Container for title and button -->
     <div class="d-flex align-items-center justify-content-start ">
        <h5 style="font-weight: bolder; color: #555555">NEA ΚΑΤΗΓΟΡΙΑ ΕΣΟΔΩΝ</h5>
        <button v-if="!showForm" class="btn btn-primary fw-bold" style="margin-left:10px;" @click="showAddForm">ΠΡΟΣΘΗΚΗ +</button>
      </div>
  
      <!-- Form Container for NEW REVENUE CATEGORY -->
      <div v-if="showForm" class="col-md-4 form-container">
        <div class="row" style="color:#4A4A4A; font-weight:bolder">
          <div class="col-md-8">
            <p class="label-with-circle2"><strong>TIΤΛΟΣ</strong><hr/></p>
            <input
              type="text"
              v-model="form.title"
              class="form-control"
              maxlength="255"
              required
            />
          </div>
        </div>
  
        <div class="row form-actions mt-3">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary" @click="resetForm">
              ΑΚΥΡΩΣΗ
            </button>
            <button class="btn btn-primary ms-2 save-button" @click="confirmSaveItem">ΑΠΟΘΗΚΕΥΣΗ</button>
          </div>
        </div>
      </div>
  
      <!-- Display list of revenue categories -->
      <div class="mt-4 row">
        <div
          v-for="(category, index) in items"
          :key="category.id"
          class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative"
        >
          <!-- Non-Editable View -->
          <div v-if="!category.isEditing">
            <div class="row" style="color:#A6A6A6; font-weight:bolder">
              <div class="col-md-8">
                <p class="label-with-circle"><strong>{{ category.title }}</strong><hr/></p>
              </div>
            </div>
  
            <!-- Edit Button -->
            <div class="action-buttons">
              <button class="btn border-0 p-1 edit" @click="editItem(category)">
                <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
              </button>
            </div>
          </div>
  
          <!-- Editable View -->
          <div v-else>
            <div class="row" style="color:#4A4A4A; font-weight:bolder">
              <div class="col-md-8">
                <p class="label-with-circle2"><strong>TIΤΛΟΣ</strong><hr/></p>
                <input
                  type="text"
                  v-model="form.title"
                  class="form-control"
                  maxlength="255"
                  required
                />
              </div>
            </div>
            <div class="row form-actions mt-4">
              <div class="d-flex justify-content-middle">
                  <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEdit(category)">
                   ΑΚΥΡΩΣΗ
                  </button>
              </div>
            </div>

             <!-- Form Actions -->
    <div class="action-buttons">
      <button class="btn border-0 p-1 save" @click="confirmSaveItem(index)">
        <img src="../assets/images/save.png" alt="Save" width="30">
      </button>
      <button class=" btn border-0 p-1 delete" @click="openDeleteModal(category.id)">
        <img src="../assets/images/bin.png" alt="Delete" width="30">
      </button>
      
    </div>
          </div>
        </div>
      </div>
  
      <!-- Section for NEW REVENUE ITEM -->
      <div class="d-flex align-items-center justify-content-start mt-5">
        <h5 style="font-weight: bolder; color: #555555">ΝΕΟ ΕΣΟΔΟ</h5>
        <button class="btn btn-primary fw-bold" v-if="!showForm2" style="margin-left:10px;" @click="showAddForm2">ΠΡΟΣΘΗΚΗ +</button>
      </div>
  
      <!-- Form Container for NEW REVENUE ITEM -->
      <div v-if="showForm2" class="form-container">
        <div class="row" style="color:#4A4A4A; font-weight:bolder">
          <div class="col-md-4">
            <p class="label-with-circle2"><strong>ΤΙΤΛΟΣ</strong><hr/></p>
            <input
              type="text"
              v-model="form2.title"
              class="form-control"
              maxlength="255"
              required
            />
          </div>
  
          <div class="col-md-4">
            <p class="label-with-circle2"><strong>ΚΑΤΗΓΟΡΙΑ ΕΣΟΔΟΥ</strong><hr/></p>
            <select v-model="form2.revenue_category_id" class="form-control" required>
              <option v-for="item in items" :key="item.id" :value="item.id">{{ item.title }}</option>
            </select>
          </div>
        </div>
  
          <div class="col-md-4" v-if="form2.revenue_category_id == 10">
            <p class="label-with-circle2"><strong>TΥΠΟΣ ΥΠΟΛΟΓΙΣΜΟΥ</strong><hr/></p>
            <select v-model="form2.type_of_calculation_id" class="form-control" required>
              <option value="1">KPIs</option>
              <option value="2">Entry Data</option>
            </select>
          </div>
  
        <div class="row form-actions mt-2">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary" @click="resetForm2">ΑΚΥΡΩΣΗ</button>
            <button class="btn btn-primary ms-2 save-button" @click="confirmSaveItem2">ΑΠΟΘΗΚΕΥΣΗ</button>
          </div>
        </div>
      </div>
  
      <!-- Categorized list of revenues -->
      <div class="mt-4">
        <div v-for="(revenues, categoryTitle) in categorizedRevenues" :key="categoryTitle">
          <h5 style="font-weight: bold; color: #333;">{{ categoryTitle }}</h5>
          <div class="row">
            <div
              v-for="(revenue, index) in revenues"
              :key="revenue.id"
              class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative"
            >
              <!-- Non-Editable View -->
              <div v-if="!revenue.isEditing">
                <div class="row" style="color:#A6A6A6; font-weight:bolder">
                  <div class="col-md-8">
                    <p class="label-with-circle"><strong>{{ revenue.title }}</strong><hr/></p>
                  </div>
                </div>
                <!-- Edit Button -->
                <div class="action-buttons">
                  <button class="btn border-0 p-1 edit" @click="editItem2(revenue)">
                    <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
                  </button>
                </div>
              </div>
  
              <!-- Editable View -->
              <div v-else>
                <div class="row">
                  <div class="col-md-8 form-group">
                    <p class="label-with-circle2"><strong>ΤΙΤΛΟΣ</strong><hr/></p>
                    <input
                      type="text"
                      v-model="form2.title"
                      class="form-control"
                      maxlength="255"
                      required
                    />
                  </div>
                  <div class="col-md-12 form-group mt-3">
                    <p class="label-with-circle2"><strong>KATHΓΟΡΙΑ ΕΣΟΔΟΥ</strong><hr/></p>
                    <select v-model="form2.revenue_category_id" class="form-control" required>
                      <option v-for="item in items" :key="item.id" :value="item.id">{{ item.title }}</option>
                    </select>
                  </div>
                  <div class="col-md-12 form-group mt-3" v-if="form2.revenue_category_id == 10">
                    <p class="label-with-circle2"><strong>TΥΠΟΣ ΥΠΟΛΟΓΙΣΜΟΥ</strong><hr/></p>
                    <select v-model="form2.type_of_calculation_id" class="form-control" required>
                        <option value="1">KPIs</option>
                        <option value="2">Entry Data</option>
                    </select>
                  </div>
                </div>
                

                <div class="row form-actions mt-4">
              <div class="d-flex justify-content-end">
                  <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEdit2(revenue)">
                   ΑΚΥΡΩΣΗ
                  </button>
              </div>
            </div>

             <!-- Form Actions -->
    <div class="action-buttons">
      <button class="btn border-0 p-1 save" @click="confirmSaveItem2">
        <img src="../assets/images/save.png" alt="Save" width="30">
      </button>
      <button class=" btn border-0 p-1 delete" @click="openDeleteModal2(revenue.id)">
        <img src="../assets/images/bin.png" alt="Delete" width="30">
      </button>
      
    </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Delete Modal for Categories -->
        <div id="deleteModal" class="modal fade" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Confirm Delete</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeDeleteModal">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete this category?</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeDeleteModal">Cancel</button>
                <button type="button" class="btn btn-danger" @click="confirmDeleteCategory">Delete</button>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Delete Modal for Revenues -->
        <div id="deleteModal2" class="modal fade" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Confirm Delete</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeDeleteModal2">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete this revenue item?</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeDeleteModal2">Cancel</button>
                <button type="button" class="btn btn-danger" @click="confirmDeleteRevenue">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- End of main container -->
  </template>
  
  
  <script>
  import { useHead } from '@vueuse/head';
  import {
    createRevenue,
    updateRevenue,
    deleteRevenue,
    getRevenues,
  } from '@/services/revenues';
  import {
    createRevenueCategory,
    getRevenueCategories,
    updateRevenueCategory,
    deleteRevenueCategory,
  } from '@/services/revenue-categories';
import { useToast } from 'vue-toastification';
  
  export default {
    name: 'ItemComponent',
    data() {
      return {
        showForm: false,
        showForm2: false,
        form: {
          title: '',
          description: '',
        },
        form2: {
          title: '',
          revenue_category_id: null,
          type_of_calculation_id: null,
        },
        items: [], // List for Categories
        items2: [], // List for Revenues
        currentEditRevenue: null, // Track current editing revenue object
        currentEditCategory: null, // Track current editing category object
        currentDeleteId: null, // For deleting categories
        currentDeleteId2: null, // For deleting revenues
      };
    },
    computed: {
      categorizedRevenues() {
        return this.items2.reduce((grouped, revenue) => {
          const categoryTitle = revenue.revenue_category?.title || 'Uncategorized';
          if (!grouped[categoryTitle]) {
            grouped[categoryTitle] = [];
          }
          grouped[categoryTitle].push(revenue);
          return grouped;
        }, {});
      },
    },
    methods: {
      // Fetch Revenue Categories
      async fetchItems() {
        try {
          const response = await getRevenueCategories(this.$route.params.budget_id);
          this.items = response.data.map((item) => ({ ...item, isEditing: false }));
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      },
  
      // Fetch Revenues and attach revenue_category data
      async fetchRevenues() {
        try {
          const response = await getRevenues(this.$route.params.budget_id);
          // Map the revenues and attach the revenue_category data
          this.items2 = response.data.map((revenue) => {
            // Find the matching category
            const category = this.items.find((cat) => cat.id === revenue.revenue_category_id);
            return {
              ...revenue,
              revenue_category: category,
              isEditing: false,
            };
          });
        } catch (error) {
          console.error('Error fetching revenues:', error);
        }
      },
  
      // Show form for adding new categories
      showAddForm() {
        this.resetForm();
        this.showForm = true;
      },
  
      // Show form for adding new revenues
      showAddForm2() {
        this.resetForm2();
        this.showForm2 = true;
      },
  
      // Reset category form
      resetForm() {
        this.form = {
          title: '',
          description: '',
        };
        this.currentEditCategory = null;
        this.showForm = false;
      },
  
      // Reset revenue form
      resetForm2() {
        this.form2 = {
          title: '',
          revenue_category_id: null,
          type_of_calculation_id: null,
        };
        this.currentEditRevenue = null;
        this.showForm2 = false;
      },
  
      // Confirm save for category
      async confirmSaveItem() {
        try {
          if (this.currentEditCategory) {
            // Update existing category
            const response = await updateRevenueCategory(this.currentEditCategory.id, {
              title: this.form.title,
              description: this.form.description,
            });
            this.currentEditCategory.isEditing = false;
            this.currentEditCategory = null;
            if (response.success) {
              useToast().success(response.message);
            } else {
              useToast().error(response.message);
            }
          } else {
            // Create new category
            const response = await createRevenueCategory(this.$route.params.budget_id, {
              title: this.form.title,
              description: this.form.description,
            });
            if (response.success) {
              useToast().success(response.message);
            } else {
              useToast().error(response.message);
            }
          }
          this.resetForm();
          await this.fetchItems();
          await this.fetchRevenues(); // Refresh revenues to update categories
        } catch (error) {
          console.error('Error saving category:', error);
        }
      },
  
      // Confirm save for revenue
      async confirmSaveItem2() {
        try {
          if (this.currentEditRevenue) {
            // Update existing revenue
            const response = await updateRevenue(this.currentEditRevenue.id, {
              title: this.form2.title,
              revenue_category_id: this.form2.revenue_category_id,
              type_of_calculation_id: this.form2.type_of_calculation_id,
            });
            if (response.success) {
              useToast().success(response.message);
            } else {
              useToast().error(response.message);
            }
            this.currentEditRevenue.isEditing = false;
            this.currentEditRevenue = null;
          } else {
            // Create new revenue
            const response = await createRevenue(this.$route.params.budget_id, {
              title: this.form2.title,
              revenue_category_id: this.form2.revenue_category_id,
              type_of_calculation_id: this.form2.type_of_calculation_id,
            });
            if (response.success) {
              useToast().success(response.message);
            } else {
              useToast().error(response.message);
            }
          }
          this.resetForm2();
          await this.fetchRevenues();
        } catch (error) {
          console.error('Error saving revenue:', error);
        }
      },
  
      // Edit category
      editItem(category) {
        this.resetForm(); // Reset form to avoid conflicts
        this.currentEditCategory = category;
        this.form = {
          title: category.title,
          description: category.description,
        };
        category.isEditing = true;
      },
  
      // Edit revenue
      editItem2(revenue) {
        this.resetForm2(); // Reset form to avoid conflicts
        this.currentEditRevenue = revenue;
        this.form2 = {
          title: revenue.title,
          revenue_category_id: revenue.revenue_category_id,
          type_of_calculation_id: revenue.type_of_calculation_id,
        };
        revenue.isEditing = true;
      },
  
      // Cancel editing category
      cancelEdit(category) {
        category.isEditing = false;
        this.currentEditCategory = null;
        this.resetForm();
      },
  
      // Cancel editing revenue
      cancelEdit2(revenue) {
        revenue.isEditing = false;
        this.currentEditRevenue = null;
        this.resetForm2();
      },
  
      // Open delete modal for category
      openDeleteModal(id) {
        this.currentDeleteId = id;
        $('#deleteModal').modal('show');
      },
  
      // Close delete modal for category
      closeDeleteModal() {
        $('#deleteModal').modal('hide');
        this.currentDeleteId = null;
      },
  
      // Confirm delete category
      async confirmDeleteCategory() {
        try {
          const response = await deleteRevenueCategory(this.currentDeleteId);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          this.items = this.items.filter((category) => category.id !== this.currentDeleteId);
          this.currentDeleteId = null;
          $('#deleteModal').modal('hide');
          useToast().success(r.message);
          await this.fetchRevenues(); // Refresh revenues to update categories
        } catch (error) {
          console.error('Error deleting category:', error);
          $('#deleteModal').modal('hide');
        }
      },
  
      // Open delete modal for revenue
      openDeleteModal2(id) {
        this.currentDeleteId2 = id;
        $('#deleteModal2').modal('show');
      },
  
      // Close delete modal for revenue
      closeDeleteModal2() {
        $('#deleteModal2').modal('hide');
        this.currentDeleteId2 = null;
      },
  
      // Confirm delete revenue
      async confirmDeleteRevenue() {
        try {
          const response = await deleteRevenue(this.currentDeleteId2);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          this.items2 = this.items2.filter((revenue) => revenue.id !== this.currentDeleteId2);
          this.currentDeleteId2 = null;
          $('#deleteModal2').modal('hide');
        } catch (error) {
          console.error('Error deleting revenue:', error);
          $('#deleteModal2').modal('hide');
        }
      },
    },
    mounted() {
      useHead({
        title: 'Έσοδα',
      });
      this.fetchItems().then(() => {
        this.fetchRevenues();
      });
    },
  };
  </script>
  
  
  
  
  <style scoped>
   .form-container {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .card {
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
    position: relative;
    margin-left:35px;
  }
  
  .action-buttons {
    position: absolute;
    top: 100px;
    right: 10px;
  }
  
  
  
  hr {
    margin: 10px 0;
  }
  
  .modal-backdrop {
    opacity: 0.5 !important;
  }
  /* Adding a circle bullet point next to each label */
  .label-with-circle::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #A6A6A6;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }
  
  .label-with-circle2::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }
  /* Aligning middle elements like Basic Board and Supplement */
  .align-middle {
    margin-top: 30px; /* Adjust this value based on the spacing you want */
  }
  
  /* Custom shadow for outlet-card */
  .shadow-custom {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .shadow-custom:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
  }
  
  
  
  .edit{
    position: absolute;
  
    top: 25%; /* Adjust the vertical position to be slightly above middle */
    right: -20px; /* Half the button outside the outlet-card */
    transform: translateY(-200%); /* Center vertically relative to its position */
  
  }
  
  .save {
    position: absolute;
    top: 30%; /* Adjust as needed to lower the button */
    right: -18px; /* Adjust to move outside the card */
    transform: translateY(-95%); /* Further adjust for desired position */
  }
  
  .delete {
    position: absolute;
    top: 50%; /* Adjust to position higher or lower */
    right: -18px; /* Same as above */
    transform: translateY(30%); /* Further adjust for desired position */
  
  }
  </style>
  