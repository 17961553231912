<template>
  <div id="mytask-layout" class="theme-indigo">
    <!-- Modal for Unsaved Changes -->
    <div
      class="modal fade"
      id="unsavedChangesModal"
      tabindex="-1"
      aria-labelledby="unsavedChangesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="unsavedChangesModalLabel">Unsaved Changes</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            You have unsaved changes. Are you sure you want to leave this page without saving?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Stay on Page
            </button>
            <button
              type="button"
              class="btn btn-primary"
              id="confirmLeaveBtn"
            >
              Leave Page
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Main body area -->
    <div class="main px-lg-4 px-md-4 p-0">
      <!-- Navigation bar -->
      <div id="main-header">
        <nav class="navbar p-0">
          <div class="container-xxl d-flex justify-content-between align-items-center p-0">
            <!-- Left: Logo and Final Budget Section -->
            <div class="d-flex align-items-center ms-0">
              <div class="logo-container me-3">
                <router-link :to="{ path: '/hotels/1/budgets' }">
                  <img
                    src="../assets/images/logo.png"
                    alt="Logo"
                    class="logo"
                    style="height: 150px; width: 150px;"
                  />
                </router-link>
              </div>

              <!-- Final Budget and Date Section -->
              <div class="right-section d-flex flex-column align-items-start">
                <div class="final-budget">
                  <h2 class="mb-0" style="font-weight: bolder; color: #555555">
                    {{ budgetTitle }}
                  </h2>
                </div>

                <div class="date-section d-flex align-items-center mt-2">
                  <img
                    src="../assets/images/calendar.png"
                    alt="Logo"
                    class="logo"
                    style="width: 32px;"
                  />
                  <span
                    class="fw-bold"
                    style="color:#555555; font-size:x-large; margin-left: 5px"
                  >
                    {{ budgetYear }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Right: Combined flex container for nav tabs and user profile -->
            <div class="d-flex align-items-center ms-auto">
              <!-- Right: Navigation Tabs Section -->
              <div class="h-right d-flex align-items-center">
                <ul class="nav nav-tabs" style="margin-right:10px; margin-left: 10px; margin-top:2px;">
                  <li class="nav-item1">
                    <a
                      :class="['nav-link1', isDomikaActive ? 'active' : '']"
                      href="#"
                      @click.prevent="navigateToDomika"
                    >
                      ΔΟΜΙΚΑ ΣΤΟΙΧΕΙΑ
                    </a>
                  </li>
                  <li class="nav-item1">
                    <a
                      :class="['nav-link1', isDataActive ? 'active' : '']"
                      href="#"
                    >
                      ΔΕΔΟΜΕΝΑ
                    </a>
                  </li>
                </ul>
              </div>

              <!-- Right: User Profile Dropdown -->
              <div class="dropdown ms-2 user-profile d-flex align-items-center">
                <a @click.prevent="toggleMenu()" class="nav-link dropdown-toggle user-menu-toggler pulse p-0" href="#"
                  role="button">
                  <img class="avatar lg rounded-circle img-thumbnail" src="../assets/images/profile.png" alt="profile">
                </a>
                <div
                  :class="{ 'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0': !isMenuOpen, 'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0 show': isMenuOpen }"
                  :style="menuStyle">
                  <div class="card border-0 w280">
                    <div class="card-body pb-0">
                      <div class="d-flex py-1">
                        <img class="avatar rounded-circle" src="../assets/images/profile.png" alt="profile">
                        <div class="flex-fill ms-3">
                          <p class="mb-0">
                            <span class="font-weight-bold user-name" v-if="userInfo">{{ userInfo.name }}</span>
                          </p>
                          <small class="user-email" v-if="userInfo">{{ userInfo.email }}</small>
                        </div>
                      </div>
                      <div>
                        <hr class="dropdown-divider border-dark">
                      </div>
                    </div>
                    <div class="list-group m-2">
                      <RouterLink to="#">
                        <a @click.prevent="showModal" class="list-group-item list-group-item-action border-0">
                          <i class="icofont-ui-settings fs-5 me-3"></i>Αλλαγή password
                        </a>
                      </RouterLink>
                      <a @click.prevent="logout" href="#" class="list-group-item list-group-item-action border-0 btn-logout">
                        <i class="icofont-logout fs-6 me-3"></i>Αποσύνδεση
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

  
        <!-- Main content area -->
        <div id="main-container">
          <!-- Upper Menu Section for Domika -->
          <div class="upper-menu justify-content-center d-flex second-menu" v-if="isDomikaActive">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  :class="['nav-link', isRoomTypeActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('room-type')"
                >
                  TΥΠΟΙ ΔΩΜΑΤΙΩΝ
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="['nav-link', isRevenueCategoriesActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('revenue-categories')"
                >
                  EΣΟΔΑ
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="['nav-link', isOutletsActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('outlets')"
                >
                  OUTLETS
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="['nav-link', isEmployeeSpecialtiesActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('employee-specialties')"
                >
                  ΕΙΔΙΚΟΤΗΤΕΣ ΕΡΓΑΖΟΜΕΝΩΝ
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="['nav-link', isOperationalDepartmentActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('operational-department')"
                >
                  ΛΕΙΤΟΥΡΓΙΚΑ ΤΜΗΜΑΤΑ
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="['nav-link', isExpenseCategoryActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('expense-categories')"
                >
                  ΕΞΟΔΑ
                </a>
              </li>
            </ul>
          </div>
  
          <!-- Upper Menu Section for Data -->
          <div class="upper-menu justify-content-center d-flex second-menu" v-if="isDataActive">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  :class="['nav-link', isRoomRevenueActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('room-revenue')"
                >
                  ROOM REVENUES
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="['nav-link', isFnBExtraRevenuesActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('fnb-extras-revenue')"
                >
                  F&B EXTRAS REVENUES
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="['nav-link', isOtherRevenuesActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('other-revenues')"
                >
                  ΛΟΙΠΑ ΕΣΟΔΑ
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="['nav-link', isFnBExtrasCostsActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('fnb-extras-costs')"
                >
                  F&B EXTRAS COSTS
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="['nav-link', isPayrollCostActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('payroll-cost')"
                >
                  PAYROLL COSTS
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="['nav-link', isOtherExpensesActive ? 'active' : '']"
                  href="#"
                  @click.prevent="navigateTo('other-expenses')"
                >
                  ΛΟΙΠΑ ΕΞΟΔΑ
                </a>
              </li>
            </ul>
          </div>
  
          <LoadingSpinner :isLoading="isLoading" />
          <slot></slot>
        </div>
  
        <!-- Footer -->
        <div class="footer">
          <div class="container-xxl p-1">
            <span class="text-white" style="text-align: center; font-weight: 600; font-size: 13px;">
              <span id="footeryear"></span> © By
              <a class="text-white" href="https://citrine.gr/" target="_blank">CITRINE</a>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
   <div v-if="isModalVisible" class="custom-modal">
      <div class="custom-modal-dialog">
        <div class="custom-modal-content">
          <div class="custom-modal-header">
            <h5 class="custom-modal-title">Αλλαγή κωδικού</h5>
            <button type="button" class="close" @click="hideModal">  
                <span>&times;</span>
            </button>
          </div>
          <div class="custom-modal-body">
            <form @submit.prevent="handleChangePassword">
              <div class="mb-3">
                <label for="oldPassword" class="form-label">Παλιός κωδικός</label>
                <input type="password" id="oldPassword" v-model="oldPassword" class="form-control" required />
              </div>
              <div class="mb-3">
                <label for="newPassword" class="form-label">Νέος κωδικός</label>
                <input type="password" id="newPassword" v-model="newPassword" class="form-control" required />
              </div>
              <div class="mb-3">
                <label for="confirmPassword" class="form-label">Επιβεβαίωση νέου κωδικού</label>
                <input type="password" id="confirmPassword" v-model="confirmPassword" class="form-control" required />
              </div>
              <div class="modal-footer">
                <button type="submit text-end" @click="hideModal" class="btn btn-secondary">Άκυρο</button>
                <button type="submit text-end" class="btn btn-primary" style="background-color: #E1961F">Αποθήκευση</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import {
    fetchBudgetInfo,
    fetchUsers,
    changeUser,
    fetchUserInfo,changePassword
  } from '../services/user';
  import { useToast } from 'vue-toastification';
  import LoadingSpinner from '../components/LoadingSpinner.vue';
  
  export default {
    name: 'MainLayout',
    components: {
      LoadingSpinner,
    },
    setup() {
      const isModalVisible = ref(false);
      const isLoading = ref(false);
      const userInfo = ref(null);
      const users = ref([]);
      const selectedUser = ref(null);
      const showUsersContainer = ref(false);
      const isMenuOpen = ref(false);
      const budgetTitle = ref('FINAL BUDGET');
      const budgetYear = ref('2024');
      const hasUnsavedChanges = ref(false); // Track unsaved changes
      const router = useRouter();
      const route = useRoute();
      const toast = useToast();
      const budgetId = route.params.budget_id;
      const oldPassword = ref('');
      const newPassword = ref('');
      const confirmPassword = ref('');
      let pendingNavigation = null; // Store pending navigation path
  
      // Active tab states using computed properties
      const isDomikaActive = computed(() => route.path.includes('/domika'));
      const isDataActive = computed(() => route.path.includes('/data'));
      const isRoomTypeActive = computed(
        () => route.path.includes('/room-type') || route.path.endsWith('/room-type')
      );
      const isRoomRevenueActive = computed(
        () =>
          route.path.includes('/room-revenue') ||
          route.path.endsWith('/room-revenue')
      );
      const isRevenueCategoriesActive = computed(
        () =>
          route.path.includes('/revenue-categories') ||
          route.path.endsWith('/revenue-categories')
      );
      const isOutletsActive = computed(
        () => route.path.includes('/outlets') || route.path.endsWith('/outlets')
      );
      const isEmployeeSpecialtiesActive = computed(
        () =>
          route.path.includes('/employee-specialties') ||
          route.path.endsWith('/employee-specialties')
      );
      const isOperationalDepartmentActive = computed(
        () =>
          route.path.includes('/operational-department') ||
          route.path.endsWith('/operational-department')
      );
      const isExpenseCategoryActive = computed(
        () =>
          route.path.includes('/expense-categories') ||
          route.path.endsWith('/expense-categories')
      );
      const isOtherRevenuesActive = computed(
        () =>
          route.path.includes('/other-revenues') ||
          route.path.endsWith('/other-revenues')
      );
      const isFnBExtrasCostsActive = computed(
        () =>
          route.path.includes('/fnb-extras-costs') ||
          route.path.endsWith('/fnb-extras-costs')
      );
      const isPayrollCostActive = computed(
        () =>
          route.path.includes('/payroll-cost') ||
          route.path.endsWith('/payroll-cost')
      );
      const isOtherExpensesActive = computed(
        () =>
          route.path.includes('/other-expenses') ||
          route.path.endsWith('/other-expenses')
      );
      const isFnBExtraRevenuesActive = computed(
        () =>
          route.path.includes('/fnb-extras-revenue') ||
          route.path.endsWith('/fnb-extras-revenue')
      );

      const showModal = () => {
      isModalVisible.value = true;
    };

    const hideModal = () => {
      isModalVisible.value = false;
    };
    const handleChangePassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        toast.error('Ο νέος κωδικός και η επιβεβαίωση δεν ταιριάζουν.');
        return;
      }

      try {
        const payload = {
          old_password: oldPassword.value,
          password: newPassword.value,
          password_confirmation: confirmPassword.value,
        };

        const response = await changePassword(payload);

        if (response.success) {
          toast.success('Ο κωδικός άλλαξε με επιτυχία!');
          hideModal();
        } else {
          toast.error(response.message || 'Αποτυχία αλλαγής κωδικού.');
        }
      } catch (error) {
        console.error('Error changing password:', error);
        toast.error('Προέκυψε σφάλμα κατά την αλλαγή του κωδικού.');
      }
    };
  
      // Navigation functions
      const navigateTo = (path) => {
        if (hasUnsavedChanges.value) {
          pendingNavigation = path; // Store the path for pending navigation
          showUnsavedChangesModal(); // Show modal
        } else {
          performNavigation(path);
        }
      };
  
      const performNavigation = (path) => {
        const currentPath = route.path;
        const pathParts = currentPath.split('/');
        const hotelIdIndex = pathParts.indexOf('hotels') + 1;
        const budgetIdIndex = pathParts.indexOf('budgets') + 1;
  
        const hotelId = pathParts[hotelIdIndex];
        const budgetId = pathParts[budgetIdIndex];
  
        let lastComputedSlug = '';
        if (
          path === 'expense-categories' ||
          path === 'room-type' ||
          path === 'revenue-categories' ||
          path === 'outlets' ||
          path === 'employee-specialties' ||
          path === 'operational-department'
        ) {
          lastComputedSlug = 'domika';
        } else {
          lastComputedSlug = 'data';
        }
  
        if (hotelId && budgetId) {
          const newPath = `/hotels/${hotelId}/budgets/${budgetId}/${lastComputedSlug}/${path}`;
          router.push(newPath);
        }
      };
  
      const navigateToDomika = () => {
        navigateTo('room-type');
      };
  
      const navigateToData = () => {
        navigateTo('room-revenue');
      };
  
      // Unsaved changes detection
      const showUnsavedChangesModal = () => {
        $('#unsavedChangesModal').modal('show');
      };
  
      const confirmLeave = () => {
        hasUnsavedChanges.value = false; // Reset unsaved changes
        $('#unsavedChangesModal').modal('hide');
        if (pendingNavigation) {
          performNavigation(pendingNavigation);
          pendingNavigation = null;
        }
      };
  
      const stayOnPage = () => {
        $('#unsavedChangesModal').modal('hide');
        pendingNavigation = null;
      };
  
      // Global input handler using jQuery
      const setupUnsavedChangesDetection = () => {
        $(document).on('change, keyup', '.form-container > *', function () {
          hasUnsavedChanges.value = true;
        });
  
        $(document).on('change, keyup', '.room-item > *', function () {
          hasUnsavedChanges.value = true;
        });
  
        $(document).on('change, keyup', '.outlet-item > *', function () {
          hasUnsavedChanges.value = true;
        });
  
        $(document).on('change, keyup', '.specialty-item > *', function () {
          hasUnsavedChanges.value = true;
        });

        $(document).on('click', '.save-button, .save', function () {
          hasUnsavedChanges.value = false;
        });
      };
  
      const resetUnsavedHandler = function() {
        hasUnsavedChanges.value = false; // Reset unsaved changes
      };
  
      onMounted(() => {
        // Setup unsaved changes detection
        setupUnsavedChangesDetection();
  
        // Handle modal button clicks
        $('#confirmLeaveBtn').on('click', confirmLeave);
        $('#unsavedChangesModal .btn-secondary').on('click', stayOnPage);
  
        // Fetch user data and budget info
        fetchUserData();
        fetchBudgetInfoData();
      });
  
      onBeforeUnmount(() => {
        // Remove event listeners
        $(document).off('change keyup', '.form-container > *');
        $(document).off('change keyup', '.room-item > *');
        $(document).off('change keyup', '.outlet-item > *');
        $(document).off('change keyup', '.specialty-item > *');
        $('#confirmLeaveBtn').off('click', confirmLeave);
        $('#unsavedChangesModal .btn-secondary').off('click', stayOnPage);
      });
  
      // Fetch user data and budget info
      const fetchUserData = async () => {
        try {
          const response = await fetchUserInfo();
          userInfo.value = response.data?.info || null;
          if (
            userInfo.value?.customer_id === null &&
            userInfo.value.superUser === 1
          ) {
            showUsersContainer.value = true;
            fetchUsersData();
          }
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        }
      };
  
      const fetchUsersData = async () => {
        try {
          const response = await fetchUsers();
          if (response.data.success) {
            users.value = response.data.data;
            showUsersContainer.value = true;
          }
        } catch (error) {
          console.error('Failed to fetch users:', error);
        }
      };
  
      const changeUserHandler = async () => {
        if (selectedUser.value) {
          try {
            const response = await changeUser(selectedUser.value);
            if (response.data.success) {
              const newToken = response.data.data.token.plainTextToken;
              localStorage.setItem('api_token', newToken);
              await fetchUserData();
              toast.success(response.data.message);
              window.location.reload();
            } else {
              toast.error(response.data.message);
            }
          } catch (error) {
            toast.error('An error occurred while changing the user.');
          }
        }
      };
  
      const fetchBudgetInfoData = async () => {
        isLoading.value = true;
        try {
          const response = await fetchBudgetInfo(budgetId);
          if (response) {
            budgetTitle.value = response.data.data.title || 'FINAL BUDGET';
            budgetYear.value = response.data.data.year || '2024';
          }
        } catch (error) {
          console.error('Failed to fetch budget info:', error);
        } finally {
          isLoading.value = false;
        }
      };
  
      const logout = () => {
        localStorage.removeItem('api_token');
        router.push('login');
      };
  
      // Dropdown menu logic
      const toggleMenu = () => {
        isMenuOpen.value = !isMenuOpen.value;
      };
  
      const menuStyle = computed(() => ({
        top: isMenuOpen.value ? '100%' : 'initial',
        right: isMenuOpen.value ? '0' : 'initial',
      }));
  
      const dropdownClass = computed(() => ({
        'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0':
          !isMenuOpen.value,
        'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0 show':
          isMenuOpen.value,
      }));
  
      return {
        isLoading,
        userInfo,
        users,
        selectedUser,
        showUsersContainer,
        isMenuOpen,
        toggleMenu,
        menuStyle,
        dropdownClass,
        budgetTitle,
        budgetYear,
        hasUnsavedChanges,
        navigateTo,
        navigateToDomika,
        navigateToData,
        isDomikaActive,
        isDataActive,
        isRoomTypeActive,
        isRoomRevenueActive,
        isRevenueCategoriesActive,
        isOutletsActive,
        isEmployeeSpecialtiesActive,
        isOperationalDepartmentActive,
        isExpenseCategoryActive,
        isOtherRevenuesActive,
        isFnBExtrasCostsActive,
        isPayrollCostActive,
        isFnBExtraRevenuesActive,
        isOtherExpensesActive,
        confirmLeave,
        stayOnPage,
        changeUserHandler,
        logout,
        oldPassword,
      newPassword,
      confirmPassword,
      showModal,
      hideModal,
      handleChangePassword,
      isModalVisible
      };
    },
  };
  </script>
  
  
<style scoped>
.upper-menu {
    background-color: #e1e1e1;
    border-radius: 30px;
}

.h-right {
    box-shadow: 0 4px 8px rgba(0.6, 0.6, 0.7, 0.6);
    border-radius: 30px;
    background-color: white;
    min-height: 50px;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
    color: #939393;
    margin-right: 10px;
    font-weight: bolder;
}

.nav-tabs .nav-link {
    color: #8d8d8d;
    font-weight: bolder;
    border-radius: 4px 4px 0 0;
    padding: 10px 15px;
    font-size: 16px;
}

.nav-tabs .nav-link.active {
/*     background-color: #ffffff;
 */    height: 95%;
    border-color: #ddd #ddd #fff;
    border-radius: 30px;
    color: var(--primary-color);
}

.nav-tabs .nav-link1 {
    color: #8d8d8d;
    font-weight: bolder;
    border-radius: 4px 4px 0 0;
    padding: 10px 15px;
    font-size: 16px;
}

.nav-tabs .nav-link1.active {
    background-color: var(--primary-color);
    border-color: #ddd #ddd #fff;
    border-radius: 35px;
    color: #ffffff;
}

.nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: #ddd;
    border-radius: 30px;
}
</style>