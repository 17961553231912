import { createRouter, createWebHistory } from 'vue-router';
import Login from './views/Login.vue';
import Register from './views/Register.vue';
import Dashboard from './views/Dashboard.vue';
import ResetPassword from './views/ResetPassword.vue';
import { fetchUserInfo } from './services/user';
import ChangePassword from './views/ChangePassword.vue';
import RevenueCategories from './views/RevenueCategories.vue';
import RoomType from './views/RoomType.vue';
import RoomRevenue from './views/RoomRevenue.vue';
import Outlets from './views/Outlets.vue';
import EmployeeSpecialties from './views/EmployeeSpecialties.vue';
import OperationalDepartment from './views/OperationalDepartment.vue';
import ExpenseCategories from './views/ExpenseCategories.vue';
import OtherRevenues from './views/OtherRevenues.vue';
import FnbExtrasCosts from './views/FnbExtrasCosts.vue';
import FnbExtrasRevenue from './views/FnbExtrasRevenue.vue';
import PayrollCost from './views/PayrollCost.vue';
import OtherExpenses from './views/OtherExpenses.vue';
import Budgets from './views/Budgets.vue';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { layout: 'MainLayout' }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { layout: 'MainLayout' }
  },

  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { layout: 'LoginLayout' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { layout: 'LoginLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/room-type',
    name: 'RoomType',
    component: RoomType,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/room-revenue',
    name: 'RoomRevenue',
    component: RoomRevenue,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/revenue-categories',
    name: 'RevenueCategories',
    component: RevenueCategories,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/fnb-extras-revenue',
    name: 'FnbExtrasRevenue',
    component: FnbExtrasRevenue,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/outlets',
    name: 'Outlets',
    component: Outlets,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/employee-specialties',
    name: 'EmployeeSpecialties',
    component: EmployeeSpecialties,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/operational-department',
    name: 'OperationalDepartment',
    component: OperationalDepartment,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/expense-categories',
    name: 'ExpenseCategories',
    component: ExpenseCategories,
    meta: { layout: 'BudgetLayout' }
  },

  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/other-revenues',
    name: 'OtherRevenues',
    component: OtherRevenues,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/fnb-extras-costs',
    name: 'FnbExtrasCosts',
    component: FnbExtrasCosts,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/payroll-cost',
    name: 'PayrollCost',
    component: PayrollCost,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/:budget_id/:type/other-expenses',
    name: 'OtherExpenses',
    component: OtherExpenses,
    meta: { layout: 'BudgetLayout' }
  },
  {
    path: '/hotels/:hotel_id/budgets/',
    name: 'Budgets',
    component: Budgets,
    meta: { layout: 'BudgetListLayout' }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      layout: 'RegisterLayout' // No layout for Register component
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach( async (to, from, next) => {
    // Meta tags population
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    if (nearestWithMeta) {
      nearestWithMeta.meta.metaTags.forEach(tag => {
        const element = document.createElement('meta');
        Object.keys(tag).forEach(key => {
          element.setAttribute(key, tag[key]);
        });
        document.head.appendChild(element);
      });
    }

    // Auth guard
    const publicPages = ['Login', 'ResetPassword'];
    const authRequired = !publicPages.includes(to.name);

    if (to.path == '/register') {
      try {
        const userInfo = await fetchUserInfo();
        if (!userInfo || userInfo.data.info == null || !userInfo.data.info.superUser) { throw new Error('Invalid/expired token'); }
        next();
      } catch (error) {
        next({ name: 'Home' });
      }
    }
    
    const userInfo = await fetchUserInfo();

    if (authRequired) {
      try {
        if (!userInfo || userInfo.data.info == null) { throw new Error('Invalid/expired token'); }
        next();
      } catch (error) {
        localStorage.removeItem('api_token');
        next({ name: 'Login' });
      }
    } else {
      if (to.path == '/login' && userInfo && userInfo.data.info) {
        next({ path: '/hotels/1/budgets' }); 
      } else {
        next();
      }
    }
  });

export default router;
