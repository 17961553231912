<template>
  <div>
    <!-- Tabs for Payroll Sections -->
    <div class="tabs-container d-flex justify-content-center mt-2">
      <button 
        class="btn btn-primary me-2" 
        :class="{ active: activeTab === 'employees' }" 
        @click="activeTab = 'employees'">
        Payroll Costs Εργαζομένων
      </button>
      <button 
        class="btn btn-primary me-2" 
        :class="{ active: activeTab === 'departments' }" 
        @click="activeTab = 'departments'">
        Payroll Costs Λειτουργικών
      </button>
      <button 
        class="btn btn-primary" 
        :class="{ active: activeTab === 'related' }" 
        @click="activeTab = 'related'">
        Payroll Related Costs
      </button>
    </div>

    <!-- Specialties CRUD Section -->
    <div v-if="activeTab === 'employees'" class="form-section mt-3">
        <!-- Container for title and button -->
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <h5 style="font-weight: bolder; color: #555555">Payroll Costs Εργαζομένων</h5>
          </div>
  
          <!-- Right-aligned category selection -->
          <div class="d-flex align-items-center">
            <h5 style="font-weight: bolder; color: #555555; margin-right: 10px;">Επιλέξτε Κατηγορία</h5>
            <div class="dropdown">
              <select v-model="selectedCategory" class="form-select">
                <!--<option value="">Όλες</option> -->
                <option v-for="(title, id) in categoryTitles" :key="id" :value="id">
                  {{ title }}
                </option>
              </select>
            </div>
          </div>

          <div>
          <h5 style="font-weight: bolder; color: #555555">
            ΣΥΝΟΛΟ: {{ formattedBudgetTotal }}
            <span v-if="budgetTotalEmployees"> €</span>
          </h5>
        </div>
        </div>
  
        <!-- Specialties List (Grouped by Category) -->
        <div v-if="filteredSpecialties.length > 0" class=" mt-4">
          <div v-for="(specialtiesByCategory, categoryId) in groupedSpecialties" :key="categoryId">
            <div class="row">
              <div v-for="(specialty, index) in specialtiesByCategory" :key="specialty.id" class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative">
                <!-- Non-Editable View -->
                <div v-if="!editStates[specialty.id]">
                  <div class="row" style="color:#A6A6A6; font-weight:bolder">
                    <div class="col-md-12">
                      <p class="label-with-circle">
                        <strong>{{ specialty.title }}</strong>
                        <hr />
                      </p>
                      <div class="col-md-12">
      <p>
        <strong>Αριθμός εργαζομένων: {{ specialty.number_of_employees }}</strong>
      </p>
    </div>
    <div class="col-md-12">
      <p>
        <strong>Ημέρες εργασίας: {{ specialty.working_days }}</strong>
      </p>
    </div>
    <div class="col-md-12">
        <p><strong>Ώρες Εργασίας:</strong> {{ specialty.working_hours }}</p>
    </div>
    <div class="col-md-12">
      <p>
        <strong>Συνολικοί Μήνες Εργασίας: {{ formatNumber(specialty.total_working_months) }}</strong>
      </p>
    </div>
    <div class="col-md-12">
      <p>
        <strong>Μήνες εργασίας (Operation): {{ formatNumber(specialty.working_months_operation) }}</strong>
      </p>
    </div>
    <div class="col-md-12">
        <p><strong>Μήνες εργασίας (Off Season):</strong> {{ formatNumber(specialty.working_months_off_season) }}</p>
    </div>

    <div class="col-md-12">
      <p>
        <strong>Δώρα/Επιδόματα: {{ formatNumber(specialty.benefits) }}</strong>
      </p>
    </div>
    <div class="col-md-12">
      <p>
        <strong>Μικτός Μηνιαίος Μισθός: {{ formatNumber(specialty.monthly_gross_salary) }}€</strong>
      </p>
    </div>
    <div class="col-md-12">
        <p><strong>Καθαρός Μηνιαίος Μισθός:</strong> {{ formatNumber(specialty.monthly_net_salary) }}€</p>
    </div>

    <div class="col-md-12">
      <p>
        <strong>Μηνιαίο Μισθοδοτικό Κόστος: {{ formatNumber(specialty.monthly_payment_salary) }}€</strong>
      </p>
    </div>
    <div class="col-md-12 mt-3">
  <div class="card shadow-sm bg-light rounded">
    <h6 class="text-primary" style="font-weight: bold; text-decoration: underline;">Συνολικό Μισθοδοτικό Κόστος</h6>
    <div class="row">
      <div class="col-md-6">
        <p>
          <strong>Καθαρά:</strong> {{ formatNumber(specialty.total_payment_salary_net) }}€
        </p>
        <p>
          <strong>Operation:</strong> {{ formatNumber(specialty.total_payment_salary_operation) }}€
        </p>
      </div>
      <div class="col-md-6">
        <p>
          <strong>Off Season:</strong> {{ formatNumber(specialty.total_payment_salary_off_season) }}€
        </p>
        <p>
          <strong>Έτους:</strong> {{ formatNumber(specialty.total_payment_salary) }}€
        </p>
      </div>
    </div>
  </div>
</div>

                    </div>
  
                    <div class="action-buttons">
                      <button class="btn border-0 p-1 edit" @click="editSpecialty(specialty)">
                        <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
                      </button>
                    </div>
                  </div>
                </div>
  
                <!-- Editable View (Inline) -->
                <div v-else>
                  <div class="row">
                    <div class="col-md-12">
                      <p class="label-with-circle2">
                        <strong>{{ specialty.title }}</strong>
                        <hr />
                      </p></div>
                    <div class="col-md-8 form-group">
                      <p>
                        <strong>Αριθμός εργαζομένων</strong>
                        <hr />
                      </p>
                      <input type="number" v-model="specialty.number_of_employees" class="form-control"  required />
                    </div><br>
  
                    <div class="col-md-8 form-group mt-2">
                      <p>
                        <strong>Ημέρες εργασίας</strong>
                        <hr />
                      </p>
                      <input type="number" v-model="specialty.working_days" class="form-control"  required />
                    </div><br>

                    <div class="col-md-8 form-group mt-2">
                      <p>
                        <strong>Ώρες εργασίας</strong>
                        <hr />
                      </p>
                      <input type="number" v-model="specialty.working_hours" class="form-control"  required />
                    </div><br>

                    <div class="col-md-8 form-group mt-2">
                      <p>
                        <strong>Συνολικοί Μήνες Εργασίας</strong>
                        <hr />
                      </p>
                      <input type="text" v-model="specialty.total_working_months" class="form-control"  required />
                    </div><br>

                    <div class="col-md-8 form-group mt-2">
                      <p>
                        <strong>Μικτός Μηνιαίος Μισθός</strong>
                        <hr />
                      </p>
                      <input type="text" v-model="specialty.monthly_gross_salary" class="form-control"  required />
                    </div><br>
                  </div>
  
                  <div class="row form-actions mt-4">
                    <div class="d-flex justify-content-end">
                      <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditSpecialty(specialty)">
                        ΑΚΥΡΩΣΗ
                      </button>
                    </div>
                  </div>
  
                  <!-- Form Actions -->
                  <div class="action-buttons">
                    <button class="btn border-0 p-1 save" @click="openSpecialtySaveModal($event, specialty)">
                      <img src="../assets/images/save.png" alt="Save" width="30">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       <!-- Payroll Related Costs -->
       <div v-if="activeTab === 'related'" class="form-section mt-4">
        <!-- Container for title and button -->
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <h5 style="font-weight: bolder; color: #555555">Payroll Related Costs</h5>
          </div>
  

          <div>
          <h5 style="font-weight: bolder; color: #555555">
            ΣΥΝΟΛΟ: {{ formattedBudgetRelatedCosts }}
            <span v-if="budgetTotalRelated"> €</span>
          </h5>
        </div>
        </div>
  

        <!-- Specialties List (Grouped by Category) -->
        <div class="row">
  <div class="col-md-10 card shadow-custom p-3 mb-3 bg-white rounded position-relative">
    <!-- Non-Editable View -->
    <div v-if="!isEditingRelatedCosts">
      <div class="row" style="color:#A6A6A6; font-weight:bolder">
        <!-- Total Number of Employees -->
        <div class="col-md-12 mb-4">
          <p>
            <strong>Συνολικός Αριθμός Εργαζομένων: {{ payrollRelatedCosts.total_number_of_employees }}</strong>
          </p>
        </div>

        <!-- Accommodation and Clothing Costs -->
        <div class="col-md-6">
          <!-- Accommodation Section -->
          <div class="row">
            <div class="col-4">
              <p><strong>Κόστος Διαμονής:</strong></p>
            </div>
            <div class="col-8">
              <p><strong>Ανάγκες Στέγασης - KPIs: {{ payrollRelatedCosts.cost_of_accomondation_food_needs }}</strong></p>
              <p><strong>Μέσο Κόστος Διαμονής - KPIs: {{ payrollRelatedCosts.cost_of_accomondation_average }}</strong></p>
              <p><strong>Συνολικό Κόστος Διαμονής: {{ payrollRelatedCosts.cost_of_accomondation_total }}</strong></p>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <!-- Clothing Section -->
          <div class="row">
            <div class="col-4">
              <p><strong>Κόστος Ένδυσης:</strong></p>
            </div>
            <div class="col-8">
              <p><strong>Ανάγκες Ένδυσης - KPIs: {{ payrollRelatedCosts.cost_of_clothing_needs }}</strong></p>
              <p><strong>Μέσο Κόστος Ένδυσης - KPIs: {{ payrollRelatedCosts.cost_of_clothing_average }}</strong></p>
              <p><strong>Συνολικό Κόστος Ένδυσης: {{ payrollRelatedCosts.cost_of_clothing_total }}</strong></p>
            </div>
          </div>
        </div>

      
      <!-- Edit Button -->
      <div class="action-buttons text-end mt-3">
        <button class="btn border-0 p-1 edit" @click="editRelatedCosts()">
          <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
        </button>
      </div>
    </div>
  </div>

                <!-- Editable View (Inline) -->
                <div v-else>
  <div class="row" style="color:#A6A6A6; font-weight:bolder">
    <!-- Total Number of Employees -->
    <div class="col-md-12 mb-4">
      <p>
        <strong>Συνολικός Αριθμός Εργαζομένων: {{ payrollRelatedCosts.total_number_of_employees }}</strong>
      </p>
    </div>

    <!-- Accommodation and Clothing Costs -->
    <div class="col-md-6">
      <!-- Accommodation Section -->
      <div class="row mb-3">
        <div class="col-4">
          <p><strong >Κόστος Διαμονής:</strong></p>
        </div>
        <div class="col-8">
          <div class="d-flex align-items-center mb-2">
            <strong class="me-2"  style="width:80%">Ανάγκες Στέγασης - KPIs:</strong>
            <input
              type="text"
              v-model="payrollRelatedCosts.cost_of_accomondation_food_needs"
              class="form-control"
              required
            />
          </div>
          <div class="d-flex align-items-center mb-2">
            <strong class="me-2"  style="width:80%">Μέσο Κόστος Διαμονής - KPIs:</strong>
            <input
              type="text"
              v-model="payrollRelatedCosts.cost_of_accomondation_average"
              class="form-control"
              required
            />
          </div>
          <div class="d-flex align-items-center">
            <strong class="me-2">Συνολικό Κόστος Διαμονής:</strong>
            <span>{{ payrollRelatedCosts.cost_of_accomondation_total }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <!-- Clothing Section -->
      <div class="row mb-3">
        <div class="col-4">
          <p><strong>Κόστος Ένδυσης:</strong></p>
        </div>
        <div class="col-8">
          <div class="d-flex align-items-center mb-2">
            <strong class="me-2"  style="width:80%">Ανάγκες Ένδυσης - KPIs:</strong>
            <input
              type="text"
              v-model="payrollRelatedCosts.cost_of_clothing_needs"
              class="form-control"
              required
            />
          </div>
          <div class="d-flex align-items-center mb-2">
            <strong class="me-2" style="width:80%">Μέσο Κόστος Ένδυσης - KPIs:</strong>
            <input
              type="text"
              v-model="payrollRelatedCosts.cost_of_clothing_average"
              class="form-control"
              required
            />
          </div>
          <div class="d-flex align-items-center">
            <strong class="me-2">Συνολικό Κόστος Ένδυσης:</strong>
            <span>{{ payrollRelatedCosts.cost_of_clothing_total }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
                  <div class="row form-actions mt-4">
                    <div class="d-flex justify-content-end">
                      <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditRelatedCosts()">
                        ΑΚΥΡΩΣΗ
                      </button>
                    </div>
                  </div>
  
                  <!-- Form Actions -->
                  <div class="action-buttons">
                    <button class="btn border-0 p-1 save" @click="openRelatedCostsSaveModal($event)">
                      <img src="../assets/images/save.png" alt="Save" width="30">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <!-- Save Confirmation Modal for Specialties -->
      <div class="modal fade" id="specialtySaveModal" tabindex="-1" aria-labelledby="specialtySaveModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="specialtySaveModalLabel">Επιβεβαίωση Αποθήκευσης</h5>
              <button type="button" class="close" @click="closeSpecialtySaveModal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">Είστε σίγουροι ότι θέλετε να αποθηκεύσετε τα δεδομένα;</div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeSpecialtySaveModal">Ακύρωση</button>
              <button type="button" class="btn btn-primary" @click="confirmSaveSpecialty">Αποθήκευση</button>
            </div>
          </div>
        </div>
      </div>
  
      <div class="modal fade" id="relatedSaveModal" tabindex="-1" aria-labelledby="relatedSaveModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="specialtySaveModalLabel">Επιβεβαίωση Αποθήκευσης</h5>
              <button type="button" class="close" @click="closeRelatedSaveModal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">Είστε σίγουροι ότι θέλετε να αποθηκεύσετε τα δεδομένα;</div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeRelatedSaveModal">Ακύρωση</button>
              <button type="button" class="btn btn-primary" @click="confirmSaveRelatedCosts">Αποθήκευση</button>
            </div>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  import { useHead } from '@vueuse/head';
  import { useToast } from 'vue-toastification';
  import { fetchBudgetInfo, fetchUsers, changeUser, fetchUserInfo } from '../services/user';

  import {
    getCategories,
    getSpecialties,
    updateSpecialty,
  } from '@/services/payroll_cost_specialties';

  import {
    getBudget,
    updatePayrollRelatedCosts,
  } from '@/services/budget';
  
  export default {
    name: 'EmployeeSpecialties',
    data() {
      return {
        activeTab: 'employees', // Default tab
        showCategoryForm: false,
        showForm: false,
        isEditingSpecialty: false,
        isEditingRelatedCosts: false,
        showSpecialtySaveModal: false, // Modal for saving specialties
        showDeleteSpecialtyModal: false, // Modal for deleting specialties
        showCategorySaveModal: false, // Modal for saving categories
        showDeleteCategoryModal: false, // Modal for deleting categories
        categoryForm: {
          title: '',
        },
        form: {
          title: '',
          employee_category_id: null,
        },
        categories: [],
        specialties: [],
        payrollRelatedCosts: {
      cost_of_accomondation_food_needs: '',
      cost_of_accomondation_average: '',
      cost_of_clothing_needs: '',
      cost_of_clothing_average: '',
      
    },        selectedCategory: '', // Initialize as an empty string
        categoryTitles: {},
        editStates: {}, // Store the editing state separately
        currentEditSpecialty: null,
        currentDeleteSpecialty: null,
        currentEditCategory: null,
        currentDeleteCategory: null,
        budgetTotalEmployees: 0,
        budgetTotalRelated:0

      };
    },
    computed: {
      // Filter specialties based on selected category
      filteredSpecialties() {
        if (!this.selectedCategory) return this.specialties;
        return this.specialties.filter(specialty => specialty.employee_category_id == this.selectedCategory);
      },

      formattedBudgetTotal() {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(this.budgetTotalEmployees);
    },
    formattedBudgetRelatedCosts() {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(this.budgetTotalRelated);
    },
  
      // Grouped specialties based on category
      groupedSpecialties() {
        return this.filteredSpecialties.reduce((acc, specialty) => {
          const categoryId = specialty.employee_category_id;
          if (!acc[categoryId]) {
            acc[categoryId] = [];
          }
          acc[categoryId].push(specialty);
          return acc;
        }, {});
      }
    },
    methods: {
      // Category methods
      async fetchCategories() {
  try {
    const response = await getCategories(this.$route.params.budget_id);
    this.categories = response.data;

    // Build categoryTitles map
    this.categoryTitles = this.categories.reduce((acc, category) => {
      acc[category.id] = category.title;
      return acc;
    }, {});

    // Set the default selected category to the first category
    if (this.categories.length > 0) {
      this.selectedCategory = this.categories[0].id; // Set to the first category's ID
    }
  } catch (error) {
    console.error('Error fetching categories:', error);
  }
},

async fetchRelatedCosts() {
  try {
    const response = await getBudget(this.$route.params.budget_id);
    this.payrollRelatedCosts = response.data.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
  }
},

      formatNumber(value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
      showAddCategoryForm() {
        this.resetCategoryForm();
        this.showCategoryForm = true;
        this.isEditingCategory = false;
      },
      resetCategoryForm() {
        this.categoryForm = { title: '' };
        this.currentEditCategory = null;
      },
      openCategorySaveModal() {
        $('#categorySaveModal').modal('show');
      },
      closeCategorySaveModal() {
        $('#categorySaveModal').modal('hide');
      },

      async fetchBudgetInfoData() {
      try {
        const response = await fetchBudgetInfo(this.$route.params.budget_id);
        if (response) {
          this.budgetTotalEmployees = response.data.data.total_payroll_cost_employees || 0;
          this.budgetTotalRelated = response.data.data.total_payroll_cost_others || 0;

        }
      } catch (error) {
        console.error('Failed to fetch budget info:', error);
      }
    },
      async confirmSaveCategory() {
        try {
          const form = { title: this.categoryForm.title };
          if (this.isEditingCategory && this.currentEditCategory) {
            const response = await updateCategory(this.currentEditCategory.id, form);
            if (response.success) {
              useToast().success(response.message);
            } else {
              useToast().error(response.message);
            }
            this.currentEditCategory.title = form.title;
            this.editStates[this.currentEditCategory.id] = false;
          } else {
            const response = await createCategory(this.$route.params.budget_id, form);
            if (response.success) {
              useToast().success(response.message);
            } else {
              useToast().error(response.message);
            }
            this.categories.push(response.data);
          }
          this.fetchCategories();
          this.fetchRelatedCosts();
          this.resetCategoryForm();
          this.closeCategorySaveModal();
          this.fetchBudgetInfoData();
          this.showCategoryForm = false;
        } catch (error) {
          console.error('Error saving category:', error);
          this.closeCategorySaveModal();
        }
      },
      async confirmSaveRelatedCosts() {
  try {
    // Construct the form object
    const form = {
      cost_of_accomondation_food_needs: this.payrollRelatedCosts.cost_of_accomondation_food_needs,
      cost_of_accomondation_average: this.payrollRelatedCosts.cost_of_accomondation_average,
      cost_of_clothing_needs: this.payrollRelatedCosts.cost_of_clothing_needs,
      cost_of_clothing_average: this.payrollRelatedCosts.cost_of_clothing_average,
     
    };

    // Make the API call to save data
    const response = await updatePayrollRelatedCosts(this.$route.params.budget_id, form);
    if (response.data.success) {
      useToast().success(response.data.message);
    } else {
      useToast().error(response.data.message);
    }

    // Reset the editing state and fetch data
    this.isEditingRelatedCosts = false; // Close the edit mode after saving
    this.fetchSpecialties();
    this.closeRelatedSaveModal();
    this.resetForm();
    this.fetchBudgetInfoData();
    this.showForm = false; // Close the form after saving
  } catch (error) {
    console.error("Error saving related costs:", error);
    useToast().error("An error occurred while saving the related costs.");
    this.closeRelatedSaveModal();
  }
},

      editCategory(category) {
        this.currentEditCategory = category;
        this.categoryForm.title = category.title;
        this.editStates = { ...this.editStates, [category.id]: true };
        this.isEditingCategory = true;
      },
      cancelEditCategory(category) {
        this.editStates[category.id] = false;
        this.resetCategoryForm();
      },
     
      // Specialty methods
      async fetchSpecialties() {
        try {
          const response = await getSpecialties(this.$route.params.budget_id);
          this.specialties = response.data;
        } catch (error) {
          console.error('Error fetching specialties:', error);
        }
      },
      showAddSpecialtyForm() {
        this.resetForm();
        this.showForm = true;
        this.currentEditSpecialty = null; // Reset any previous edits
      },
      resetForm() {
        this.form = {
          title: '',
          employee_category_id: null,
        };
        this.currentEditSpecialty = null; // Reset the edit state
      },
      openSpecialtySaveModal(ev, specialty = null) {
        if (specialty) {
          this.currentEditSpecialty = specialty;
        } else {
          this.currentEditSpecialty = null; // Ensure this is null when creating a new specialty
        }
        $('#specialtySaveModal').modal('show');
      },
      openRelatedCostsSaveModal(ev) {
        $('#relatedSaveModal').modal('show');
      },
      closeSpecialtySaveModal() {
        $('#specialtySaveModal').modal('hide');
      },
      closeRelatedSaveModal(){
        $('#relatedSaveModal').modal('hide');
      },
      async confirmSaveSpecialty() {
        try {
          if (!this.currentEditSpecialty) {
            // Create a new specialty
            const response = await createSpecialty(this.$route.params.budget_id, this.form);
            if (response.success) {
              useToast().success(response.message);
            } else {
              useToast().error(response.message);
            }
            this.specialties.push(response.data); // Add the new specialty to the list
          } else {
            // Update existing specialty
            const response = await updateSpecialty(this.currentEditSpecialty.id, this.currentEditSpecialty);
            if (response.success) {
              useToast().success(response.message);
            } else {
              useToast().error(response.message);
            }
            this.editStates[this.currentEditSpecialty.id] = false; // Close the edit mode after saving
          }
          this.fetchSpecialties();
          this.closeSpecialtySaveModal();
          this.resetForm();
          this.fetchBudgetInfoData();
          this.showForm = false; // Close the form after saving
        } catch (error) {
          console.error('Error saving specialty:', error);
          this.closeSpecialtySaveModal();
        }
      },
      editSpecialty(specialty) {
        this.editStates = { ...this.editStates, [specialty.id]: true };
        this.currentEditSpecialty = specialty;
      },
      editRelatedCosts(){
this.isEditingRelatedCosts = true;
      },
      cancelEditSpecialty(specialty) {
        this.editStates[specialty.id] = false;
        this.currentEditSpecialty = null; // Reset the edit state
      },
      cancelEditRelatedCosts(){
        this.isEditingRelatedCosts=false;
      }
    },
    mounted() {
      useHead({
        title: 'Payroll Costs',
      });
      this.fetchCategories();
      this.fetchRelatedCosts();
      this.fetchSpecialties();
      this.fetchBudgetInfoData();
    },
  };
  </script>
  
  <style scoped>
    .form-container {
      background: #f8f9fa;
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #ddd;
    }
  
    .card {
      padding: 15px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #ddd;
      position: relative;
      margin-left: 35px;
    }
  
    .action-buttons {
      position: absolute;
      top: 100px;
      right: 10px;
    }
  
    .label-with-circle::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #a6a6a6;
      border-radius: 50%;
      margin-right: 10px;
      vertical-align: middle;
    }
  
    .label-with-circle2::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: var(--primary-color);
      border-radius: 50%;
      margin-right: 10px;
      vertical-align: middle;
    }
  
    .align-middle {
      margin-top: 30px;
    }
  
    .shadow-custom {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease-in-out;
    }
  
    .shadow-custom:hover {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
    }
  
    .edit {
      position: absolute;
      top: 25%; /* Adjust the vertical position to be slightly above middle */
      right: -20px; /* Half the button outside the outlet-card */
      transform: translateY(-200%); /* Center vertically relative to its position */
    }
  
    .save {
      position: absolute;
      top: 10%; /* Adjust as needed to lower the button */
      right: -18px; /* Adjust to move outside the card */
      transform: translateY(-195%); /* Further adjust for desired position */
    }
  
    .delete {
      position: absolute;
      top: 50%; /* Adjust to position higher or lower */
      right: -18px; /* Same as above */
      transform: translateY(30%); /* Further adjust for desired position */
    }
    .tabs-container .btn {
  font-weight: bold;
  border-radius: 5px;
  padding: 10px 20px;
}

.tabs-container .btn.active {
  background-color: #0056b3;
  color: white;
}
  </style>
  