<template>
    <div class="form-section mt-4 ms-4">
      <!-- Container for title and button -->
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h5 style="font-weight: bolder; color: #555555">F&B Extras Costs</h5>
        </div>
        <div>
          <h5 style="font-weight: bolder; color: #555555">
            ΣΥΝΟΛΟ: {{ formattedBudgetTotal }}
            <span v-if="budgetTotal"> €</span>
          </h5>
        </div>
      </div>
  
      <!-- Display list of expense categories -->
      <div class="mt-4 row">
        <div
          v-for="(expense, index) in items"
          :key="expense.id"
          class="col-md-11 card shadow-custom mb-3 bg-white rounded position-relative"
        >
          <!-- Non-Editable View -->
          <div v-if="!expense.isEditing">
            <div class="row align-items-center" style="color:#A6A6A6; font-weight:bolder">
    <div class="col-md-8">
      <p class="label-with-circle">
        <strong>{{ expense.restaurant_kind }}</strong>
      </p>
    </div>
    <div class="col-md-4 text-end">
      <p>
        <strong>Σύνολο Kόστους:</strong> {{ formatNumber(expense.total_fnb_cost) }} €
      </p>
    </div><hr/><br>


    <div class="col-md-4">
      <p>
        <strong>Παραδοχή_Κόστος φαγητού πρωϊνού: {{ expense.assumption_cost_of_breakfast }}€</strong>
      </p>
    </div>
    <div class="col-md-4">
      <p>
        <strong>Παραδοχή_Κόστος φαγητού μεσημεριανού: {{ expense.assumption_cost_of_lunch }}€</strong>
      </p>
    </div>
    <div class="col-md-4">
        <p><strong>Παραδοχή_Κόστος φαγητού βραδυνού:</strong> {{ expense.assumption_cost_of_dinner }}€</p>
    </div>

    <div class="col-md-4">
      <p>
        <strong>Παραδοχή_Κόστος ποτών AI: {{ expense.assumption_cost_of_drinks_ai }}€</strong>
      </p>
    </div>
    <div class="col-md-4">
      <p>
        <strong>Παραδοχή_Κόστος τροφής προσωπικού: {{ expense.assumption_cost_of_staff_food }}€</strong>
      </p>
    </div>
    <div class="col-md-4">
        <p><strong>Παραδοχή_Κόστος προσφορών:</strong> {{ expense.assumption_cost_of_offers }}€</p>
    </div>

  <div class="col-md-12 align-items-center justify-content-center mt-4">
      <p>
        <strong>Παραδοχές κουβερ ανά μηνα λειτουργίας</strong>
      </p>
    </div>

   <!-- Table Section -->
   <div class="table-responsive mb-4">
    <table class="table table-bordered text-center">
      <thead class="table-dark">
        <tr>
          <th></th>
          <th>ΚΑΤΑΝΟΜΗ</th>
          <th v-for="expenseMonth in revenue.expenses" :key="expenseMonth.id">
            {{ getMonthName(expenseMonth.date_from) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>ΠΡΩΪΝΑ</strong></td>
          <td>{{ revenue.assumption_breakfast_kouver }}%</td>

          <td v-for="expenseMonth in revenue.expenses" :key="'bb-' + expenseMonth.id">
            {{ expenseMonth.allocation_breakfast_kouver }}
          </td>
        </tr>
        <tr>
          <td><strong>ΜΕΣΗΜΕΡΙΑΝΑ</strong></td>
          <td>{{ revenue.assumption_lunch_kouver }}%</td>

          <td v-for="expenseMonth in revenue.expenses" :key="'hb-' + expenseMonth.id">
            {{ expenseMonth.allocation_lunch_kouver }}
          </td>
        </tr>
        <tr>
          <td><strong>ΒΡΑΔΥΝΑ</strong></td>
          <td>{{ revenue.assumption_dinner_kouver }}%</td>

          <td v-for="expenseMonth in revenue.expenses" :key="'fb-' + expenseMonth.id">
            {{ expenseMonth.allocation_dinner_kouver }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-4">
      <p>
        <strong>Παραδοχή Ποσοστιαίου Κόστους Extra F&B: {{ expense.assumption_of_percentage_cost_extra_fnb }}%</strong>
      </p>
    </div>
    <div class="col-md-4">
      <p>
        <strong>Τελικό κόστος Extra F&B:</strong> {{ formatNumber(expense.final_cost_extra_fnb) }}€
      </p>
    </div>
    <div class="col-md-4">
      <strong>Κόστος Συμφωνίας Tρόφιμα:</strong> {{ formatNumber(expense.deal_cost_food) }}€
    </div>

    <div class="col-md-4">
      <p>
        <strong>Κόστος Συμφωνίας Ποτά:</strong> {{ formatNumber(expense.deal_cost_drinks) }}€
      </p>
    </div>
    <div class="col-md-4">
      <p>
        <strong>Κόστος Extra F&B:</strong> {{ formatNumber(expense.cost_extra_fnb) }}€
      </p>
    </div>
    <div class="col-md-4">
      <strong>Κόστος τροφής προσωπικού:</strong> {{ formatNumber(expense.cost_food_staff) }}€
    </div>
    <div class="col-md-4">
      <p>
        <strong>Κόστος προσφορών:</strong> {{ formatNumber(expense.cost_offers) }}€
      </p>
    </div>

</div>

            <!-- Edit Button -->
            <div class="action-buttons">
              <button class="btn border-0 p-1 edit" @click="editItem(revenue)">
                <img src="../assets/images/edit-budget.png" alt="Edit" width="30" />
              </button>
            </div>
          </div>
  
         <!-- Editable View -->
<div v-else>
  <div class="row align-items-center" style="color:#A6A6A6; font-weight:bolder">
    <div class="col-md-8">
      <p class="label-with-circle2">
        <strong style="color:black">{{ expense.restaurant_kind }}</strong>
      </p>
    </div>
    <div class="col-md-4 text-end">
      <p>
        <strong>Σύνολο Kόστους:</strong> {{ formatNumber(expense.total_fnb_cost) }} €
      </p>
    </div>

     
    </div><hr/><br>

  <!-- F&B Assumptions Section -->
  <div class="row align-items-center">
  <div class="col-md-4">
    <div class="d-flex align-items-center">
      <p class="mb-0 flex-70"><strong>Παραδοχή_Κόστος φαγητού πρωϊνού:</strong></p>
      <input
        type="number"
        v-model="revenue.assumption_cost_of_breakfast"
        class="form-control flex-30"
        placeholder="Breakfast Cost"
      />
    </div>
  </div>
  <div class="col-md-4">
    <div class="d-flex align-items-center">
      <p class="mb-0 flex-70"><strong>Παραδοχή_Κόστος φαγητού μεσημεριανού:</strong></p>
      <input
        type="number"
        v-model="revenue.assumption_cost_of_lunch"
        class="form-control flex-30"
        placeholder="Lunch Cost"
      />
    </div>
  </div>
  <div class="col-md-4">
    <div class="d-flex align-items-center">
      <p class="mb-0 flex-70"><strong>Παραδοχή_Κόστος φαγητού βραδυνού:</strong></p>
      <input
        type="number"
        v-model="revenue.assumption_cost_of_dinner"
        class="form-control flex-30"
        placeholder="Dinner Cost"
      />
    </div>
  </div>
</div>

<div class="row align-items-center mt-3">
  <div class="col-md-4">
    <div class="d-flex align-items-center">
      <p class="mb-0 flex-70"><strong>Παραδοχή_Κόστος ποτών AI:</strong></p>
      <input
        type="number"
        v-model="revenue.assumption_cost_of_drinks_ai"
        class="form-control flex-30"
        placeholder="AI Drinks Cost"
      />
    </div>
  </div>
  <div class="col-md-4">
    <div class="d-flex align-items-center">
      <p class="mb-0 flex-70"><strong>Παραδοχή_Κόστος τροφής προσωπικού:</strong></p>
      <input
        type="number"
        v-model="revenue.assumption_cost_of_staff_food"
        class="form-control flex-30"
        placeholder="Staff Food Cost"
      />
    </div>
  </div>
  <div class="col-md-4">
    <div class="d-flex align-items-center">
      <p class="mb-0 flex-70"><strong>Παραδοχή_Κόστος προσφορών:</strong></p>
      <input
        type="number"
        v-model="revenue.assumption_cost_of_offers"
        class="form-control flex-30"
        placeholder="Offers Cost"
      />
    </div>
  </div>
</div>

  <!-- Monthly Supplement Pick Ups -->
  <div class="col-md-12 align-items-center justify-content-center mt-4">
    <p><strong>Παραδοχές κουβερ ανά μηνα λειτουργίας</strong></p>
  </div>
  <div class="table-responsive">
    <table class="table table-bordered text-center">
  <thead class="table-dark">
    <tr>
      <th></th>
      <th style="width:15%;">ΚΑΤΑΝΟΜΗ (%)</th>
      <th v-for="expenseMonth in revenue.expenses" :key="expenseMonth.id">
        {{ getMonthName(expenseMonth.date_from) }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="type in ['ΠΡΩΪΝΑ', 'ΜΕΣΗΜΕΡΙΑΝΑ', 'ΒΡΑΔΥΝΑ']" :key="type">
      <!-- Row Header (Type) -->
      <td><strong>{{ type }}</strong></td>

      <!-- Allocation Input Field -->
      <td>
        <input
          type="number"
          v-model="revenue[mapTypeToKey(type)]"
          class="form-control text-center"
          placeholder="Enter Allocation"
        />
      </td>

      <!-- Monthly data (Non-editable) columns -->
      <td v-for="expenseMonth in revenue.expenses" :key="'month-' + expenseMonth.id">
        <span v-if="type === 'ΠΡΩΪΝΑ'">{{ expenseMonth.allocation_breakfast_kouver }}</span>
        <span v-if="type === 'ΜΕΣΗΜΕΡΙΑΝΑ'">{{ expenseMonth.allocation_lunch_kouver }}</span>
        <span v-if="type === 'ΒΡΑΔΥΝΑ'">{{ expenseMonth.allocation_dinner_kouver }}</span>
      </td>
    </tr>
  </tbody>
</table>


  </div>

  <!-- Final Costs -->
  <div class="row align-items-center mt-3">
    <div class="col-md-4 mb-3">
  <div class="d-flex align-items-center justify-content-between">
    <p class="mb-0"><strong>Παραδοχή Ποσοστιαίου Κόστους Extra F&B:</strong></p>
    <input
      type="number"
      v-model="revenue.assumption_of_percentage_cost_extra_fnb"
      class="form-control ms-2"
      style="max-width: 70%;"
      placeholder="Final F&B Cost"
    />
  </div>
</div>

  </div>

  <div class="row form-actions mt-4">
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary justify-content-end align-items-end"
                  @click="cancelEdit(revenue)">
                  ΑΚΥΡΩΣΗ
          </button>
        </div>
      </div>
       <!-- Form Actions -->
      <div class="action-buttons">
        <button class="btn border-0 p-1 save" @click="confirmSaveItem(index)">
          <img src="../assets/images/save.png" alt="Save" width="30" />
        </button>
      </div>
</div>
</div>
  </div>
</div>
  </template>
  
  <script>
  import { useHead } from '@vueuse/head';
  import { getFnbExtrasCosts, updateFnbExtrasCosts } from '@/services/FnbExtrasCosts';
  import { useToast } from 'vue-toastification';
  import { fetchBudgetInfo, fetchUsers, changeUser, fetchUserInfo } from '../services/user';
  
  export default {
  name: 'FnbExtrasCosts',
  data() {
    return {
      showForm: false,
      form: {
        title: '',
        description: '',
      },
      revenue: {
        date_from: null,
        date_to: null,
        expenses: [],
      },
      items: [],
      currentEditRevenue: null,
      budgetTotal: 0,
      months: [],
    };
  },

  computed: {
    // Format the total revenue with European formatting
    formattedBudgetTotal() {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(this.budgetTotal);
    },
  },

  watch: {
    'revenue.date_from'(newStartDate) {
      this.updateMonths();
    },
    'revenue.date_to'(newEndDate) {
      this.updateMonths();
    },
  },

  methods: {
    // Format number with European decimal places
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },

    mapTypeToKey(type) {
    switch (type) {
      case 'ΠΡΩΪΝΑ':
        return 'assumption_breakfast_kouver';
      case 'ΜΕΣΗΜΕΡΙΑΝΑ':
        return 'assumption_lunch_kouver';
      case 'ΒΡΑΔΥΝΑ':
        return 'assumption_dinner_kouver';
      default:
        return '';
    }
  },

    // Generate month names based on date_from and date_to
    generatedMonths(revenue) {
      return this.calculateMonthNames(revenue.date_from, revenue.date_to);
    },

    // Update months list when date range changes
    updateMonths() {
      this.months = this.calculateMonthNames(this.revenue.date_from, this.revenue.date_to);
      console.log('Updated months:', this.months);  // For debugging
    },

    // Calculate the month names between two dates
    calculateMonthNames(startDate, endDate) {
      const months = [];
      if (startDate && endDate) {
        let start = new Date(startDate);
        const end = new Date(endDate);

        // Loop through each month and push its name to the array
        while (start <= end) {
          months.push(new Intl.DateTimeFormat("el-GR", { month: "long" }).format(start));
          start.setMonth(start.getMonth() + 1);
        }

        // Ensure the last month is included even if not in the initial range
        const lastMonth = new Date(endDate);
        if (months[months.length - 1] !== new Intl.DateTimeFormat("el-GR", { month: "long" }).format(lastMonth)) {
          months.push(new Intl.DateTimeFormat("el-GR", { month: "long" }).format(lastMonth));
        }

        console.log("Months:", months);
      }
      return months;
    },

    // Ensure data is initialized for all months, even the last one (e.g., July)
    initializeMonthData() {
      this.months.forEach((month) => {
        if (!this.revenue.expenses[month]) {
          this.revenue.expenses[month] = {
            extra_revenue_per_guest_ai: null,
            extra_revenue_per_guest_bb: null,
            extra_revenue_per_guest_fb: null,
            extra_revenue_per_guest_hb: null,
            monthly_supplement_pick_up_ai: null,
            monthly_supplement_pick_up_bb: null,
            monthly_supplement_pick_up_fb: null,
            monthly_supplement_pick_up_hb: null,
          };
        }
      });
    },
    getSupplementData(expenses, month, type) {
    // Logic to retrieve monthly data for the specific type.
    const expense = expenses.find((e) => e.date_from === month.date_from);
    return expense ? expense[`allocation_${type.toLowerCase()}_kouver`] : '';
  },
  setSupplementData(value, month, type) {
    // Logic to update monthly data for the specific type.
    const expense = this.revenue.expenses.find(
      (e) => e.date_from === month.date_from
    );
    if (expense) {
      expense[`allocation_${type.toLowerCase()}_kouver`] = value;
    }
  },
    // Get extra revenue data for a specific month and type
    getExtraRevenueData(expenses, month, type) {
      if (!expenses || expenses.length === 0) return null;
      const revenueData = expenses.find((rev) => this.getMonthName(rev.date_from) === month);
      return revenueData ? revenueData[`extra_revenue_per_guest_${type.toLowerCase()}`] : null;
    },


    // Set extra revenue data for a specific month and type
    setExtraRevenueData(value, month, type) {
      const revenueData = this.revenue.expenses.find(
        (rev) => this.getMonthName(rev.date_from) === month
      );
      if (revenueData) {
        revenueData[`extra_revenue_per_guest_${type.toLowerCase()}`] = Number(value);
      }
      this.handleMonthDataChange(month, type, value);
    },

    // Get the month name for a specific date
    getMonthName(date) {
      return new Intl.DateTimeFormat("el-GR", { month: "long" }).format(new Date(date));
    },

    // Handle data change for a specific month
    handleMonthDataChange(month, type, value) {
      if (!this.revenue.expenses[month]) {
        this.revenue.expenses[month] = {};
      }
      this.revenue.expenses[month][type] = value;
    },

    // Fetch the list of revenue items from the API
    async fetchItems() {
      try {
        const response = await getFnbExtrasCosts(this.$route.params.budget_id);
        this.items = response.data.map((item) => ({ ...item, isEditing: false }));
        if (this.items.length > 0) {
          this.revenue = this.items[0]; // Use the first item
        }
        this.initializeMonthData(); // Ensure all months are initialized
      } catch (error) {
        console.error('Error fetching fnb expenses:', error);
      }
    },

    // Fetch the total budget revenue
    async fetchBudgetInfoData() {
      try {
        const response = await fetchBudgetInfo(this.$route.params.budget_id);
        if (response) {
          this.budgetTotal = response.data.data.total_fnb_cost || 0;
        }
      } catch (error) {
        console.error('Failed to fetch budget info:', error);
      }
    },

    // Start editing a specific revenue item
    editItem(revenue) {
      this.currentEditRevenue = revenue;
      revenue.isEditing = true;
    },

    // Cancel editing
    cancelEdit(revenue) {
      revenue.isEditing = false;
      this.currentEditRevenue = null;
    },

    // Save the updated item via API
    async confirmSaveItem(index) {
      try {
        const revenue = this.items[index];
        const payload = {
          assumption_cost_of_breakfast: revenue.assumption_cost_of_breakfast,
          assumption_cost_of_lunch: revenue.assumption_cost_of_lunch,
          assumption_cost_of_dinner: revenue.assumption_cost_of_dinner,
          assumption_cost_of_drinks_ai: revenue.assumption_cost_of_drinks_ai,
          assumption_cost_of_staff_food: revenue.assumption_cost_of_staff_food,
          assumption_cost_of_offers: revenue.assumption_cost_of_offers,
          assumption_breakfast_kouver: revenue.assumption_breakfast_kouver,
          assumption_dinner_kouver: revenue.assumption_dinner_kouver,
          assumption_lunch_kouver: revenue.assumption_lunch_kouver,
          assumption_of_percentage_cost_extra_fnb: revenue.assumption_of_percentage_cost_extra_fnb,
        };

        // Send the payload to the backend
        const response = await updateFnbExtrasCosts(revenue.id, payload);

        if (response.success) {
          useToast().success('Changes saved successfully');
        } else {
          useToast().error('Failed to save changes');
        }

        // End editing mode and refresh data
        revenue.isEditing = false;
        this.fetchItems();
        this.fetchBudgetInfoData();
      } catch (error) {
        console.error('Error saving revenue:', error);
      }
    },

    // Format the monthly data
    formatMonthlyData(expenses, field) {
      const formattedData = {};
      expenses.forEach((revenue) => {
        const month = new Date(revenue.date_from).getMonth() + 1; // Get the month (1-indexed)
        formattedData[month] = revenue[field] || null;
      });
      return formattedData;
    },
  },

  mounted() {
    this.fetchItems();
    this.fetchBudgetInfoData();
    useHead({
      title: 'F&B Extras Costs',
      meta: [
        { name: 'description', content: 'Manage F&B extras expenses for outlets' },
      ],
    });
  },
};
</script>


  <style scoped>
  .form-container {
   background: #f8f9fa;
   padding: 20px;
   border-radius: 8px;
   border: 1px solid #ddd;
 }
 
 .card {
   padding: 15px;
   background: #fff;
   border-radius: 8px;
   border: 1px solid #ddd;
   position: relative;
   margin-left:35px;
 }
 
 .action-buttons {
   position: absolute;
   top: 100px;
   right: 10px;
 }
 
 
 
 hr {
   margin: 10px 0;
 }
 
 .modal-backdrop {
   opacity: 0.5 !important;
 }
 /* Adding a circle bullet point next to each label */
 .label-with-circle::before {
   content: '';
   display: inline-block;
   width: 10px;
   height: 10px;
   background-color: #A6A6A6;
   border-radius: 50%;
   margin-right: 10px;
   vertical-align: middle;
 }
 
 .label-with-circle2::before {
   content: '';
   display: inline-block;
   width: 10px;
   height: 10px;
   background-color: var(--primary-color);
   border-radius: 50%;
   margin-right: 10px;
   vertical-align: middle;
 }
 /* Aligning middle elements like Basic Board and Supplement */
 .align-middle {
   margin-top: 30px; /* Adjust this value based on the spacing you want */
 }
 
 /* Custom shadow for outlet-card */
 .shadow-custom {
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
   transition: box-shadow 0.3s ease-in-out;
 }
 
 .shadow-custom:hover {
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
 }
 
 
 
 .edit{
   position: absolute;
 
   top: 25%; /* Adjust the vertical position to be slightly above middle */
   right: -30px; /* Half the button outside the outlet-card */
   transform: translateY(-150%); /* Center vertically relative to its position */
 
 }
 
 .save {
   position: absolute;
   top: 30%; /* Adjust as needed to lower the button */
   right: -18px; /* Adjust to move outside the card */
   transform: translateY(-150%); /* Further adjust for desired position */
 }
 
 .delete {
   position: absolute;
   top: 50%; /* Adjust to position higher or lower */
   right: -18px; /* Same as above */
   transform: translateY(30%); /* Further adjust for desired position */
 
 }
 .flex-70 {
  flex: 0 0 70%;
  margin-bottom: 0;
}

.flex-30 {
  flex: 0 0 30%;
  max-width: 30%;
}

 </style>
 
