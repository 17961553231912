<template>
    <div id="mytask-layout" class="theme-indigo">
      <!-- Main body area -->
      <div class="main px-lg-4 px-md-4">
        <!-- Navigation bar -->
        <div id="main-header">
          <nav class="navbar">
            <div class="container-xxl d-flex justify-content-between align-items-center">
              <!-- Left: Logo and Final Budget Section -->
              <div class="d-flex align-items-center">
                <div class="logo-container me-3">
                  <img src="../assets/images/logo.png" alt="Logo" class="logo"
                    style="height: 150px; width: 150px;" />
                </div>
                <div class="right-section d-flex flex-column align-items-start">
                  <div class="final-budget">
                    <h1 class="mb-0" style="font-weight: bolder; color: #555555">{{ pageTitle }}</h1>
                  </div>
                  <div class="date-section d-flex align-items-center mt-2">
                    <span class="fw-bold" style="color:#555555; font-size:x-large;">
                      {{ pageSubtitle }}
                    </span>
                  </div>
                </div>
              </div>
  
              <!-- Right: User Profile Dropdown -->
              <div class="dropdown user-profile d-flex align-items-center">
                <!-- <div class="u-info me-2">
                  <p class="mb-0 text-end line-height-sm">
                    <span class="font-weight-bold user-name" v-if="userInfo">{{ userInfo.name }}</span>
                  </p>
                  <small class="user-email" v-if="userInfo">{{ userInfo.email }}</small>
                </div> -->
                <a @click.prevent="toggleMenu()" class="nav-link dropdown-toggle user-menu-toggler pulse p-0" href="#"
                  role="button">
                  <img class="avatar lg rounded-circle img-thumbnail" src="../assets/images/profile.png" alt="profile">
                </a>
                <div
                  :class="{ 'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0': !isMenuOpen, 'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0 show': isMenuOpen }"
                  :style="menuStyle">
                  <div class="card border-0 w280">
                    <div class="card-body pb-0">
                      <div class="d-flex py-1">
                        <img class="avatar rounded-circle" src="../assets/images/profile.png" alt="profile">
                        <div class="flex-fill ms-3">
                          <p class="mb-0">
                            <span class="font-weight-bold user-name" v-if="userInfo">{{ userInfo.name }}</span>
                          </p>
                          <small class="user-email" v-if="userInfo">{{ userInfo.email }}</small>
                        </div>
                      </div>
                      <div>
                        <hr class="dropdown-divider border-dark">
                      </div>
                    </div>
                    <div class="list-group m-2">
                      <RouterLink to="#">
                        <a @click.prevent="showModal" class="list-group-item list-group-item-action border-0">
                          <i class="icofont-ui-settings fs-5 me-3"></i>Αλλαγή password
                        </a>
                      </RouterLink>
                      <a @click.prevent="logout" href="#" class="list-group-item list-group-item-action border-0 btn-logout">
                        <i class="icofont-logout fs-6 me-3"></i>Αποσύνδεση
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
  
        <!-- Main content area -->
        <div id="main-container">
          <LoadingSpinner :isLoading="isLoading" />
          <slot></slot>
        </div>
  
        <!-- Footer -->
        <div class="footer">
          <div class="container-xxl p-1">
            <span class="text-white" style="text-align: center; font-weight: 600; font-size: 13px;">
              <span id="footeryear"></span> © By
              <a class="text-white" href="https://citrine.gr/" target="_blank">CITRINE</a>
            </span>
          </div>
        </div>
      </div>
    </div>

   <!-- Modal -->
   <div v-if="isModalVisible" class="custom-modal">
      <div class="custom-modal-dialog">
        <div class="custom-modal-content">
          <div class="custom-modal-header">
            <h5 class="custom-modal-title">Αλλαγή κωδικού</h5>
            <button type="button" class="close" @click="hideModal">  
                <span>&times;</span>
            </button>
          </div>
          <div class="custom-modal-body">
            <form @submit.prevent="handleChangePassword">
              <div class="mb-3">
                <label for="oldPassword" class="form-label">Παλιός κωδικός</label>
                <input type="password" id="oldPassword" v-model="oldPassword" class="form-control" required />
              </div>
              <div class="mb-3">
                <label for="newPassword" class="form-label">Νέος κωδικός</label>
                <input type="password" id="newPassword" v-model="newPassword" class="form-control" required />
              </div>
              <div class="mb-3">
                <label for="confirmPassword" class="form-label">Επιβεβαίωση νέου κωδικού</label>
                <input type="password" id="confirmPassword" v-model="confirmPassword" class="form-control" required />
              </div>
              <div class="modal-footer">
                <button type="submit text-end" @click="hideModal" class="btn btn-secondary">Άκυρο</button>
                <button type="submit text-end" class="btn btn-primary" style="background-color: #E1961F">Αποθήκευση</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { fetchUsers, changePassword, fetchUserInfo } from '../services/user';
import { useToast } from 'vue-toastification';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { getHotel } from '@/services/hotels';

export default {
    name: 'MainLayout',
    components: {
        LoadingSpinner,
    },
    setup() {
        const isModalVisible = ref(false);
        const isLoading = ref(false);
        const userInfo = ref(null);
        const users = ref([]);
        const selectedUser = ref(null);
        const showUsersContainer = ref(false);
        const isMenuOpen = ref(false);
        const pageTitle = ref('');
        const pageSubtitle = ref('BUDGETS');
        const router = useRouter();
        const oldPassword = ref('');
        const newPassword = ref('');
        const confirmPassword = ref('');
        const toast = useToast();   
        const route = useRoute();

        onMounted(async () => {
            const response = await getHotel(route.params.hotel_id);
            if (response.data.success) {
                pageTitle.value = response.data.data.title;
            }
            fetchUserData();
        });

        const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value; // Toggle menu state
    };

    const showModal = () => {
      isModalVisible.value = true;
    };

    const hideModal = () => {
      isModalVisible.value = false;
    };
    const handleChangePassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        toast.error('Ο νέος κωδικός και η επιβεβαίωση δεν ταιριάζουν.');
        return;
      }

      try {
        const payload = {
          old_password: oldPassword.value,
          password: newPassword.value,
          password_confirmation: confirmPassword.value,
        };

        const response = await changePassword(payload);

        if (response.success) {
          toast.success('Ο κωδικός άλλαξε με επιτυχία!');
          hideModal();
        } else {
          toast.error(response.message || 'Αποτυχία αλλαγής κωδικού.');
        }
      } catch (error) {
        console.error('Error changing password:', error);
        toast.error('Προέκυψε σφάλμα κατά την αλλαγή του κωδικού.');
      }
    };

        const fetchUserData = async () => {
            try {
                const response = await fetchUserInfo();
                userInfo.value = response.data?.info || null;
                if (userInfo.value?.customer_id === null && userInfo.value.superUser === 1) {
                    showUsersContainer.value = true;
                    fetchUsersData();
                }
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };

        const fetchUsersData = async () => {
            try {
                const response = await fetchUsers();
                if (response.data.success) {
                    users.value = response.data.data;
                }
            } catch (error) {
                console.error('Failed to fetch users:', error);
            }
        };

       

        const logout = () => {
            localStorage.removeItem('api_token');
            router.push('login');
        };

        const menuStyle = computed(() => ({
            top: isMenuOpen.value ? '100%' : 'initial',
            right: isMenuOpen.value ? '0' : 'initial',
        }));

        const dropdownClass = computed(() => ({
            'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0': !isMenuOpen.value,
            'dropdown-menu user-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0 show': isMenuOpen.value,
        }));

        return {
            isLoading,
            toggleMenu,
            userInfo,
            users,
            selectedUser,
            showUsersContainer,
            isMenuOpen,
            menuStyle,
            dropdownClass,
            pageTitle,
            pageSubtitle,
            logout,
            oldPassword,
      newPassword,
      confirmPassword,
      showModal,
      hideModal,
      handleChangePassword,
      isModalVisible
        };
    },
};
</script>

<style scoped>
.upper-menu {
    background-color: #e1e1e1;
    border-radius: 30px;
}

.h-right {
    box-shadow: 0 4px 8px rgba(0.6, 0.6, 0.7, 0.6);
    border-radius: 30px;
    background-color: white;
    min-height: 50px;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
    color: #939393;
    margin-right: 10px;
    font-weight: bolder;
}

.nav-tabs .nav-link {
    color: #8d8d8d;
    font-weight: bolder;
    border-radius: 4px 4px 0 0;
    padding: 10px 15px;
    font-size: 16px;
}

.nav-tabs .nav-link.active {
    background-color: #ffffff;
    height: 95%;
    border-color: #ddd #ddd #fff;
    border-radius: 30px;
    color: var(--primary-color);
}

.nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: #ddd;
    border-radius: 30px;
}



</style>