<template>
  <div class="form-section mt-4 ms-4">
    <!-- Container for title and button -->
    <div class="d-flex align-items-center ms-2 justify-content-start">
      <h5 style="font-weight: bolder; color: #555555">NEO ΛΕΙΤΟΥΡΓΙΚΟ ΤΜΗΜΑ</h5>
      <button v-if="!showDepartmentForm" class="btn btn-primary fw-bold" style="margin-left:10px;" @click="showAddDepartmentForm">
        ΠΡΟΣΘΗΚΗ +
      </button>
    </div>

    <!-- Department Form for Adding New Department -->
    <div v-if="showDepartmentForm" class="col-md-6 form-container">
      <div class="row">
        <div class="col-md-6">
          <p class="label-with-circle2">
            <strong>Τίτλος Τμήματος</strong>
            <hr />
          </p>
          <input type="text" v-model="departmentForm.title" class="form-control" maxlength="255" required /><br />
        </div>
        <div class="col-md-6 form-group">
          <p class="label-with-circle2">
            <strong>Ειδικότητες</strong>
            <hr />
          </p>
          <Select2 v-model="departmentForm.specialties" :options="specialtiesOptions" :settings="select2Settings"></Select2><br />
        </div>
      </div>
      <div class="row form-actions mt-4">
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary" @click="resetDepartmentForm(); showDepartmentForm = false;">Ακύρωση</button>
          <button class="btn btn-primary ms-2 save-button" @click="openDepartmentSaveModal">Αποθήκευση</button>
        </div>
      </div>
    </div>

    <!-- Operational Department List with Inline Editing and Specialties -->
    <div class="mt-4 ms-2 row">
      <div v-for="department in operationalDepartments" :key="department.id" class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative">
        <div v-if="!editStates.departments[department.id]">
          <div class="row" style="color:#A6A6A6; font-weight:bolder">
            <div class="col-md-6">
              <p class="label-with-circle">
                <strong>{{ department.title }}</strong>
                <hr />
              </p>
              <p><strong>Ειδικότητες:</strong></p>
              <ul>
                <li v-for="specialty in department.specialties" :key="specialty.id">
                  {{ findSpecialtyTitle(specialty.id) }}
                </li>
              </ul>
            </div>
            
          </div>

          <div class="action-buttons">
            <button class="btn border-0 p-1 edit" @click="editDepartment(department)">
              <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
            </button>
          </div>
        </div>

        <!-- Editable Inline View for Department -->
        <div v-else>
          <div class="row" style="color:#4A4A4A; font-weight:bolder">
            <div class="col-md-8">
              <p class="label-with-circle2">
                <strong>Τίτλος Τμήματος</strong>
                <hr />
              </p>
              <input type="text" v-model="departmentForm.title" class="form-control" maxlength="255" required /><br />
            </div>
            <div class="col-md-8 form-group">
              <Select2 v-model="departmentForm.specialties" :options="specialtiesOptions" :settings="select2Settings"></Select2>
            </div>
          </div>

          <div class="row form-actions mt-4">
              <div class="d-flex justify-content-end">
                <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditDepartment(department)">
                  ΑΚΥΡΩΣΗ
                </button>
              </div>
          </div>

          <!-- Form Actions -->
          <div class="action-buttons">
            <button class="btn border-0 p-1 save" @click="openDepartmentSaveModal">
              <img src="../assets/images/save.png" alt="Save" width="30">
            </button>
            <button class="btn border-0 p-1 delete" @click="openDeleteDepartmentModal(department)">
              <img src="../assets/images/bin.png" alt="Delete" width="30">
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Save Confirmation Modal for Departments -->
    <div class="modal fade" id="departmentSaveModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Επιβεβαίωση Αποθήκευσης</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">Είστε σίγουροι ότι θέλετε να αποθηκεύσετε αυτό το τμήμα;</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ακύρωση</button>
            <button type="button" class="btn btn-primary" @click="confirmSaveDepartment">Αποθήκευση</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal for Departments -->
    <div class="modal fade" id="deleteDepartmentModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Επιβεβαίωση Διαγραφής</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το τμήμα;</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ακύρωση</button>
            <button type="button" class="btn btn-danger" @click="confirmDeleteDepartment">Διαγραφή</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Categories Section -->
    <div class="form-section mt-4 ms-4">
      <div class="d-flex align-items-center justify-content-start">
        <h5 style="font-weight: bolder; color: #555555">ΝΕΑ ΚΑΤΗΓΟΡΙΑ ΕΙΔΙΚΟΤΗΤΑΣ</h5>
        <button v-if="!showCategoryForm" class="btn btn-primary fw-bold" style="margin-left:10px;" @click="showAddCategoryForm">
          ΠΡΟΣΘΗΚΗ +
        </button>
      </div>

      <!-- Category Form -->
      <div v-if="showCategoryForm" class="col-md-4 form-container">
        <div class="row">
          <div class="col-md-8">
            <p class="label-with-circle2">
              <strong>Τίτλος Κατηγορίας</strong>
              <hr />
            </p>
            <input type="text" v-model="categoryForm.title" class="form-control" maxlength="255" required /><br />
          </div>
        </div>
        <div class="row form-actions mt-4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary" @click="resetCategoryForm(); showCategoryForm = false;">Ακύρωση</button>
            <button class="btn btn-primary ms-2 save-button" @click="openCategorySaveModal">Αποθήκευση</button>
          </div>
        </div>
      </div>

      <!-- Category List -->
      <div class="mt-4 row">
        <div v-for="category in filteredCategories" :key="category.id" class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative">
          <div v-if="!editStates.categories[category.id]">
            <div class="row" style="color:#A6A6A6; font-weight:bolder">
              <div class="col-md-8">
                <p class="label-with-circle">
                  <strong>{{ category.title }}</strong>
                  <hr />
                </p>
              </div>
            </div>

            <div class="action-buttons">
              <button class="btn border-0 p-1 edit" @click="editCategory(category)">
                <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
              </button>
            </div>
          </div>

          <!-- Editable Inline View for Category -->
          <div v-else>
            <div class="row" style="color:#4A4A4A; font-weight:bolder">
              <div class="col-md-8">
                <p class="label-with-circle2">
                  <strong>Τίτλος Κατηγορίας</strong>
                  <hr />
                </p>
                <input type="text" v-model="categoryForm.title" class="form-control" maxlength="255" required />
              </div>
            </div>
            <div class="row form-actions mt-4">
              <div class="d-flex justify-content-middle">
                <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditCategory(category)">
                  ΑΚΥΡΩΣΗ
                </button>
              </div>
            </div>

            <!-- Form Actions -->
            <div class="action-buttons">
              <button class="btn border-0 p-1 save" @click="openCategorySaveModal">
                <img src="../assets/images/save.png" alt="Save" width="30">
              </button>
              <button class="btn border-0 p-1 delete" @click="openDeleteCategoryModal(category)">
                <img src="../assets/images/bin.png" alt="Delete" width="30">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Save Confirmation Modal for Categories -->
    <div class="modal fade" id="categorySaveModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Επιβεβαίωση Αποθήκευσης</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">Είστε σίγουροι ότι θέλετε να αποθηκεύσετε αυτή την κατηγορία;</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ακύρωση</button>
            <button type="button" class="btn btn-primary" @click="confirmSaveCategory">Αποθήκευση</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal for Categories -->
    <div class="modal fade" id="deleteCategoryModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Επιβεβαίωση Διαγραφής</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την κατηγορία;</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ακύρωση</button>
            <button type="button" class="btn btn-danger" @click="confirmDeleteCategory">Διαγραφή</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Specialties Section -->
    <div class="form-section mt-4 ms-4">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <h5 style="font-weight: bolder; color: #555555">NEA EIΔΙΚΟΤΗΤΑ</h5>
          <button v-if="!showForm" class="btn btn-primary fw-bold" style="margin-left:10px;" @click="showAddSpecialtyForm">
            ΠΡΟΣΘΗΚΗ +
          </button>
        </div>

        <div class="d-flex align-items-center">
          <h5 style="font-weight: bolder; color: #555555; margin-right: 10px;">Επιλέξτε Κατηγορία</h5>
          <div class="dropdown">
            <select v-model="selectedCategory" class="form-select">
              <option value="">Όλες</option>
              <option v-for="(title, id) in categoryTitles" :key="id" :value="id">
                {{ title }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="showForm" class="form-container mt-4">
        <div class="row">
          <div class="col-md-4 form-group">
            <p class="label-with-circle2">
              <strong>Τίτλος Ειδικότητας</strong>
              <hr />
            </p>
            <input type="text" v-model="specialtyForm.title" class="form-control" maxlength="255" required /><br />
          </div>
          <div class="col-md-4 form-group">
            <p class="label-with-circle2">
              <strong>Λειτουργική Κατηγορία</strong>
              <hr />
            </p>
            <select v-model="specialtyForm.operational_category_id" class="form-select" required>
              <option v-for="(title, id) in categoryTitles" :key="id" :value="id">
                {{ title }}
              </option>
            </select><br />
          </div>
        </div>
        <div class="row form-actions mt-4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary" @click="resetSpecialtyForm(); showForm = false;">Ακύρωση</button>
            <button class="btn btn-primary ms-2 save-button" @click="openSpecialtySaveModal($event, null)">Αποθήκευση</button>
          </div>
        </div>
      </div>

      <div v-if="filteredSpecialties.length > 0" class="specialty-list mt-4">
        <div v-for="(specialtiesByCategory, categoryId) in groupedSpecialties" :key="categoryId" class="row">
          <h5 v-if="specialtiesByCategory.length > 0" style="font-weight: bold; color: #333;">
            {{ categoryTitles[categoryId] }}
          </h5>
          <div v-for="(specialty, index) in specialtiesByCategory" :key="specialty.id" class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative">
            <div v-if="!editStates.specialties[specialty.id]">
              <div class="row" style="color:#A6A6A6; font-weight:bolder">
                <div class="col-md-8">
                  <p class="label-with-circle">
                    <strong>{{ specialty.title }}</strong>
                    <hr />
                  </p>
                </div>
              </div>

              <div class="action-buttons">
                <button class="btn border-0 p-1 edit" @click="editSpecialty(specialty)">
                  <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
                </button>
              </div>
            </div>

            <div v-else>
              <div class="row" style="color:#4A4A4A; font-weight:bolder">
                <div class="col-md-8 form-group">
                  <p class="label-with-circle2">
                    <strong>Τίτλος Ειδικότητας</strong>
                    <hr />
                  </p>
                  <input type="text" v-model="specialty.title" class="form-control" maxlength="255" required /><br />
                  <p class="label-with-circle2">
                    <strong>Λειτουργική Κατηγορία</strong>
                    <hr />
                  </p>
                  <select v-model="specialty.operational_category_id" class="form-control" required>
                    <option v-for="(title, id) in categoryTitles" :key="id" :value="id">
                      {{ title }}
                    </option>
                  </select><br />
                </div>
              </div>

              <div class="row form-actions mt-4">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditSpecialty(specialty)">
                    ΑΚΥΡΩΣΗ
                  </button>
                </div>
              </div>
              <div class="action-buttons">
                <button class="btn border-0 p-1 save" @click="openSpecialtySaveModal($event, specialty)">
                  <img src="../assets/images/save.png" alt="Save" width="30">
                </button>
                <button class="btn border-0 p-1 delete" @click="openDeleteSpecialtyModal(specialty)">
                  <img src="../assets/images/bin.png" alt="Delete" width="30">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Save Confirmation Modal for Specialties -->
    <div class="modal fade" id="specialtySaveModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Επιβεβαίωση Αποθήκευσης</h5>
            <button type="button" class="close" @click="closeSpecialtySaveModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">Είστε σίγουροι ότι θέλετε να αποθηκεύσετε αυτή την ειδικότητα;</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeSpecialtySaveModal">Ακύρωση</button>
            <button type="button" class="btn btn-primary" @click="confirmSaveSpecialty">Αποθήκευση</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal for Specialties -->
    <div class="modal fade" id="deleteSpecialtyModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Επιβεβαίωση Διαγραφής</h5>
            <button type="button" class="close" @click="closeDeleteSpecialtyModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την ειδικότητα;</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeDeleteSpecialtyModal">Ακύρωση</button>
            <button type="button" class="btn btn-danger" @click="confirmDeleteSpecialty">Διαγραφή</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import Select2 from "vue3-select2-component";
import {
  createOperationalDepartment,
  getOperationalDepartments,
  updateOperationalDepartment,
  deleteOperationalDepartment,
  createOperationalCategory,
  getOperationalCategories,
  updateOperationalCategory,
  deleteOperationalCategory,
  createOperationalSpecialty,
  getOperationalSpecialties,
  updateOperationalSpecialty,
  deleteOperationalSpecialty,
} from '@/services/operational-departments';
import { useToast } from 'vue-toastification';

export default {
  name: 'OperationalDepartment',
  components: {
    Select2
  },
  data() {
    return {
      // For Departments
      showDepartmentForm: false,
      showDepartmentSaveModal: false,
      showDeleteDepartmentModal: false,
      departmentForm: {
        title: '',
        specialties: [],
      },
      operationalDepartments: [],
      operationalSpecialties: [],
      specialtiesOptions: [],
      editStates: {
        departments: {},
        categories: {},
        specialties: {},
      },

      // For Categories
      showCategoryForm: false,
      showCategorySaveModal: false,
      showDeleteCategoryModal: false,
      categoryForm: {
        title: '',
      },
      operationalCategories: [],
      categoryTitles: {},

      // For Specialties
      showForm: false,
      select2Settings: { multiple: true },
      showSpecialtySaveModal: false,
      showDeleteSpecialtyModal: false,
      specialtyForm: {
        title: '',
        operational_category_id: null,
      },
      selectedCategory: '',
    };
  },
  computed: {
    filteredSpecialties() {
      if (!this.selectedCategory) return this.operationalSpecialties;
      return this.operationalSpecialties.filter(specialty => specialty.operational_category_id == this.selectedCategory);
    },
    filteredCategories() {
      return this.operationalCategories;
    },
    groupedSpecialties() {
      const grouped = this.operationalCategories.reduce((acc, category) => {
        acc[category.id] = [];
        return acc;
      }, {});

      this.filteredSpecialties.forEach(specialty => {
        if (grouped[specialty.operational_category_id]) {
          grouped[specialty.operational_category_id].push(specialty);
        }
      });

      return grouped;
    }
  },
  methods: {
    findSpecialtyTitle(specialtyId) {
      const specialty = this.operationalSpecialties.find(s => s.id === specialtyId);
      return specialty ? specialty.title : 'Unknown Specialty';
    },

    // CRUD for Departments
    async fetchOperationalDepartments() {
      try {
        const response = await getOperationalDepartments(this.$route.params.budget_id);
        this.operationalDepartments = response.data;
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    },
    showAddDepartmentForm() {
      this.resetDepartmentForm();
      this.showDepartmentForm = true;
    },
    resetDepartmentForm() {
      this.departmentForm = { title: '', specialties: [] };
    },
    openDepartmentSaveModal() {
      $('#departmentSaveModal').modal('show');
    },
    closeDepartmentSaveModal() {
      $('#departmentSaveModal').modal('hide');
    },
    async confirmSaveDepartment() {
      try {
        const form = { title: this.departmentForm.title, specialties: this.departmentForm.specialties };
        if (this.departmentForm.id) {
          const response = await updateOperationalDepartment(this.departmentForm.id, form);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
        } else {
          const response = await createOperationalDepartment(this.$route.params.budget_id, form);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          this.operationalDepartments.push(response.data);
        }
        this.showDepartmentForm = false;
        this.editStates.departments[this.departmentForm.id] = false;
        this.closeDepartmentSaveModal();
        this.fetchOperationalDepartments();
      } catch (error) {
        console.error('Error saving department:', error);
      }
    },
    editDepartment(department) {
      this.departmentForm = {
        id: department.id,
        title: department.title,
        specialties: department.specialties.map(s => s.id),
      };
      this.editStates.departments[department.id] = true;
    },
    cancelEditDepartment(department) {
      this.editStates.departments[department.id] = false;
    },
    openDeleteDepartmentModal(department) {
      this.currentDeleteDepartment = department;
      $('#deleteDepartmentModal').modal('show');
    },
    closeDeleteDepartmentModal() {
      $('#deleteDepartmentModal').modal('hide');
    },
    async confirmDeleteDepartment() {
      try {
        const response = await deleteOperationalDepartment(this.currentDeleteDepartment.id);
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        const index = this.operationalDepartments.findIndex(d => d.id === this.currentDeleteDepartment.id);
        if (index !== -1) this.operationalDepartments.splice(index, 1);
        this.closeDeleteDepartmentModal();
      } catch (error) {
        console.error('Error deleting department:', error);
      }
    },

    // CRUD for Categories
    async fetchOperationalCategories() {
      try {
        const response = await getOperationalCategories(this.$route.params.budget_id);
        this.operationalCategories = response.data;
        this.categoryTitles = this.operationalCategories.reduce((acc, category) => {
          acc[category.id] = category.title;
          return acc;
        }, {});
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
    showAddCategoryForm() {
      this.resetCategoryForm();
      this.showCategoryForm = true;
    },
    resetCategoryForm() {
      this.categoryForm = { title: '' };
    },
    openCategorySaveModal() {
      $('#categorySaveModal').modal('show');
    },
    closeCategorySaveModal() {
      $('#categorySaveModal').modal('hide');
    },
    async confirmSaveCategory() {
      try {
        const form = { title: this.categoryForm.title };
        if (this.categoryForm.id) {
          const response = await updateOperationalCategory(this.categoryForm.id, form);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
        } else {
          const response = await createOperationalCategory(this.$route.params.budget_id, form);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          this.operationalCategories.push(response.data);
        }
        this.showCategoryForm = false;
        this.editStates.categories[this.categoryForm.id] = false;
        this.closeCategorySaveModal();
        this.fetchOperationalCategories();
      } catch (error) {
        console.error('Error saving category:', error);
      }
    },
    editCategory(category) {
      this.categoryForm = {
        id: category.id,
        title: category.title,
      };
      this.editStates.categories[category.id] = true;
    },
    cancelEditCategory(category) {
      this.editStates.categories[category.id] = false;
    },
    openDeleteCategoryModal(category) {
      this.currentDeleteCategory = category;
      $('#deleteCategoryModal').modal('show');
    },
    closeDeleteCategoryModal() {
      $('#deleteCategoryModal').modal('hide');
    },
    async confirmDeleteCategory() {
      try {
        const response = await deleteOperationalCategory(this.currentDeleteCategory.id);
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        const index = this.operationalCategories.findIndex(c => c.id === this.currentDeleteCategory.id);
        if (index !== -1) this.operationalCategories.splice(index, 1);
        this.closeDeleteCategoryModal();
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    },

    // CRUD for Specialties
    async fetchOperationalSpecialties() {
      try {
        const response = await getOperationalSpecialties(this.$route.params.budget_id);
        this.operationalSpecialties = response.data;
        this.specialtiesOptions = this.operationalSpecialties.map(s => ({ id: s.id, text: s.title }));
      } catch (error) {
        console.error('Error fetching specialties:', error);
      }
    },
    showAddSpecialtyForm() {
      this.resetSpecialtyForm();
      this.showForm = true;
    },
    resetSpecialtyForm() {
      this.specialtyForm = {
        title: '',
        operational_category_id: null,
      };
    },
    openSpecialtySaveModal() {
      $('#specialtySaveModal').modal('show');
    },
    closeSpecialtySaveModal() {
      $('#specialtySaveModal').modal('hide');
    },
    async confirmSaveSpecialty() {
      try {
        if (!this.specialtyForm.id) {
          const response = await createOperationalSpecialty(this.$route.params.budget_id, this.specialtyForm);
          if (response.success) {
            useToast().success(response.message);
          } else {
            useToast().error(response.message);
          }
          this.operationalSpecialties.push(response.data);
        } else {
          const specialtyToUpdate = this.operationalSpecialties.find(s => s.id === this.specialtyForm.id);
          if (specialtyToUpdate) {
            const updatedData = await updateOperationalSpecialty(this.specialtyForm.id, specialtyToUpdate);
            if (updatedData.success) {
              useToast().success(updatedData.message);
            } else {
              useToast().error(updatedData.message);
            }
            Object.assign(specialtyToUpdate, updatedData.data);
            this.editStates.specialties[this.specialtyForm.id] = false;
          }
        }

        this.showForm = false;
        this.closeSpecialtySaveModal();
        this.fetchOperationalSpecialties();
      } catch (error) {
        console.error('Error saving specialty:', error);
      }
    },
    editSpecialty(specialty) {
      this.specialtyForm = {
        id: specialty.id,
        title: specialty.title,
        operational_category_id: specialty.operational_category_id,
      };
      this.editStates.specialties[specialty.id] = true;
    },
    cancelEditSpecialty(specialty) {
      this.editStates.specialties[specialty.id] = false;
    },
    openDeleteSpecialtyModal(specialty) {
      this.currentDeleteSpecialty = specialty;
      $('#deleteSpecialtyModal').modal('show');
    },
    closeDeleteSpecialtyModal() {
      $('#deleteSpecialtyModal').modal('hide');
    },
    async confirmDeleteSpecialty() {
      try {
        const response = await deleteOperationalSpecialty(this.currentDeleteSpecialty.id);
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        const index = this.operationalSpecialties.findIndex(s => s.id === this.currentDeleteSpecialty.id);
        if (index !== -1) this.operationalSpecialties.splice(index, 1);
        this.closeDeleteSpecialtyModal();
      } catch (error) {
        console.error('Error deleting specialty:', error);
      }
    },
  },
  mounted() {
    useHead({
      title: 'Λειτουργικά Τμήματα',
    });
    this.fetchOperationalDepartments();
    this.fetchOperationalCategories();
    this.fetchOperationalSpecialties();
  },
};
</script>

<style scoped>
.form-container {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.card {
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  position: relative;
  margin-left: 35px;
}

.action-buttons {
  position: absolute;
  top: 100px;
  right: 10px;
}

.label-with-circle::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #a6a6a6;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.label-with-circle2::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.align-middle {
  margin-top: 30px;
}

.shadow-custom {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.shadow-custom:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
}

.edit {
  position: absolute;
  top: 25%;
  right: -20px;
  transform: translateY(-200%);
}

.save {
  position: absolute;
  top: 30%;
  right: -18px;
  transform: translateY(-95%);
}

.delete {
  position: absolute;
  top: 50%;
  right: -18px;
  transform: translateY(30%);
}
</style>
