import apiClient from "./api";

async function createExpenseCategory(budgetId, data) {
  try {
    const response = await apiClient.post(
      `/insert-expense-category/${budgetId}`,
      data
    );
    return response.data; // Return the actual data part of the response
  } catch (error) {
    // Handle the error response
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return {
        success: false,
        message: "No response received",
        details: error.request,
      };
    } else {
      return {
        success: false,
        message: "Request setup error",
        details: error.message,
      };
    }
  }
}

async function updateExpenseCategory(id, data) {
    try {
      const response = await apiClient.post(
        `/update-expense-category/${id}`,
        data
      );
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

async function deleteExpenseCategory(id) {
    try {
      const response = await apiClient.post(
        `/delete-expense-category/${id}`);
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

async function getExpenseCategories(budgetId) {
    try {
      const response = await apiClient.get(
        `/get-expense-categories/${budgetId}`);
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

export { createExpenseCategory, updateExpenseCategory, deleteExpenseCategory, getExpenseCategories };
