<template>
  <div class="form-section mt-4 ms-4">
    <!-- Container for title and button -->
    <div class="d-flex align-items-center justify-content-between">
      <div>
          <h5 style="font-weight: bolder; color: #555555">Room Revenues</h5>
        </div>
        <div>
          <!-- Display the total sum of all total_revenue, with € symbol only if total is greater than 0 -->
          <h5 style="font-weight: bolder; color: #555555">
            ΣΥΝΟΛΟ: {{ formattedBudgetTotal }}
            <span v-if="budgetTotal"> €</span>
          </h5>
        </div>
      </div>
    <!-- Container for Season Dates and Add Button -->
    <div class="d-flex align-items-center justify-content-between">
      <h5 style="font-weight: bolder; color: #555555;">Season Dates</h5>
      <button @click="addSeason" class="btn btn-primary fw-bold">ΠΡΟΣΘΗΚΗ +</button>
    </div>

    <!-- Seasons List -->
    <div class="mt-3 row" style="width:100%">
      <div
        v-for="(season, index) in seasons"
        :key="season.id"
        class="col-md-3 card shadow-custom mb-3 ms-3 bg-white rounded position-relative"
      >
        <!-- Non-Editable View for Seasons -->
        <div v-if="!season.isEditing">
          <div class="row" style="color:#A6A6A6; font-weight:bolder; width: 100%;">
            <div class="col-md-12">
              <p class="label-with-circle"><strong>Season {{ index + 1 }}</strong><hr/></p>
              <p><strong>From:</strong> {{ season.season_date_from_formatted }}</p>
              <p><strong>To:</strong> {{ season.season_date_to_formatted }}</p>
              <p><strong>Season Type:</strong> {{ season.season_type }}</p>
            </div>
          </div>
          <div class="action-buttons" v-if="!season.isEditing">
            <button class="btn border-0 p-1 edit-button" @click="editSeason(index)">
              <img src="../assets/images/edit-budget.png" alt="Edit" />
            </button>
          </div>
        </div>

        <!-- Editable View for Seasons -->
        <div v-else>
  <div class="row" style="color:#4A4A4A; font-weight:bolder;">
    <div class="col-md-12">
      <p class="label-with-circle2"><strong>Season {{ index + 1 }}</strong><hr/></p>
      
      <!-- Date From Row -->
      <div class="row align-items-center mb-3">
        <div class="col-md-3">
          <label><strong>Date From</strong></label>
        </div>
        <div class="col-md-9">
          <input type="date" v-model="season.season_date_from" class="form-control" />
        </div>
      </div>

      <!-- Date To Row -->
      <div class="row align-items-center mb-3">
        <div class="col-md-3">
          <label><strong>Date To</strong></label>
        </div>
        <div class="col-md-9">
          <input type="date" v-model="season.season_date_to" class="form-control" />
        </div>
      </div>

      <!-- Season Type Row -->
      <div class="row align-items-center mb-3">
        <div class="col-md-3">
          <label><strong>Season Type</strong></label>
        </div>
        <div class="col-md-9">
          <select v-model="season.season_type" class="form-control">
            <option value="Middle">Mid</option>
            <option value="High">High</option>
            <option value="Low">Low</option>
          </select>
        </div>
      </div>

      <!-- Cancel Button -->
      <div class="d-flex justify-content-end mt-3">
        <button class="btn btn-secondary" @click="cancelEditSeason(index)">
          ΑΚΥΡΩΣΗ
        </button>
      </div>
    </div>
  </div>

  <!-- Action Buttons -->
  <div class="action-buttons mt-4 d-flex flex-column" v-if="season.isEditing">
   
    <button class="btn border-0 p-1 delete-button" @click="openDeleteModal(index)">
      <img src="../assets/images/bin.png" alt="Delete" />
    </button>
    <button class="btn border-0 p-1 save " @click="saveAllSeasons">
        <img src="../assets/images/save.png" alt="Save" width="30">
      </button>
  </div>
</div>

      </div>
     
    </div>

    

    <!-- Room Types Section -->
    <div class="room-types-section mt-2">
      <div class="d-flex align-items-center justify-content-start">
        <h5 style="font-weight: bolder; color: #555555;">Room Revenues</h5>
      </div>

      <!-- Room Types List -->
      <div class="mt-1">
        <div
          v-for="(room, index) in roomTypes"
          :key="room.id"
          class="card shadow-custom p-3 mb-3 bg-white rounded position-relative room-type-card"
        >
          <!-- Non-Editable View for Rooms (Fields disabled) -->
          <div v-if="!room.isEditing">
            <div class="row" style="color:#A6A6A6; font-weight:bolder;">
              <div class="col-md-8">
                <p class="label-with-circle"><strong>ROOM TYPE ({{ room.title }})</strong></p>
              </div>
    <div class="col-md-4 text-end">
      <p>
        <strong>Σύνολο Εσόδου:</strong> {{ formatNumber(room.total_revenue) }} €
      </p>
    </div><hr/>
              <div class="col-md-6">
                <p class="label-with-circle"><strong>Season days</strong></p>
                <div class="d-flex flex-wrap">
                   <p><strong>Low:</strong> {{ totalSeasonLow }} days</p>,&nbsp;
                   <p><strong>Middle:</strong> {{ totalSeasonMiddle }} days</p>,&nbsp;
                   <p><strong>High:</strong> {{ totalSeasonHigh }} days</p>,&nbsp;
                   <p><strong>Total:</strong> {{ totalSeason }} days</p>&nbsp;
               </div>
              </div>
               <!-- Pricing per Guest / Season Section -->
    <div class="col-md-6">
      <p class="label-with-circle"><strong>Pricing per Guest / Season (Low, Middle, High)</strong></p>
      <div class="d-flex flex-wrap">
        <p><strong>Low:</strong> &euro;{{ room.pricing_per_guest_low }}</p>,&nbsp;
        <p><strong>Middle:</strong> &euro;{{ room.pricing_per_guest_middle }}</p>,&nbsp;
        <p><strong>High:</strong> &euro;{{ room.pricing_per_guest_high }}</p>
      </div>
    </div>

              <!-- Ratio Guest/Room -->
    <div class="col-md-6">
      <p class="label-with-circle"><strong>Ratio Guest/Room</strong></p>
      <p>{{ room.ratio }}</p>
    </div>
              <!-- Pricing per Room / Season -->
    <div class="col-md-6">
      <p class="label-with-circle"><strong>Pricing per Room / Season (Low, Middle, High)</strong></p>
      <div class="d-flex flex-wrap">
        <p><strong>Low:</strong> &euro;{{ room.pricing_per_room_low }}</p>,&nbsp;
        <p><strong>Middle:</strong> &euro;{{ room.pricing_per_room_middle }}</p>,&nbsp;
        <p><strong>High:</strong> &euro;{{ room.pricing_per_room_high }}</p>
      </div>
    </div>

    
    
    <div class="row mt-3">
      <h4 class="fw-bolder">TO Pricing (Τιμολόγηση Πρακτόρων)</h4>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>Room ADR</strong></p>
                  <p>{{ room.adr }}</p>
                </div>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>Room Contract Value</strong></p>
                  <p>{{ room.room_contract_value }}</p>
                </div>
                <div class="col-md-4">

                  <p class="label-with-circle"><strong>Season Average Rate</strong></p>

                  <div class="d-flex flex-wrap">
                    <p><strong>Low:</strong>{{ room.season_average_rate_low }}</p>,&nbsp;
                    <p><strong>Middle:</strong>{{ room.season_average_rate_middle }}</p>,&nbsp;
                    <p><strong>High:</strong>{{ room.season_average_rate_high }}</p>
                  </div>
                </div>

              </div><br>
              <div class="row mt-3">
                <h4 class="fw-bolder">Final Pricing (Τελική Τιμολόγηση)</h4>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>Available room nights</strong></p>
                  <p>{{ room.available_room_nights }}</p>
                </div>
                <div class="col-md-4">

                  <p class="label-with-circle"><strong>Available room nights / Season (Low, Middle, High)</strong></p>
                  <div class="d-flex flex-wrap">
                    <p><strong>Low:</strong>{{ room.available_room_nights_low }}</p>,&nbsp;
                    <p><strong>Middle:</strong>{{ room.available_room_nights_middle }}</p>,&nbsp;
                    <p><strong>High:</strong>{{ room.available_room_nights_high }}</p>
                  </div>
                </div>
                <div class="col-md-4">

                  <p class="label-with-circle"><strong>Available pax nights</strong></p>
                  <p>{{ room.available_pax_nights }}</p>
                </div>
                <div class="col-md-4">

                  <p class="label-with-circle"><strong>Available pax nights / Season (Low, Middle, High)</strong></p>
                  <div class="d-flex flex-wrap">
                    <p><strong>Low:</strong>{{ room.available_pax_nights_low }}</p>,&nbsp;
                    <p><strong>Middle:</strong>{{ room.available_pax_nights_middle }}</p>,&nbsp;
                    <p><strong>High:</strong>{{ room.available_pax_nights_high }}</p>
                  </div>
                </div>
                <div class="col-md-4">

                  <p class="label-with-circle"><strong>Room occupancy / Season (Low, Middle, High)</strong></p>
                  <div class="d-flex flex-wrap">
                    <p><strong>Low:</strong> {{ room.room_occupancy_low }}%</p>,&nbsp;
                    <p><strong>Middle:</strong> {{ room.room_occupancy_middle }}%</p>,&nbsp;
                    <p><strong>High:</strong> {{ room.room_occupancy_high }}%</p>
                  </div>
                </div>
                <div class="col-md-4">

                  <p class="label-with-circle"><strong>EBD</strong></p>
                  <p>{{ room.ebd }}</p>
                </div>
                <div class="col-md-4">

                  <p class="label-with-circle"><strong>SPO</strong></p>
                  <p>{{ room.spo }}</p>
                  
                </div>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>Ποσοστό Room Category Overbooking Discount</strong></p>
                  <p>{{ room.room_category_overbooking_discount }}</p>
                </div>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>EBD Percentage</strong></p>
                  <p>{{ room.ebd_percentage }}</p>
                </div>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>SPO Percentage</strong></p>
                  <p>{{ room.spo_percentage }}</p>
                </div>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>Room Overbooking</strong></p>
                  <p>{{ room.room_overbooking }}</p>
                </div>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>Room Nights Sold / Season (Low, Middle, High)</strong></p>
                  <div class="d-flex">
                    <p><strong>Low:</strong>{{ room.room_nights_sold_low }}</p>,&nbsp;
                    <p><strong>Middle:</strong>{{ room.room_nights_sold_middle }}</p>,&nbsp;
                    <p><strong>High:</strong>{{ room.room_nights_sold_high }}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>Pax Nights Sold / Season (Low, Middle, High)</strong></p>
                  <div class="d-flex">
                    <p><strong>Low:</strong>{{ room.pax_nights_sold_low }}</p>,&nbsp;
                    <p><strong>Middle:</strong>{{ room.pax_nights_sold_middle }}</p>,&nbsp;
                    <p><strong>High:</strong>{{ room.pax_nights_sold_high }}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>Wash Down Room Rate</strong></p>
                  <p>{{ room.wash_down_room_rate }}</p>
                </div>
                <div class="col-md-4">
                  <p class="label-with-circle"><strong>Wash Down Room Season Average Rate / Season (Low, Middle, High)</strong></p>
                  <div class="d-flex">
                    <p><strong>Low:</strong>{{ room.wash_down_season_average_rate_low }}</p>,&nbsp;
                    <p><strong>Middle:</strong>{{ room.wash_down_season_average_rate_middle }}</p>,&nbsp;
                    <p><strong>High:</strong>{{ room.wash_down_season_average_rate_high }}</p>
                  </div>
                </div>
              </div><br>
              <div class="row mt-3">
                <h4 class="fw-bolder">Revenue Arrangement (Έσοδα Συμφωνίας)</h4>
                <div class="col-md-4">
                <p class="label-with-circle"><strong>Total room nights sold</strong></p>
                <p>{{ room.total_room_nights_sold }}</p>
              </div>
              <div class="col-md-4">
                <p class="label-with-circle"><strong>Total pax nights sold</strong></p>
                <p>{{ room.total_pax_nights_sold }}</p>
              </div>
              <div class="col-md-4">
                <p class="label-with-circle"><strong>Final occupancy</strong></p>
                <p>{{ room.final_occupancy }}</p>
              </div>
              <div class="col-md-4">
                <p class="label-with-circle"><strong>Total arrangement revenue</strong></p>
                <p>{{ room.total_arrangement_revenue }}</p>
              </div></div>
              <div class="col-md-6">
                <h4 class="fw-bolder">Revenue Supplement (Έσοδα Συμπληρωματικά)</h4>
                <p class="label-with-circle"><strong>Supplement pick up</strong></p>
                <div class="table">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                      <th></th>
                      <th>BB</th>
                      <th>HB</th>
                      <th>FB</th>
                      <th>AI</th>
                    </tr>
                  </thead>
                      <tbody>
                        <tr>
                            <td>Low season</td>
                            <td>{{ room.supplement_pick_up_bb_low }}</td>
          <td>{{ room.supplement_pick_up_hb_low }}</td>
          <td>{{ room.supplement_pick_up_fb_low }}</td>
          <td>{{ room.supplement_pick_up_ai_low }}</td>
                        </tr>
                        <tr>
                            <td>Middle season</td>
                            <td>{{ room.supplement_pick_up_bb_middle }}</td>
          <td>{{ room.supplement_pick_up_hb_middle }}</td>
          <td>{{ room.supplement_pick_up_fb_middle }}</td>
          <td>{{ room.supplement_pick_up_ai_middle }}</td>
                        </tr>
                        <tr>
                            <td>High season</td>
                            <td>{{ room.supplement_pick_up_bb_high }}</td>
          <td>{{ room.supplement_pick_up_hb_high }}</td>
          <td>{{ room.supplement_pick_up_fb_high }}</td>
          <td>{{ room.supplement_pick_up_ai_high }}</td>
                        </tr>
                      </tbody>
                  </table>
                </div>
                <p class="label-with-circle"><strong>Pax nights supplement</strong></p>
                <div class="table mt-2">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                      <th></th>
                      <th>BB</th>
                      <th>HB</th>
                      <th>FB</th>
                      <th>AI</th>
                      </tr>
                    </thead>
                      <tbody>
                        <tr>
                            <td>Low season</td>
                            <td>{{ room.pax_nights_supplement_bb_low }}</td>
                            <td>{{ room.pax_nights_supplement_hb_low }}</td>
                            <td>{{ room.pax_nights_supplement_fb_low }}</td>
                            <td>{{ room.pax_nights_supplement_ai_low }}</td>
                        </tr>
                        <tr>
                            <td>Middle season</td>
                            <td>{{ room.pax_nights_supplement_bb_middle }}</td>
                            <td>{{ room.pax_nights_supplement_bb_middle }}</td>
                            <td>{{ room.pax_nights_supplement_fb_middle }}</td>
                            <td>{{ room.pax_nights_supplement_ai_middle }}</td>
                        </tr>
                        <tr>
                            <td>High season</td>
                            <td>{{ room.pax_nights_supplement_bb_high }}</td>
                            <td>{{ room.pax_nights_supplement_hb_high }}</td>
                            <td>{{ room.pax_nights_supplement_fb_high }}</td>
                            <td>{{ room.pax_nights_supplement_ai_high }}</td>
                        </tr>
                      </tbody>
                  </table>
                </div>
                <p class="label-with-circle"><strong>Supplement income/Guest</strong></p>
                <p>{{room.supplement_income_per_guest}}</p>
                <p class="label-with-circle"><strong>Supplement revenue</strong></p>
                <p>{{ room.supplement_revenue }}</p>      
              </div>
            </div>

            <!-- Edit Button for Rooms -->
            <div class="action-buttons" v-if="!room.isEditing">
              <button class="btn border-0 p-1 edit-button" @click="editRoom(index)">
                <img src="../assets/images/edit-budget.png" alt="Edit" />
              </button>
            </div>
          </div>

          <!-- Editable View for Rooms (Fields enabled) -->
          <div v-else>
  <div class="row" style="color:#4A4A4A; font-weight:bolder;">
    <div class="col-md-8">
                <p class="label-with-circle2"><strong>ROOM TYPE ({{ room.title }})</strong></p>
              </div>
    <div class="col-md-4 text-end">
      <p>
        <strong>Σύνολο Εσόδου:</strong> {{ formatNumber(room.total_revenue) }} €
      </p>
    </div><hr/>
    <!-- Pricing Per Guest Row -->
    <div class="col-md-12 mb-3">
  <p class="label-with-circle2"><strong>Pricing Per Guest</strong></p>

  <div class="row">
    <div class="col-md-4">
      <div class="d-flex align-items-center justify-content-between">
        <label><strong>Low</strong></label>
        <input v-model="room.pricing_per_guest_low" class="form-control ms-2" style="max-width: 70%;" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="d-flex align-items-center justify-content-between">
        <label><strong>Middle</strong></label>
        <input v-model="room.pricing_per_guest_middle" class="form-control ms-2" style="max-width: 70%;" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="d-flex align-items-center justify-content-between">
        <label><strong>High</strong></label>
        <input v-model="room.pricing_per_guest_high" class="form-control ms-2" style="max-width: 70%;" />
      </div>
    </div>
  </div>
</div>



<div class="col-md-4 mb-3">
  <div class="mb-3">
    <div class="d-flex align-items-center justify-content-between">
      <p class="label-with-circle2 mb-0"><strong>Ratio Guest/Room</strong></p>
      <input v-model="room.ratio" class="form-control ms-2" style="max-width: 70%;" />
    </div>
  </div>
</div>
    <!-- Room Occupancy Row -->
    <div class="col-md-12 mb-3">
  <p class="label-with-circle2"><strong>Room Occupancy</strong></p>

  <div class="row">
    <div class="col-md-4">
      <div class="d-flex align-items-center justify-content-between">
        <label><strong>Low</strong></label>
        <input v-model="room.room_occupancy_low" class="form-control ms-2" style="max-width: 70%;" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="d-flex align-items-center justify-content-between">
        <label><strong>Middle</strong></label>
        <input v-model="room.room_occupancy_middle" class="form-control ms-2" style="max-width: 70%;" />
      </div>
    </div>
    <div class="col-md-4">
      <div class="d-flex align-items-center justify-content-between">
        <label><strong>High</strong></label>
        <input v-model="room.room_occupancy_high" class="form-control ms-2" style="max-width: 70%;" />
      </div>
    </div>
  </div>
</div>


    <!-- EBD and SPO -->
    <div class="col-md-4">
  <div class="mb-3">
    <div class="d-flex align-items-center justify-content-between">
      <p class="label-with-circle2 mb-0"><strong>EBD</strong></p>
      <input v-model="room.ebd" class="form-control ms-2" style="max-width: 70%;" />
    </div>
  </div>

  <div class="mb-3">
    <div class="d-flex align-items-center justify-content-between">
      <p class="label-with-circle2 mb-0"><strong>EBD Percentage</strong></p>
      <input v-model="room.ebd_percentage" class="form-control ms-2" style="max-width: 70%;" />
    </div>
  </div>

  <div class="mb-3">
    <div class="d-flex align-items-center justify-content-between">
      <p class="label-with-circle2 mb-0"><strong>SPO</strong></p>
      <input v-model="room.spo" class="form-control ms-2" style="max-width: 70%;" />
    </div>
  </div>

  <div class="mb-3">
    <div class="d-flex align-items-center justify-content-between">
      <p class="label-with-circle2 mb-0"><strong>SPO Percentage</strong></p>
      <input v-model="room.spo_percentage" class="form-control ms-2" style="max-width: 70%;" />
    </div>
  </div>

  <div class="mb-3">
    <div class="d-flex align-items-center justify-content-between">
      <p class="label-with-circle2 mb-0"><strong>Room Overbooking</strong></p>
      <input v-model="room.room_overbooking" class="form-control ms-2" style="max-width: 70%;" />
    </div>
  </div>
</div>


    <!-- Supplement Pick Up -->
    <div class="col-md-6">
      <p class="label-with-circle2"><strong>Supplement Pick Up</strong></p>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th>BB</th>
            <th>HB</th>
            <th>FB</th>
            <th>AI</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Low Season</td>
            <td><input v-model="room.supplement_pick_up_bb_low" class="form-control" /></td>
            <td><input v-model="room.supplement_pick_up_hb_low" class="form-control" /></td>
            <td><input v-model="room.supplement_pick_up_fb_low" class="form-control" /></td>
            <td><input v-model="room.supplement_pick_up_ai_low" class="form-control" /></td>
          </tr>
          <tr>
            <td>Middle Season</td>
            <td><input v-model="room.supplement_pick_up_bb_middle" class="form-control" /></td>
            <td><input v-model="room.supplement_pick_up_hb_middle" class="form-control" /></td>
            <td><input v-model="room.supplement_pick_up_fb_middle" class="form-control" /></td>
            <td><input v-model="room.supplement_pick_up_ai_middle" class="form-control" /></td>
          </tr>
          <tr>
            <td>High Season</td>
            <td><input v-model="room.supplement_pick_up_bb_high" class="form-control" /></td>
            <td><input v-model="room.supplement_pick_up_hb_high" class="form-control" /></td>
            <td><input v-model="room.supplement_pick_up_fb_high" class="form-control" /></td>
            <td><input v-model="room.supplement_pick_up_ai_high" class="form-control" /></td>
          </tr>
        </tbody>
      </table>
      

      <div class="col-md-12 mb-3">
  <div class="mb-3">
    <div class="d-flex align-items-center justify-content-between">
      <p class="label-with-circle2 mb-0"><strong>Supplement Income/Guest</strong></p>
      <input v-model="room.supplement_income_per_guest" class="form-control ms-2" style="max-width: 70%;" />
    </div>
  </div>
</div>
    </div>

    <!-- Buttons -->
    <div class="row form-actions mt-4">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-secondary justify-content-end align-items-end"
                @click="cancelEditRoom(index)"
              >
                ΑΚΥΡΩΣΗ
              </button>
            </div>
          </div>

          <!-- Form Actions -->
          <div class="action-buttons">
            <button class="btn border-0 p-1 save" @click="saveRoom(index)">
              <img src="../assets/images/save.png" alt="Save" width="30" />
            </button>
          </div>
  </div>
</div>

        </div>
      </div>
    </div>
    <!-- Delete Confirmation Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Επιβεβαίωση Διαγραφής</h5>
            <button type="button" class="close" @click="closeDeleteModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή τη σεζόν;
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeDeleteModal">Ακύρωση</button>
            <button type="button" class="btn btn-danger" @click="confirmDeleteSeason">Διαγραφή</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoomTypes, saveRoomRevenue } from '@/services/room-types'; 
import { getSeason, createSeason, deleteSeason } from '@/services/seasons';
import { useHead } from '@vueuse/head';
import { useToast } from 'vue-toastification';
import { getBudget } from '@/services/budget';
import { FloatInput } from '@/components/FloatInput.vue';
import { fetchBudgetInfo, fetchUsers, changeUser, fetchUserInfo } from '../services/user';


export default {
  components: {
    FloatInput
  },
  data() {
    return {
      seasons: [], 
      roomTypes: [],
      totalSeason: 0,
      totalSeasonHigh: 0,
      totalSeasonLow: 0,
      totalSeasonMiddle: 0,
      calculatedData: {},
      budgetTotal: 0,
      deleteIndex: null, 
    };
  },
  computed: {
    // Format the total revenue with European formatting
    formattedBudgetTotal() {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(this.budgetTotal);
    },
  },
  
  methods: {
    replaceCommasWithDots(obj) {
        // Loop through each key in the object
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              // Check if the value is a string and looks like a float with comma as decimal separator
              if (typeof obj[key] === 'string' && obj[key].match(/^\d{1,3}(.\d{3})*,\d+$/)) {
                  // Replace the thousand separators (.) and convert comma to dot for decimals
                  obj[key] = parseFloat(obj[key].replace(/\./g, '').replace(',', '.'));
              }
            }
        }
        return obj;
    },
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    async fetchSeasons() {
      try {
        const response = await getSeason(this.$route.params.budget_id);
        if (response.success) {
          this.seasons = response.data.map(season => ({
            ...season,
            isEditing: false
          }));
        }
      } catch (error) {
        console.error('Error fetching seasons:', error);
      }
    },

    async fetchRoomTypes() {
      try {
        const response = await getRoomTypes(this.$route.params.budget_id);
        this.roomTypes = response.data.map(room => ({
          ...room,  // Ensure all fields from API are included
          isEditing: false
        }));
      } catch (error) {
        console.error('Error fetching room types:', error);
      }
    },

    addSeason() {
      this.seasons.push({
        season_date_from: '',
        season_date_to: '',
        season_type: 'Middle',
        isEditing: true
      });
    },

    editSeason(index) {
      this.seasons[index].isEditing = true;
    },

    openDeleteModal(index) {
  this.deleteIndex = index; // Correctly set deleteIndex
  $('#deleteModal').modal('show'); // Show the modal
},

closeDeleteModal() {
  $('#deleteModal').modal('hide'); // Correctly hide the modal
  this.deleteIndex = null; // Clear the deleteIndex for safety
},

    async confirmDeleteSeason() {
  try {
    if (this.deleteIndex !== null) {
      await this.deleteSeason(this.deleteIndex); // Use the correct deleteIndex
      this.saveAllSeasons(); // Save the remaining seasons
    }
  } catch (error) {
    console.error('Error deleting room:', error);
  } finally {
    this.closeDeleteModal(); // Always close the modal
  }
},

    async saveAllSeasons() {
      const response = await createSeason(this.$route.params.budget_id, { seasons: this.seasons });
      if (response.success) {
        this.fetchSeasons();
        this.calcSeasonDays();
        useToast().success(response.message);
      } else {
        useToast().error(response.message);
      }
    },

    cancelEditSeason(index) {
      this.seasons[index].isEditing = false;
      this.fetchSeasons();
    },

    async deleteSeason(index) {
    const season = this.seasons[index];
    console.log(season);
    if (season.id) {
      try {
        await deleteSeason(season.id); // Make API call to delete season by ID
        this.seasons.splice(index, 1);
      } catch (error) {
        console.error('Error deleting season:', error);
      }
    } else {
      this.seasons.splice(index, 1); // If no ID, just remove from local array
    }
  },

    editRoom(index) {
      this.roomTypes[index].isEditing = true;
    },

    cancelEditRoom(index) {
      this.roomTypes[index].isEditing = false;
    },

    async saveRoom(index) {
      let room = this.roomTypes[index];
      room = this.replaceCommasWithDots(room);
      try {
        const response = await saveRoomRevenue(room.id, room); // Assuming the service method
        if (response.success) {
          this.roomTypes[index] = response.data;
          room.isEditing = false;
          this.fetchBudgetInfoData();
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
      } catch (error) {
        console.error('Error saving room type:', error);
      }
    },

    async fetchBudgetInfoData() {
      try {
        const response = await fetchBudgetInfo(this.$route.params.budget_id);
        if (response) {
          this.budgetTotal = response.data.data.total_room_revenue || 0;
        }
      } catch (error) {
        console.error('Failed to fetch budget info:', error);
      }
    },

    async calcSeasonDays() {
      const response = await getBudget(this.$route.params.budget_id);
      if (response.data) {
        this.totalSeason = response.data.data.total_season;
        this.totalSeasonHigh = response.data.data.total_season_high;
        this.totalSeasonLow = response.data.data.total_season_low;
        this.totalSeasonMiddle = response.data.data.total_season_middle;
      }
    }
  },

  async mounted() {
    useHead({
      title: 'Room Revenues',
    });
    this.fetchSeasons();
    this.fetchRoomTypes();
    this.calcSeasonDays();
    this.fetchBudgetInfoData();
  }
};
</script>

<style scoped>
.seasonality-pricing-container {
  padding: 20px;
}

h5 {
  font-weight: bold;
}

.card {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 15px;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #ddd;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shadow-custom {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}



.edit-button img,
.delete-button img {
  width: 30px;
  height: 30px;
}

.edit-button,
.edit-button:hover {
  position: absolute;
  background-color: #67B662 !important;
  border-radius: 8px;
  top: 42px;
  right: -20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.delete-button {
  position: absolute;
  background-color: #D03535;
  border-radius: 8px;
  top: 42px;
  right: -20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.delete-button,
.delete-button:hover {
  background-color: #D03535;
}

.room-types-section {
  margin-top: 40px;
}

.room-type-card {
  margin-bottom: 20px;
  width: 100%;
}

.d-flex {
  margin-top: 15px;
}

.mb-3 {
  margin-bottom: 15px !important;
}

.edit{
      position: absolute;
    
      top: 25%; /* Adjust the vertical position to be slightly above middle */
      right: -20px; /* Half the button outside the outlet-card */
      transform: translateY(-200%); /* Center vertically relative to its position */
    
    }
    
    .save {
    position: absolute;
    top: 10%; /* Adjust as needed to lower the button */
    right: -18px; /* Adjust to move outside the card */
    transform: translateY(30%); /* Further adjust for desired position */
  }
  
  .delete-button {
    position: absolute;
    top: 42%; /* Adjust to position higher or lower */
    right: -18px; /* Same as above */
    transform: translateY(-95%); /* Further adjust for desired position */
  
  }
</style>
