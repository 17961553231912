import apiClient from "./api";



async function updateOtherRevenues(id, data) {
    try {
      const response = await apiClient.post(
        `/update-other-revenues/${id}`,
        data
      );
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}



async function getOtherRevenues(budgetId) {
    try {
      const response = await apiClient.get(
        `/get-other-revenues/${budgetId}`);
      return response.data; // Return the actual data part of the response
    } catch (error) {
      // Handle the error response
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return {
          success: false,
          message: "No response received",
          details: error.request,
        };
      } else {
        return {
          success: false,
          message: "Request setup error",
          details: error.message,
        };
      }
    }
}

export { updateOtherRevenues, getOtherRevenues };
