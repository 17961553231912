import apiClient from "./api";

async function getHotel(hotelId) {
    try {
      const response = await apiClient.get('/get-hotel/' + hotelId);
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return { success: false, message: "No response received", details: error.request };
      } else {
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }

  export { getHotel }