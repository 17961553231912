<template>
  
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { fetchUserInfo, fetchUsers, changeUser } from '../services/user'; // Adjust this import as needed
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';



// Define a reactive variable to track whether the current mode is light or dark
const isLightMode = ref(false);

export default {
  name: 'MainLayout',
  components: {
    LoadingSpinner
  },
  mounted() {
    this.fetchUserData();
    // Set the current year in the footer
    const yearSpan = document.getElementById('footeryear');
    if (yearSpan) {
      yearSpan.textContent = new Date().getFullYear();
    }
  },
  methods: {
    ...mapActions(['fetchUserData']),
  },
  computed: {
    ...mapGetters({
      getUserPermission: 'getUserPermission',
    }),
    userPermissionsProjects() {
      return this.getUserPermission('Projects');
    },
    canViewProjects() {
      return this.userPermissionsProjects?.view === 1;
    },
    userPermissionsUsers() {
      return this.getUserPermission('Users');
    },
    canViewUsers() {
      return this.userPermissionsUsers?.view === 1;
    },
    userPermissionsServices() {
      return this.getUserPermission('Services');
    },
    canViewServices() {
      return this.userPermissionsServices?.view === 1;
    },
    userPermissionsSuppliers() {
      return this.getUserPermission('Suppliers');
    },
    canViewSuppliers() {
      return this.userPermissionsSuppliers?.view === 1;
    },
    userPermissionsClients() {
      return this.getUserPermission('Clients');
    },
    canViewClients() {
      return this.userPermissionsClients?.view === 1;
    },
    userPermissionsContacts() {
      return this.getUserPermission('Contacts');
    },
    canViewContacts() {
      return this.userPermissionsContacts?.view === 1;
    },
    userPermissionsHours() {
      return this.getUserPermission('Hours');
    },
    canViewHours() {
      return this.userPermissionsHours?.view === 1;
    },
    userPermissionsHolidays() {
      return this.getUserPermission('Holidays');
    },
    canViewHolidays() {
      return this.userPermissionsHolidays?.view === 1;
    },
    userPermissionsReporting() {
      return this.getUserPermission('Reporting');
    },
    canViewReporting() {
      return this.userPermissionsReporting?.view === 1;
    },
    userPermissionsDaysOff() {
      return this.getUserPermission('Days off');
    },
    canViewDaysOff() {
      return this.userPermissionsDaysOff?.view === 1;
    },
    userPermissionsDaysOffReports() {
      return this.getUserPermission('Days off reports');
    },
    canViewDaysOffReports() {
      return this.userPermissionsDaysOffReports?.view === 1;
    },
  },
  setup() {
    const isLoading = ref(false);
    const userInfo = ref(null); // Use cached user info if available
    const users = ref([]); // Ref to store fetched users
    const selectedUser = ref(null); // Ref to store selected user
    const showUsersContainer = ref(true); // Ref to control visibility of users container
    const menuItemsCollapsed = ref(['dashboard', 'contacts']);

    const isMenuOpen = ref(false); // Reactive variable to track menu state
    const isNotificationsOpen = ref(false); // Reactive variable to track notifications state
    const router = useRouter();
    const isSidebarOpen = ref(false);
    const isSidebarMini = ref(false);
    const route = useRoute();
    const showSearch = ref(false);
    const partnerData = ref(false);


    watch(
      () => route.name,
      (newRoute) => {
        const hiddenRoutes = [
          "ChangePassword",
          "BusinessPartnerProfile",
          "OfferProfile",
          "ShowBusinessPartnerProfile"
        ];

        // Check if the current route name is in the list of hidden routes
        if (hiddenRoutes.includes(newRoute)) {
          showSearch.value = false; // Hide search bar
        } else {
          showSearch.value = true; // Show search bar
        }
      },
      { immediate: true }  // Ensure it runs immediately on load
    );

    const openMainMenuItem = (menuItem) => {
      const index = menuItemsCollapsed.value.indexOf(menuItem);
      if (index > -1) {
        menuItemsCollapsed.value.splice(index, 1);
      } else {
        menuItemsCollapsed.value.push(menuItem);
      }
    };

    const toggleSidebarMini = () => {
      isSidebarMini.value = !isSidebarMini.value;
      $('.sidebar').toggleClass('sidebar-mini');

      if (isSidebarMini.value) {
        $('.sidebar').removeClass('open');
      }
    };

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;

      if (isSidebarOpen.value) {
        // Sidebar is open
        $('.sidebar').addClass('open');
        if (isSidebarMini.value) {
          $('.sidebar').addClass('sidebar-mini');
        }
      } else {
        // Sidebar is closed

        $('.sidebar').removeClass('open');
        $('.sidebar').removeClass('sidebar-mini');
      }
    };

    const isRtl = ref(false); // Add this line to define reactive variable for RTL mode

    // Watch for changes in RTL mode checkbox
    watch(isRtl, (newValue) => {
      if (newValue) {
        document.body.classList.add('rtl_mode');
      } else {
        document.body.classList.remove('rtl_mode');
      }
    });


    const startLoading = () => {
      isLoading.value = true;
    };

    const stopLoading = () => {
      isLoading.value = false;
    };

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value; // Toggle menu state
      isNotificationsOpen.value = false;
    };


    const handleNotificationLinkClick = (event) => {
      event.preventDefault();
      const target = $(event.target);
      const dataId = target.attr('data-id');
      const dataType = target.attr('data-type');

      if (dataId) {
        switch (dataType) {
          case 'project':
            router.push({ path: '/project', query: { id: dataId } });
            break;
          case 'task':
            router.push({ path: '/task', query: { id: dataId } });
            break;
          case 'subtask':
            router.push({ path: '/subtask', query: { id: dataId } });
            break;
          case 'host':
            router.push({ path: '/host', query: { id: dataId } });
            break;
          // Add more cases here for different types
          default:
            console.log('Unknown data-type with ID:', dataType, dataId);
        }
      } else {
        switch (dataType) {
          case 'hours-creation':
            router.push({ path: '/hours-requests', query: {} });
            break;
          case 'days-off-creation':
            router.push({ path: '/days-off-requests', query: {} });
            break;
          case 'missing-hours-days':
            router.push({ path: '/hours', query: {} });
            break;
          // Add more cases here for different types
          default:
            console.log('Unknown data-type without ID:', dataType);
        }
      }
    };

    const menuStyle = computed(() => ({
      top: isMenuOpen.value ? '100%' : 'initial',
      right: isMenuOpen.value ? '0' : 'initial'
    }));

    const notificationStyle = computed(() => ({
      top: isNotificationsOpen.value ? '100%' : 'initial',
      right: isNotificationsOpen.value ? '0' : 'initial'
    }));

    const fetchUserData = async () => {
      try {
        const response = await fetchUserInfo();
        userInfo.value = response.data?.info || null;
        if (userInfo.value && userInfo.value.customer_id === null && userInfo.value.superUser === 1) {
          showUsersContainer.value = true; // Set showUsersContainer to true if conditions are met
          fetchUsersData(); // Fetch users data if conditions are met
        } else {
          showUsersContainer.value = false; // Set showUsersContainer to false if conditions are not met
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    const fetchUsersData = async () => {
      try {
        const response = await fetchUsers();
        showUsersContainer.value = response.data.success; // Set showUsersContainer based on response success
        if (response.data.success) {
          users.value = response.data.data; // Update users ref with response data
        } else {
          console.error("Failed to fetch users:", response.data.message);
        }
      } catch (error) {
        showUsersContainer.value = false; // Set showUsersContainer to false in case of error
        console.error("Failed to fetch users:", error);
      }
    };

    // Handle user change
    const changeUserHandler = async (userId) => {
      const userInfo = await fetchUserInfo();
      if (userInfo.data.info?.id) {
        if (userId && parseInt(userInfo.data.info?.id) != parseInt(userId)) {
          const toast = useToast();
          try {
            const response = await changeUser(userId); // Call the changeUser function with the selected userId
            if (response.data.success) {
              // Update the Authorization header with the new token
              const newToken = response.data.data.token.plainTextToken;

              localStorage.removeItem('api_token');
              localStorage.setItem('api_token', newToken);

              await fetchUserData();

              toast.success(response.data.message); // Show success message

              // Reload the page
              window.location.reload();
            } else {
              toast.error(response.data.message); // Show error message
            }
          } catch (error) {
            console.error('Error changing user:', error);
            toast.error('An error occurred while changing the user.'); // Show generic error message
          }
        }
      }
    };

    const closeMenu = (event) => {
      if (!document.querySelector('.user-menu').contains(event.target) && !document.querySelector('.user-menu-toggler').contains(event.target)) {
        isMenuOpen.value = false;
      }

    };

    const logout = (event) => {
      event.preventDefault();
      localStorage.removeItem('api_token');
      localStorage.removeItem('main_auth_token');
      router.push('/merchant');
    };


    onMounted(() => {

      if (!userInfo.value) {
        fetchUserData(); // Fetch user info if not already cached
      }


      $(document.body).on('click', '.notification-link', handleNotificationLinkClick);

      document.addEventListener('click', closeMenu);


      router.beforeEach((to, from, next) => {
        startLoading();
        next();
      });

      router.afterEach(() => {
        stopLoading();
      });
    });

    onUnmounted(() => {
      $(document.body).off('click', '.notification-link', handleNotificationLinkClick);
      document.removeEventListener('click', closeMenu);
    });



    return {
      partnerData,
      showSearch,
      userInfo,
      users,
      selectedUser,
      menuStyle,
      toggleMenu,
      isMenuOpen,
      isNotificationsOpen,
      notificationStyle,
      menuItemsCollapsed,
      openMainMenuItem,
      showUsersContainer,
      changeUserHandler,
      isLightMode,
      route,
      router,
      isLoading,
      toggleSidebar,
      isSidebarOpen,
      isSidebarMini,
      isRtl,
      toggleSidebarMini,
      logout
    };
  }
};
</script>


<style scoped>
.submenu.collapsed {
  display: none;
}

.submenu.collapse {
  display: block;
}

.sidebar {
  background: var(--primary-color);
  height: 90%;
}

.m-link {
  width: 160px;
  padding: 5px;
  left: 15px;
  top: 10px;
  margin: 0px;
  background-color: #f6f6f6;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  text-align: center;

}

.m-link span {
  padding: 5px;

  color: #616161;
  text-align: center;
  font-size: large;
}


.arrow.icofont-dotted-up {
  transform: rotate(180deg);
}

.stStatusInfoCyanMedium {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #00a0d2;
  font-size: 20px;
  font-weight: normal;
}

.stStatusInfoCyan24 {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #00a0d2;
  font-size: 30px;
  font-weight: normal;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.icon-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Adds space between the icons */
}

.icon-container i {
  font-size: 24px;
  /* Adjust the size of the icons */
  color: #4A90E2;
  /* Choose a color that fits your design */
  transition: color 0.3s ease;
}

.d-flex.center.align-middle {
  display: flex;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
  height: 100%;
  /* Adjust this depending on the parent container */
}


.icon-container i:hover {
  color: #2C3E50;
  /* Changes the color on hover */
}

.footer {
  background-color: var(--primary-color);
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 0.1rem;
  /* Reduced padding for a smaller height */
  color: white;
  font-size: 12px;
  /* Adjust font size as needed */
  line-height: 1.5;
  /* Adjust line-height for better spacing */
}

.menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* Adjust for spacing between items */
}

.menu-item i {
  margin-right: 10px;
  /* Adjust for space between icon and text */
}

.menu-link>span,
.menu-item>span {
  font-size: larger;
}

.menu-link {
  flex-grow: 1;
  /* Ensures the text stays to the right of the icon */
}

.menu-link:hover {
  color: var(--primary-color);
}

.icon-large {
  font-size: 1.6rem;
  /* Adjust icon size */
  transition: transform 0.3s ease, color 0.3s ease;
  /* Smooth transition */
}

.icon-large:hover {
  color: var(--primary-color);
  /* Replace with your primary color */
  transform: translateY(-5px);
  /* Move up by 5px */
  margin-bottom: 5px;
}

.company-data {
  color: #616161;
}
</style>